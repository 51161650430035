import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"
import { useWindowSize } from "../../../hooks/useWindowSize";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import Switch from "react-switch";
// import { DatePickerField } from "../../../shared/_helpers/datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputGroup from 'react-bootstrap/InputGroup';
import { TableComponent } from "../../../shared/components/table"
import { InAppTemplate } from "../../../shared/templates/inapp";
import DownArr from "../../../assets/icons/caret-dn.svg"
import SearchIco from "../../../assets/icons/search.svg"
import Caret1 from "../../../assets/icons/left-caret.svg"
import Caret2 from "../../../assets/icons/right-caret.svg"
import DropDn from "../../../assets/icons/drop.png"
import "../index.scss"
import { history } from "../../../shared/_helpers/history";
import { LoadingItem } from "../../../shared/components/loading"
import { AlertMsg } from "../../../shared/components/alert-msg"
import { storeActions } from "../../../redux/actions/in-app"
import { getDateFromISO } from "../../../shared/utils"

import ReactPaginate from 'react-paginate';


const getProducts = async ({ pageProps, payload }: any) => {

    await pageProps.getMyProductList(payload);
}

const toggleProduct = async ({ pageProps, payload, callback }: any) => {

    await pageProps.toggleAProduct(payload, callback);
}

const InAppContent = ({ pageProps }: any) => {
    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>();
    const screenResolution: any = useWindowSize();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [data, setData] = useState([]);
    let toogleRequest = pageProps.toggleProductRequest;



    useEffect(() => {
        let payload = `Page=${pageNumber}&Size=${pageSize}`;
        getProducts({
            pageProps,
            payload
        })

        toggleProduct({
            pageProps,
            payload: "CLEAR"
        })


    }, [])

    useEffect(() => {
        // if (searchText) {
        let payload = `Page=${pageNumber}&Size=${pageSize}${searchText ? `&SearchText=${searchText}` : ""}`;
        getProducts({
            pageProps,
            payload
        })

        toggleProduct({
            pageProps,
            payload: "CLEAR"
        })
        // }


    }, [pageNumber, pageSize, searchText])

    // useEffect(() => {
    //     let payload = `Page=${pageNumber}&Size=${pageSize}`;
    //     getProducts({
    //         pageProps,
    //         payload
    //     })

    // }, [currentPage])

    const ReportTable = ({ reportData }: any) => {

        return (
            <>
                <thead>
                    <tr>
                        <th>
                            <span>Product</span>
                        </th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Category</th>
                        {/* <th>Switch</th> */}
                        {/* <th>On-sale</th> */}
                        <th>Created On</th>
                        <th>Rating</th>
                        <th> <span>Show / Hide</span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reportData.results.map((eachItem: any, index: any) => {

                            return (
                                <tr key={index}>
                                    <td>
                                        <div className="product-info">
                                            <img src={eachItem.urls[0]} alt="" />
                                            <div className="prod-name">
                                                {eachItem.name}
                                                {/* <span>View Details</span> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td><span className="stock">{eachItem.availableQuantity}</span> </td>
                                    {/* <td><span className="unit-price">sdsds</span> </td> */}
                                    <td><span className="unit-price">${eachItem.price.amount}</span> </td>
                                    <td>
                                        {eachItem.categories.map((eachCat: any, idx: any) => {

                                            return (<span className="cate" key={idx}>
                                                {eachCat}
                                            </span>
                                            )
                                        })}
                                        {eachItem.categories.length === 0 ? "N/A" : ""}
                                    </td>
                                    {/* <td>
                                        <div className="is-active">
                                            <Switch width={40} height={20} onHandleColor="#00A85B" onColor="#B2F6D6" offHandleColor="#9B9B9B" offColor="#D8D8D8" onChange={() => { eachItem.isOn = !eachItem.isOn }} checked={eachItem.isOn} />
                                            <span>Turn {eachItem.isOn ? "Off" : "On"} </span>
                                        </div>

                                    </td> */}
                                    {/* <td>
                                        {(eachItem.salesAmount || eachItem.salesAmount === 0) &&
                                            <>

                                                <div className="sales on">
                                                    On-Sale
                                                </div>
                                                <div className="sales-rate">
                                                    {eachItem.salesAmount >= 1 ? `${eachItem.salesAmount}%` : "Flat Rate"}
                                                </div>
                                            </>
                                        }
                                        {(!eachItem.salesAmount && eachItem.salesAmount !== 0) &&
                                            <>

                                                <div className="sales ">
                                                    On-Sale
                                                </div>

                                            </>
                                        }
                                    </td> */}
                                    <td><div className="date-item">{getDateFromISO({ date: eachItem.createdDate }) || "N/A"}</div> </td>
                                    <td>
                                        {(eachItem?.ratingSummary && eachItem?.ratingSummary.count >= 1) &&
                                            <>
                                                {eachItem?.ratingSummary.averageRating} <span className="unit-price">({eachItem.ratingSummary.count})</span>
                                            </>
                                        }
                                        {eachItem?.ratingSummary && eachItem?.ratingSummary.count === 0 ? "N/A" : ""}
                                        {/* {(eachItem?.ratingSummary.count && eachItem?.ratingSummary.count === 0) &&
                                            <>N/A</>
                                        } */}
                                    </td>
                                    <td>


                                        <div className="product-actions">
                                            <div className="is-active">
                                                {/* <Switch width={40} height={20} onHandleColor="#FFFFFF" onColor="#00A85B" offHandleColor="#9B9B9B" offColor="#D8D8D8" onChange={() => { eachItem.isActive = !eachItem.isActive }} checked={eachItem.isActive} /> */}
                                                {/* <span>Turn {eachItem.isActive ? "Off" : "On"} </span> */}
                                                <EachProductStatus eachItem={eachItem} pageProps={pageProps} />
                                            </div>
                                            <Dropdown className="report_dropdown" as={ButtonGroup}>
                                                <Dropdown.Toggle id="dropdown-basic" className="action-cta">
                                                    <img src={DropDn} alt="" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item>
                                                        View details
                                                    </Dropdown.Item> */}
                                                    <Dropdown.Item href={`/app/my-products/${eachItem.id}/edit`}>
                                                        Edit
                                                        {/* <Link to={`/app/my-products/${eachItem.id}/edit`}>Edit</Link> */}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </>
        )
    }
    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }
    const MobileDataGrid = ({ reportData }: any) => {

        return (
            <div className="mobile-data-grid">
                {(reportData?.results.length >= 1) &&
                    <>
                        {reportData?.results.map((eachItem: any, index: any) => {
                            return (
                                <div className="each_data_item" key={index}>
                                    <div className="top-tiny-txt">{getDateFromISO({ date: eachItem.createdDate }) || "N/A"} </div>
                                    <div className="main-txt-head">
                                        <div className="head-txt">{eachItem?.name} </div>
                                        <div className="other-head-txt">${eachItem?.price?.amount}</div>
                                    </div>
                                    <div className="availability">
                                        <div className="title-txt">Availability </div>
                                        <div className="availables">{eachItem.availableQuantity} in stock</div>
                                    </div>
                                    <div className="cat-items-list">
                                        <div className="title-txt">Category </div>
                                        <div className="catlists">
                                            {eachItem.categories.map((eachCat: any, idx: any) => {

                                                return (<span className="cate" key={idx}>
                                                    {eachCat}{(eachItem.categories.length > 1 && idx < eachItem.categories.length - 1) ? ", " : ""}
                                                </span>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="cta-btns">
                                        <button onClick={() => history.replace(`/app/my-products/${eachItem.id}/edit`)}>Edit</button>
                                        {eachItem?.isActive && <button>Deactivate</button>}
                                        {!eachItem?.isActive && <button>Activate</button>}
                                    </div>
                                </div>
                            )
                        })}

                    </>

                }

            </div >
        )
    }

    // const handlePageClick = (selectedPage: any) => {
    //     console.log("selectedPage", selectedPage)
    //     setCurrentPage(selectedPage?.selected);
    // }

    const EachProductStatus = ({ eachItem, pageProps }: any) => {
        const [productStatus, setProductStatus] = useState<boolean>(eachItem.isActive);
        const [productInfo, setProductInfo] = useState<any>(eachItem);

        useEffect(() => {
            if (productStatus !== eachItem.isActive) {
                let togglePayload = {
                    storeId: eachItem?.storeId,
                    productId: eachItem?.id
                };

                let payload = `Page=${pageNumber}&Size=${pageSize}`;

                toggleProduct({
                    pageProps, payload: togglePayload,
                    callback: () => {
                        getProducts({
                            pageProps,
                            payload
                        })
                    }
                })
                // .then(() => {
                //     console.log("toggleProductRequest", toogleRequest)
                //     if (pageProps.toggleProductRequest.request_status === "TOGGLE_A_PRODUCT_SUCCESS") {
                //         console.log("toggleProductRequest", pageProps)


                //     }
                // })
            }


        }, [productStatus])




        return (
            <Switch width={40} height={20} disabled={pageProps.toggleProductRequest.is_request_processing} onHandleColor="#FFFFFF" onColor="#00A85B" offHandleColor="#9B9B9B" offColor="#D8D8D8" onChange={() => { setProductStatus(!productStatus) }} checked={productStatus} />
            // <Switch width={40} height={20} onHandleColor="#FFFFFF" onColor="#00A85B" offHandleColor="#9B9B9B" offColor="#D8D8D8" onChange={() => { eachItem.isActive = !eachItem.isActive }} checked={eachItem.isActive} />
        )
    }

    return (
        <div className="inapp-content">
            <div className="page-content lists-wrap with-heading">
                <div className="lists-header products">
                    <div className="heading-txt">Product Inventory</div>

                    <div className="header-wrap-items">
                        <div className="header-ctas">
                            <button className="each-cta" onClick={() => history.replace("/app/my-products/new")}>+ New Product</button>
                            <button className="each-cta" onClick={() => history.replace("/app/my-products/new/multiple")}> + Multiple Products</button>
                        </div>
                        <div className="list-filters-wrap">
                            <div className="each-filter for-search">
                                <input onChange={(e: any) => {
                                    let txtValue = e.target.value.trim();
                                    if (txtValue.length >= 4) {
                                        setSearchText(e.target.value)
                                    }
                                    if (txtValue.length == 0) {
                                        setSearchText("")
                                    }
                                }} type="text" placeholder="Search Product" />
                                {/* <button>
                                                <img src={SearchIco} alt="" />
                                            </button> */}
                            </div>
                            {/* <div className="each-filter filter-options">
                                            <div className="filter-table">
                                               
                                                <select name="" id="">
                                                    <option value="">Filter By</option>
                                                    <option value="price">Price</option>
                                                    <option value="date">Date</option>
                                                </select>
                                            </div>
                                        </div> */}
                        </div>


                    </div>

                </div>
                <div className="list-contanier">
                    {/* <div className="list-title">Products</div> */}
                    <div className="list-content-wrap cards products-list all-products">
                        {pageProps.getProductListRequest.request_status === "GET_PRODUCTS_SUCCESS" &&
                            <>
                                {pageProps.getProductListRequest.request_data.response?.results.length >= 1 &&
                                    <div>

                                        <div className="all-items">

                                            <div className="all-items-list">
                                                {screenResolution?.width >= 768 &&
                                                    <TableComponent
                                                        childComponent={<ReportTable reportData={pageProps.getProductListRequest.request_data.response} />}
                                                    />
                                                }

                                                {(screenResolution?.width <= 767 && pageProps?.getProductListRequest?.request_data?.response) &&
                                                    <MobileDataGrid
                                                        reportData={pageProps.getProductListRequest.request_data.response}

                                                    />
                                                }
                                                {(pageProps?.getProductListRequest?.request_data?.response?.totalNumberOfRecords > pageSize) &&
                                                    <>

                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel=">"
                                                            // initialPage={1}
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={4}
                                                            forcePage={pageNumber - 1}
                                                            // onClick={handleItemsClick}
                                                            className="pagination_items"
                                                            pageClassName="page_num"
                                                            pageLinkClassName="page_link"
                                                            activeClassName="active_page_link"
                                                            previousClassName="previous_page_link"
                                                            nextClassName="next_page_link"
                                                            pageCount={pageProps?.getProductListRequest?.request_data?.response?.pageNumber}
                                                            previousLabel="<"
                                                            // @ts-ignore
                                                            renderOnZeroPageCount={null}


                                                        // previousLabel={<div className="prev-page each-page">
                                                        //     <img src={Caret1} alt="" />
                                                        // </div>}
                                                        // nextLabel={<div className="next-page each-page">
                                                        //     <img src={Caret2} alt="" />
                                                        // </div>}
                                                        // pageClassName={"each-page"}
                                                        // pageCount={pageProps?.getProductListRequest?.request_data?.response?.pageNumber}
                                                        // onPageChange={handlePageClick}
                                                        // containerClassName={"pagination-wrap to-right"}
                                                        // onClick={() => console.log("hisds")}
                                                        // // previousLinkClassName={"each-page"}
                                                        // // nextLinkClassName={"each-page"}
                                                        // // nextLinkClassName={"pagination__link"}
                                                        // // disabledClassName={"pagination__link--disabled"}
                                                        // activeClassName={"active"}
                                                        />
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                }
                                {pageProps.getProductListRequest.request_data.response?.results.length === 0 &&
                                    <div className="no-products">
                                        <div className="empty-msg">
                                            No products found
                                        </div>

                                        {/* <button className="new-product-cta" onClick={() => history.replace("/app/my-products/new")}>Create your first Product</button> */}
                                    </div>
                                }
                            </>
                        }
                        {pageProps.getProductListRequest.request_status === "GET_PRODUCTS_PENDING" &&
                            <LoadingItem />
                        }
                        {pageProps.getProductListRequest.request_status === "GET_PRODUCTS_FAILURE" &&
                            <AlertMsg type="error" message={pageProps.getProductListRequest?.request_data.error} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
const ProductList = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="My Products" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    getMyProductList: storeActions.GetMyProductList,
    toggleAProduct: storeActions.ToggleAProduct,
};

const mapStateToProps = (state: any) => ({
    // return {
    getProductListRequest: state.allProductReducers.getProductListReducer,
    toggleProductRequest: state.allProductReducers.toggleProductReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);