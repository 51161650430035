import { CiCreditCard1 } from "react-icons/ci";
import { TbTruckDelivery } from "react-icons/tb"
import { GoSearch } from 'react-icons/go'
import { FaAngleUp } from 'react-icons/fa6'
import { LiaShippingFastSolid } from 'react-icons/lia'
import { MdOutlineLocalShipping } from 'react-icons/md'
import { TbMapSearch } from 'react-icons/tb'
import { BiUser } from 'react-icons/bi'
import { TbArrowsExchange2 } from 'react-icons/tb'
import { PiInfo } from 'react-icons/pi'

export const questions = [
    {
        name: 'Order listings',
        data: [
            {
                question: "How do I sign up as a vendor on the marketplace?",
                answer: "To become a vendor, visit our website and look for the 'Vendor Registration' or How to Sell on MamiMarkets. Follow the registration process, which may include providing essential information about your business and verifying your identity."
            },
            {
                question: "What are the requirements for becoming a vendor?",
                answer: "Vendor requirements may vary, but typically, you'll need a valid business registration number and bank account details for payouts. Check our vendor registration page for specific requirements."
            },
            {
                question: "How can I list my products or services on the platform?",
                answer: "After registering as a vendor, you can log in to your vendor dashboard. There, you'll find options to create and manage product listings. The steps to list your products include descriptions, prices, and images."
            },
            {
                question: "Is there a fee or subscription cost for vendors?",
                answer: "Our fee structure may vary, and we may charge a subscription or commission fee based on your sales. Review the fee schedule in your vendor dashboard or our website for more details."
            }
        ]
    },
    {
        name: 'Product Listings',
        data: [
            {
                question: "How do I create and manage product listings?",
                answer: "You'll find tools to add, edit, and manage product listings in your vendor dashboard. You can input product details, including descriptions, prices, and inventory levels."
            },
            {
                question: "What are the guidelines for product descriptions and images?",
                answer: "We have guidelines to ensure accurate and appealing product descriptions and images. Follow these guidelines to maintain a high-quality product listing."
            },
            {
                question: "Can I offer variations of my products, such as different sizes or colours?",
                answer: "Yes, you can offer product variations. Use the provided tools to create product options, such as size or colour, and specify pricing and inventory for each variation."
            },
            {
                question: "How do I set prices and manage inventory for my listings?",
                answer: "You can set prices and manage inventory in your vendor dashboard. Adjust prices and update inventory quantities as needed to reflect the availability of your products."
            }
        ]
    },
    {
        name: 'Order Management',
        data: [
            {
                question: "How do I process orders from customers?",
                answer: "When a customer places an order, you'll receive a notification in your vendor dashboard. Process the order by confirming the purchase, packing the items, and generating a shipping label."
            },
            {
                question: "What is the order fulfilment process?",
                answer: "Order fulfilment involves packaging the products, creating a shipping label, and sending the order to the customer using your preferred shipping method."
            },
            {
                question: "How can I communicate with customers about their orders?",
                answer: "Use the messaging system within your vendor dashboard to communicate with customers. Keep them informed about order status, shipping, and any inquiries they may have."
            },
            {
                question: "What is the return and refund policy for orders I fulfil?",
                answer: "Our return and refund policies vary. Refer to our vendor terms and conditions or contact our support team for details on returns and refunds for orders you fulfil."
            }
        ]
    },
    {
        name: 'Payments and Payouts',
        data: [
            {
                question: "How and when do I receive payments for my sales?",
                answer: "Payments are typically processed at regular intervals, such as weekly or monthly. You can choose your preferred payout method, and the funds will be transferred accordingly."
            },
            {
                question: "Are there any transaction fees or payment processing charges?",
                answer: "Transaction fees or payment processing charges may apply. These fees are typically deducted from your earnings. Review the fee schedule for specific information."
            },
            {
                question: "Can I choose my preferred payout method (e.g., bank transfer or PayPal)?",
                answer: "Yes, you can usually select your preferred payout method, including bank transfers, PayPal, or other options available on the platform."
            },
            {
                question: "What's the process for handling chargebacks or payment disputes?",
                answer: "We have a dispute resolution process in place for chargebacks or payment disputes. Contact our support team for guidance on resolving these issues."
            }
        ]
    },
    {
        name: 'Shipping and Delivery',
        data: [
            {
                question: "What are the shipping options and rates available to vendors?",
                answer: "We provide information on shipping options and rates within your vendor dashboard. You can choose from various shipping providers and services."
            },
            {
                question: "How can I generate shipping labels and track shipments?",
                answer: "Your vendor dashboard should have tools to generate shipping labels and track shipments. You can print labels and access tracking information."
            },
            {
                question: "Are there guidelines for packaging and labelling products?",
                answer: "We may have packaging and labelling guidelines to ensure a professional and consistent customer experience. Check your vendor resources for these guidelines."
            },
            {
                question: "What should I do in the case of lost or damaged shipments?",
                answer: "If a shipment is lost or arrives damaged, please contact the shipping carrier and follow their procedures for filing a claim. Additionally, notify our support team for further assistance."
            }
        ]
    },
    {
        name: 'Customer Relations',
        data: [
            {
                question: "How can I respond to customer inquiries and messages?",
                answer: "You can respond to customer inquiries and messages through the messaging system in your vendor dashboard. Prompt and polite responses are important for good customer relations."
            },
            {
                question: "What is the protocol for handling customer feedback and reviews?",
                answer: "Encourage customers to leave reviews and respond professionally to positive and negative feedback. Address customer concerns and issues promptly."
            },
            {
                question: "Can I offer promotions or discounts to attract more customers?",
                answer: "Yes, you can often create promotions and discounts in your vendor dashboard. Use these tools to attract and engage customers."
            },
            {
                question: "What is the marketplace's policy on customer privacy and data protection?",
                answer: "We take customer privacy and data protection seriously. We have policies and practices in place to safeguard customer information. Review our privacy policy for details."
            }
        ]
    },
    {
        name: 'Store Management',
        data: [
            {
                question: "How do I customize my vendor profile and store?",
                answer: "You can customize your vendor profile and store it in your vendor dashboard. Add a logo, banner, and information about your business to create a unique storefront."
            },
            {
                question: "What tools are available for analyzing sales and performance?",
                answer: "You can access sales and performance analytics within your vendor dashboard. Use these insights to track your progress and make informed business decisions."
            },
            {
                question: "Can I run sales or promotions within my store?",
                answer: "Yes, you can create and manage sales or promotions within your store using the available tools in your vendor dashboard."
            },
            {
                question: "What happens if I need to take a break or temporarily close my store?",
                answer: "If you need to take a break or close your store temporarily, you can typically use the 'Store Vacation' feature in your vendor dashboard to inform customers."
            }
        ]
    },
    {
        name: 'Policies and Compliance',
        data: [
            {
                question: "What are the marketplace's policies regarding prohibited items or services?",
                answer: "We have strict policies regarding prohibited items or services. Review our terms and conditions to ensure compliance."
            },
            {
                question: "How do I ensure that I comply with tax regulations and legal requirements?",
                answer: "Understanding and complying with your jurisdiction's tax regulations and legal requirements is essential. Seek guidance from legal and tax professionals as needed."
            },
            {
                question: "What should I do in case of copyright or intellectual property concerns?",
                answer: "If you have copyright or intellectual property concerns, please contact our support team for guidance and follow the prescribed processes for addressing these issues."
            },
            {
                question: "How does the marketplace handle disputes between vendors and customers?",
                answer: "We have a dispute resolution process in place to address issues between vendors and customers. Contact our support team for assistance if"
            }
        ]
    },
]
export const HelpQuestions = [
    {
        name: 'Order listings',
        data: [
            {
                title: "Order listings",
                icon: <LiaShippingFastSolid size={15} />,
                question: "How do I sign up as a vendor on the marketplace?",
                answer: "To become a vendor, visit our website and look for the 'Vendor Registration' or How to Sell on MamiMarkets. Follow the registration process, which may include providing essential information about your business and verifying your identity."
            },
            {
                title: "Order listings",
                icon: <LiaShippingFastSolid size={15} />,
                question: "What are the requirements for becoming a vendor?",
                answer: "Vendor requirements may vary, but typically, you'll need a valid business registration number and bank account details for payouts. Check our vendor registration page for specific requirements."
            },
            {
                title: "Order listings",
                icon: <LiaShippingFastSolid size={15} />,
                question: "How can I list my products or services on the platform?",
                answer: "After registering as a vendor, you can log in to your vendor dashboard. There, you'll find options to create and manage product listings. The steps to list your products include descriptions, prices, and images."
            },
            {
                title: "Order listings",
                icon: <LiaShippingFastSolid size={15} />,
                question: "Is there a fee or subscription cost for vendors?",
                answer: "Our fee structure may vary, and we may charge a subscription or commission fee based on your sales. Review the fee schedule in your vendor dashboard or our website for more details."
            }
        ]
    },
    {
        name: 'Product Listings',
        data: [
            {
                title: "Product Listings",
                icon: <CiCreditCard1 size={15} />,
                question: "How do I create and manage product listings?",
                answer: "You'll find tools to add, edit, and manage product listings in your vendor dashboard. You can input product details, including descriptions, prices, and inventory levels."
            },
            {
                title: "Product Listings",
                icon: <CiCreditCard1 size={15} />,
                question: "What are the guidelines for product descriptions and images?",
                answer: "We have guidelines to ensure accurate and appealing product descriptions and images. Follow these guidelines to maintain a high-quality product listing."
            },
            {
                title: "Product Listings",
                icon: <CiCreditCard1 size={15} />,
                question: "Can I offer variations of my products, such as different sizes or colours?",
                answer: "Yes, you can offer product variations. Use the provided tools to create product options, such as size or colour, and specify pricing and inventory for each variation."
            },
            {
                title: "Product Listings",
                icon: <CiCreditCard1 size={15} />,
                question: "How do I set prices and manage inventory for my listings?",
                answer: "You can set prices and manage inventory in your vendor dashboard. Adjust prices and update inventory quantities as needed to reflect the availability of your products."
            }
        ]
    },
    {
        name: 'Order Management',
        data: [
            {
                title: "Order Management",
                icon: <TbMapSearch size={15} />,
                question: "How do I process orders from customers?",
                answer: "When a customer places an order, you'll receive a notification in your vendor dashboard. Process the order by confirming the purchase, packing the items, and generating a shipping label."
            },
            {
                title: "Order Management",
                icon: <TbMapSearch size={15} />,
                question: "What is the order fulfilment process?",
                answer: "Order fulfilment involves packaging the products, creating a shipping label, and sending the order to the customer using your preferred shipping method."
            },
            {
                title: "Order Management",
                icon: <TbMapSearch size={15} />,
                question: "How can I communicate with customers about their orders?",
                answer: "Use the messaging system within your vendor dashboard to communicate with customers. Keep them informed about order status, shipping, and any inquiries they may have."
            },
            {
                title: "Order Management",
                icon: <TbMapSearch size={15} />,
                question: "What is the return and refund policy for orders I fulfil?",
                answer: "Our return and refund policies vary. Refer to our vendor terms and conditions or contact our support team for details on returns and refunds for orders you fulfil."
            }
        ]
    },
    {
        name: 'Payments and Payouts',
        data: [
            {
                title: "Payments and Payouts",
                icon: <CiCreditCard1 size={15} />,
                question: "How and when do I receive payments for my sales?",
                answer: "Payments are typically processed at regular intervals, such as weekly or monthly. You can choose your preferred payout method, and the funds will be transferred accordingly."
            },
            {
                title: "Payments and Payouts",
                icon: <CiCreditCard1 size={15} />,
                question: "Are there any transaction fees or payment processing charges?",
                answer: "Transaction fees or payment processing charges may apply. These fees are typically deducted from your earnings. Review the fee schedule for specific information."
            },
            {
                title: "Payments and Payouts",
                icon: <CiCreditCard1 size={15} />,
                question: "Can I choose my preferred payout method (e.g., bank transfer or PayPal)?",
                answer: "Yes, you can usually select your preferred payout method, including bank transfers, PayPal, or other options available on the platform."
            },
            {
                title: "Payments and Payouts",
                icon: <CiCreditCard1 size={15} />,
                question: "What's the process for handling chargebacks or payment disputes?",
                answer: "We have a dispute resolution process in place for chargebacks or payment disputes. Contact our support team for guidance on resolving these issues."
            }
        ]
    },
    {
        name: 'Shipping and Delivery',
        data: [
            {
                title: "Shipping and Delivery",
                icon: <TbTruckDelivery size={15} />,
                question: "What are the shipping options and rates available to vendors?",
                answer: "We provide information on shipping options and rates within your vendor dashboard. You can choose from various shipping providers and services."
            },
            {
                title: "Shipping and Delivery",
                icon: <TbTruckDelivery size={15} />,
                question: "How can I generate shipping labels and track shipments?",
                answer: "Your vendor dashboard should have tools to generate shipping labels and track shipments. You can print labels and access tracking information."
            },
            {
                title: "Shipping and Delivery",
                icon: <TbTruckDelivery size={15} />,
                question: "Are there guidelines for packaging and labelling products?",
                answer: "We may have packaging and labelling guidelines to ensure a professional and consistent customer experience. Check your vendor resources for these guidelines."
            },
            {
                title: "Shipping and Delivery",
                icon: <TbTruckDelivery size={15} />,
                question: "What should I do in the case of lost or damaged shipments?",
                answer: "If a shipment is lost or arrives damaged, please contact the shipping carrier and follow their procedures for filing a claim. Additionally, notify our support team for further assistance."
            }
        ]
    },
    {
        name: 'Customer Relations',
        data: [
            {
                title: "Customer Relations",
                icon: <PiInfo size={15} />,
                question: "How can I respond to customer inquiries and messages?",
                answer: "You can respond to customer inquiries and messages through the messaging system in your vendor dashboard. Prompt and polite responses are important for good customer relations."
            },
            {
                title: "Customer Relations",
                icon: <PiInfo size={15} />,
                question: "What is the protocol for handling customer feedback and reviews?",
                answer: "Encourage customers to leave reviews and respond professionally to positive and negative feedback. Address customer concerns and issues promptly."
            },
            {
                title: "Customer Relations",
                icon: <PiInfo size={15} />,
                question: "Can I offer promotions or discounts to attract more customers?",
                answer: "Yes, you can often create promotions and discounts in your vendor dashboard. Use these tools to attract and engage customers."
            },
            {
                title: "Customer Relations",
                icon: <PiInfo size={15} />,
                question: "What is the marketplace's policy on customer privacy and data protection?",
                answer: "We take customer privacy and data protection seriously. We have policies and practices in place to safeguard customer information. Review our privacy policy for details."
            }
        ]
    },
    {
        name: 'Store Management',
        data: [
            {
                title: "Store Management",
                icon: <TbMapSearch size={15} />,
                question: "How do I customize my vendor profile and store?",
                answer: "You can customize your vendor profile and store it in your vendor dashboard. Add a logo, banner, and information about your business to create a unique storefront."
            },
            {
                title: "Store Management",
                icon: <TbMapSearch size={15} />,
                question: "What tools are available for analyzing sales and performance?",
                answer: "You can access sales and performance analytics within your vendor dashboard. Use these insights to track your progress and make informed business decisions."
            },
            {
                title: "Store Management",
                icon: <TbMapSearch size={15} />,
                question: "Can I run sales or promotions within my store?",
                answer: "Yes, you can create and manage sales or promotions within your store using the available tools in your vendor dashboard."
            },
            {
                title: "Store Management",
                icon: <TbMapSearch size={15} />,
                question: "What happens if I need to take a break or temporarily close my store?",
                answer: "If you need to take a break or close your store temporarily, you can typically use the 'Store Vacation' feature in your vendor dashboard to inform customers."
            }
        ]
    },
    {
        name: 'Policies and Compliance',
        data: [
            {
                title: "Policies and Compliance",
                icon: <PiInfo size={15} />,
                question: "What are the marketplace's policies regarding prohibited items or services?",
                answer: "We have strict policies regarding prohibited items or services. Review our terms and conditions to ensure compliance."
            },
            {
                title: "Policies and Compliance",
                icon: <PiInfo size={15} />,
                question: "How do I ensure that I comply with tax regulations and legal requirements?",
                answer: "Understanding and complying with your jurisdiction's tax regulations and legal requirements is essential. Seek guidance from legal and tax professionals as needed."
            },
            {
                title: "Policies and Compliance",
                icon: <PiInfo size={15} />,
                question: "What should I do in case of copyright or intellectual property concerns?",
                answer: "If you have copyright or intellectual property concerns, please contact our support team for guidance and follow the prescribed processes for addressing these issues."
            },
            {
                title: "Policies and Compliance",
                icon: <PiInfo size={15} />,
                question: "How does the marketplace handle disputes between vendors and customers?",
                answer: "We have a dispute resolution process in place to address issues between vendors and customers. Contact our support team for assistance if"
            }
        ]
    },
]