import {
    loginReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
    creatUserReducer,
    confirmEmailReducer,
    resendActivationEmailReducer,
    confirmAccountPhoneReducer,
    getOtpForVendorPhoneReducer,
    getUrlReducer,
    getStoreCatsReducer,
    uploadStoreLogoReducer
} from "../reducers/onboarding/outbound.reducer"

import {
    createStoreProductReducer,
    uploadStoreBulkProductsReducer,
    updateStoreProductReducer,
    getProductMetaDataReducer,
    getImageGalleryReducer,
    getProductListReducer,
    toggleProductReducer,
    getAProductReducer,
    updateProductImageReducer,
    getStoreOrderReducer,
    getStoreOrderDetailsReducer,
    createStorePayoutReducer,
    getStorePayoutAccReducer,
    getStoreDetailsReducer,
    updateStoreDetailsReducer,
    getDashbardTopDataReducer,
    getDashbardMiddleDataReducer,
    getDashbardBottompDataReducer,

    createPayoutInfoTokenReducer,
    deactivatePayoutAccountReducer,
    getPayoutPendingLinkReducer,
    getStorePaymentsHistoryReducer,

    changeOrderStatusReducer,
    addNewStoreReducer
} from "../reducers/inapp/inapp.reducer"



export const inAppReducers = {
    createStoreProductReducer,
    uploadStoreBulkProductsReducer,
    updateStoreProductReducer,
    getProductMetaDataReducer,
    getImageGalleryReducer,
    getProductListReducer,
    toggleProductReducer,
    getAProductReducer,
    updateProductImageReducer,
    getStoreOrderReducer,
    getStoreOrderDetailsReducer,
    createStorePayoutReducer,
    getStorePayoutAccReducer,
    getStoreDetailsReducer,
    updateStoreDetailsReducer,
    getDashbardTopDataReducer,
    getDashbardMiddleDataReducer,
    getDashbardBottompDataReducer,

    createPayoutInfoTokenReducer,
    deactivatePayoutAccountReducer,
    getPayoutPendingLinkReducer,
    getStorePaymentsHistoryReducer,

    changeOrderStatusReducer,
    addNewStoreReducer
}



export const outboundReducers = {
    loginReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
    creatUserReducer,
    confirmEmailReducer,
    resendActivationEmailReducer,
    confirmAccountPhoneReducer,
    getOtpForVendorPhoneReducer,
    getUrlReducer,
    getStoreCatsReducer,
    uploadStoreLogoReducer
}


