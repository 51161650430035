import { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import { connect } from 'react-redux';

import { NavLink } from "react-router-dom"
import { Link } from "react-router-dom"
import Logo from "../../../assets/logo2.png"
import Dash from "../../../assets/icons/dash.svg"
import Prod from "../../../assets/icons/products.svg"
import OrderIco from "../../../assets/icons/order.svg"
import Walmart from "../../../assets/icons/walmart.svg"
import UserIco from "../../../assets/icons/users.svg"
import ChatIco from "../../../assets/icons/chat.svg"
import SettingIco from "../../../assets/icons/settings.svg"
import NotiIco from "../../../assets/icons/noti.svg"
import LogBg from "../../../assets/icons/logbg.svg"
import CloseIco from "../../../assets/icons/close.svg"
import ShopIco from "../../../assets/icons/shop.png"
import "./index.scss"
// import { HeaderProps } from "../../../types"
import { authActions } from "../../../redux/actions/onboarding"



// type OwnProps = HeaderProps & ReturnType<typeof mapStateToProps>;
export const SideBarWrap = ({ pageProps }: any) => {
    // let loggedInData = props.loginRequest.request_data.response
    let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}",
        userStores: any[];




    if (loggedAccountInfo) {
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        userStores = loggedAccountInfo.myStores;

    }

  
    const BizName = () => {
        return (
            <div className="bizname-wrap">
                <Link className="logo-head" to={`/app`}>
                    <img src={Logo} alt="" />
                </Link>
                <div className="store_logo_n_earnings">
                    <div className="store_logo">
                        <img src={!pageProps?.noStores ? loggedAccountInfo.selectedStore.logoUrl : ShopIco} alt="" />
                    </div>
                    <div className="earnings_wrap">
                        <div className="heading">Total Earnings</div>
                        <div className="earning_amount">{!pageProps?.noStores ? `${loggedAccountInfo.selectedStore?.totalEarnings}` : "N/A"}</div>
                    </div>
                </div>

                {/* <div className="bizname">{loggedAccountInfo.selectedStore.name}</div> */}
                {/* <div className="biz-type"> Business Account</div> */}
                {/* {
                    userStores.length >= 1 &&
                    <div className="switch-biz-wrap">
                        <div className="switch-cta">Switch business</div>
                    </div>
                } */}
            </div>
        )
    }



    const MenuItems = () => {
        return (
            <div className="all-menu-items">
                <div className="menu-item">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app">
                        <div className="menu-icon dash-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Dashboard</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-products">
                        <div className="menu-icon prod-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Products</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-orders">
                        <div className="menu-icon order-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Orders</div>
                    </NavLink>
                </div>
                {/* <div className="menu-item">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/my-customers">
                        <div className="menu-icon virtual">
                            <img src={UserIco} alt="" />
                        </div>
                        <div className="menu-txt">Customers</div>
                    </NavLink>
                </div> */}

                <div className="menu-item">
                    <NavLink end className="soon" to="#">
                        <div className="menu-icon chat-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Chat <span className="soon-txt">Coming soon</span></div>
                    </NavLink>
                </div>

                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile">
                        <div className="menu-icon settings-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Account Settings</div>
                    </NavLink>
                </div>
                <div className="menu-item">
                    <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/help-center">
                        <div className="menu-icon settings-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt">Help Center</div>
                    </NavLink>
                </div>
                <div className="menu-item   ">
                    <div onClick={() => pageProps.logoutAction()} className="logout_wrap" >
                        <div className="menu-icon logout-ico">
                            <span></span>
                        </div>
                        <div className="menu-txt logout">Logout</div>
                    </div>
                </div>
                {/* <div className="other-menu">
                    <div className="title">Support</div>
                    <div className="menu-item">
                        <NavLink className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/inbox">
                            <div className="menu-icon identity">
                                <img src={NotiIco} alt="" />
                            </div>
                            <div className="menu-txt">Inbox</div>
                        </NavLink>
                    </div>
                </div> */}


            </div>
        )
    }


    return (

        <div className={!pageProps?.isMobile ? "sidebar-wrap" : "sidebar-wrap withmobile"}>
            {pageProps?.isMobile &&
                <div className="sidebar-close" onClick={() => pageProps?.setShowVisible(false)}>
                    <img src={CloseIco} alt="" />
                </div>
            }

            <BizName />
            <MenuItems />
        </div>

    )
}

const SideBar = (pageProps: any, isMobile?: any) => {
    // const { productId } = useParams();
    // useEffect(() => {


    //         getProductDetail({
    //             pageProps,
    //             payload: "CLEAR"
    //         })



    // }, [])

    return (
        <>
            {pageProps?.isMobile && <div className="underlay_mobile"></div>}
            <SideBarWrap pageProps={pageProps} isMobile={isMobile} />
        </>
    )
}
const mapDispatchToProps = {
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => {
    return {
        // loginRequest: state.onboardingOutboundReducers.loginReducer,
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);