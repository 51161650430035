import React, { useEffect } from 'react';
// import { NavLink} from 'react-router-dom';
import { Fragment } from "react";
import Table from 'react-bootstrap/Table'
import { TableProps } from "../../../types";
// import { AlertMsg } from "../alert-msg"
import "./index.scss";
// import EmptyTxtn from "../../../assets/images/empty.png"
import TablePagination from "./pagination";
// const EmptyRecords = (props: TableProps) => {
//     const { emptyRecordsMessage, hasCta, hasCtaText, ctaCallback } = props;
//     return (
//         <div className="empty-txtn">
//             <img src={EmptyTxtn} alt="" />
//             <div className="emtpy-msg ">
//                 {emptyRecordsMessage}
//             </div>
//             {hasCta &&
//                 <button onClick={ctaCallback} className="btn refresh-btn" type="submit">{hasCtaText || "Refresh"}</button>
//             }
//         </div>
//     )
// }

// const TablePagination = ()=>{
//     return (

//     )
// }

export const TableComponent: React.FC<TableProps> = (props) => {

    const { childComponent,
        classnames,
        isEmptyRecords,filterOptions,
        requestStatus, failureMsg,
        isFailed, requestData,
        hasRequested, waitingState } = props;

    // const RenderTable :  React.FC  = () : React.ReactElement  =>{

    //     return(

    //             // <Table  hover className={this.props.classnames}>
    //             <Table striped hover className={classnames}>
    //                 {childComponent}
    //             </Table>
    //     )

  
    



    const LoadingData = () => {
        return (
            <div className="loading-data">
                Please wait ...
            </div>
        )
    }



    return (
        <Fragment>

            <div className="tablecomponent-container">
                <div className="container">
                    <Table  className={classnames}>


                        {childComponent}
                    </Table>
                    {/* {(!isEmptyRecords && hasRequested && !requestStatus && !isFailed) &&
                        <div className="row">
                            <Table hover className={classnames}>


                                {childComponent}
                            </Table>
                            
                            {requestData?.result &&
                                <TablePagination
                                    totalPages={
                                        requestData
                                            .totalPages
                                    }
                                    currPage={
                                        requestData
                                            .currentPage
                                    }
                                    currRecordsCount={requestData.result.length}
                                    totalRows={
                                        requestData
                                            .totalRows
                                    }
                                    
                                    tempData={requestData}
                                    pagesCountToshow={4}
                                    refreshFunc={() => { }}
                                
                                />
                            }
                        </div>
                    }
                    {
                        requestStatus &&
                        <LoadingData />
                    }
                  


                    {!hasRequested &&
                        <div className="row">
                            <Table hover className={classnames}>


                                {waitingState}
                            </Table>
                        </div>
                    }


                    {(isEmptyRecords && hasRequested) &&
                        <div className="row">
                            <Table hover className={classnames}>


                                {waitingState}
                            </Table>
                            <EmptyRecords {...props} />
                        </div>

                    } */}

                </div>
            </div>
        </Fragment>
    );

}





