import { connect } from 'react-redux';
import { InAppTemplate } from "../../../shared/templates/inapp";
import Avi1 from "../../../assets/icons/avatar1.svg"
import Avi2 from "../../../assets/icons/avatar2.svg"
import Avi3 from "../../../assets/icons/avatar3.svg"
import Avi4 from "../../../assets/icons/avatar4.svg"
import Caret1 from "../../../assets/icons/left-caret.svg"
import Caret2 from "../../../assets/icons/right-caret.svg"
import "../index.scss"



const InAppContent = () => {
    return (
        <div className="inapp-content">
            <div className="page-content customer-wrap with-heading">
                <div className="page-heading">Customers</div> 
                <div className="customer-list-wrap">
                    <div className="all-customers">
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi1} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi3} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi4} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi1} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi4} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi1} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi4} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi1} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                        <div className="each-customer cards">
                            <div className="customer-info">
                                <div className="img-wrap">
                                    <img src={Avi2} alt="" />
                                </div>
                                <div className="other-info">
                                    <div className="customer-name">David James</div>
                                    <div className="customer-location">United States</div>
                                    <div className="customer-date">23rd Feb, 2021</div>
                                </div>
                            </div>
                            <div className="buys">
                                <div className="amount">
                                    <sup>$</sup>200.90
                                </div>
                                <div className="amount-title">Total Buys</div>
                            </div>
                        </div>
                    </div>
                    <div className="pagination-wrap">
                        <div className="prev-page each-page">
                            <img src={Caret1} alt="" />
                        </div>
                        <div className="prev-page each-page active">1</div>
                        <div className=" each-page">2</div>
                        <div className=" each-page">3</div>
                        <div className=" each-page">4</div>
                        <div className=" each-page">5</div>
                        <div className="next-page each-page">
                            <img src={Caret2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CustomersList = () => {
    return (
        <InAppTemplate pageHeadingTitle="My Customers" childComponent={<InAppContent />} />
    )
}

const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {


    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);