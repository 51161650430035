import { connect } from 'react-redux';
import {  useState } from 'react';
import { NavLink } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Mail from "../../../assets/icons/mail2.svg"
import Walmart from "../../../assets/icons/walmart.svg"
import { TableComponent } from "../../../shared/components/table"
import Modal from 'react-bootstrap/Modal'

const InAppContent = () => {
    const [showInvite, setShowInviteModal] = useState(false);
    let detailsValidationSchema = Yup.object().shape({
        staffMail: Yup.string()
            .required("Required")

    });
    let initialValues: any = {
        staffMail: "",
    }
    const ReportTable = () => {
        return (
            <>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Robert Richard</td>
                        <td>desk@richoco.studio</td>
                        <td>
                            <button className="btn remove-btn">Remove</button>
                        </td>
                    </tr>
                </tbody>
            </>
        )
    }

    const InviteStaff = () => {
        return (
            <Modal className="invite_staff_wrap" show={showInvite} onHide={() => setShowInviteModal(false)}>
                <Modal.Body>
                    <div className="business-info">
                        <img src={Walmart} alt="" />
                        <div className="business-name">Walmart</div>
                        <div className="sub-info">Business Account</div>
                    </div>
                    <div className="send-head">Send Invite Link</div>
                    <Formik
                            initialValues={initialValues}
                            validationSchema={detailsValidationSchema}
                            onSubmit={(values: any, actions) => {

                            }}
                        >
                            {(props: FormikProps<any>) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    setFieldTouched,
                                    handleChange,
                                    setFieldValue,
                                } = props;
                                return (
                                    <Form>
                                        <div className="form-wrap">
                                            <div className="">

                                                <div className="form-group">
                                                    <Field
                                                        id="staffMail"
                                                        value={values.staffMail}
                                                        name="staffMail"
                                                        type="text"
                                                        placeholder="Staff email"
                                                        onChange={handleChange}
                                                        className={`input-wrap ${errors.staffMail && touched.staffMail ?
                                                            "is-invalid-input "
                                                            : values.staffMail !== "" ? "valid-input" : ""}`}
                                                    />
                                                    <ErrorMessage name="staffMail" className="form-input-error" component="div" />
                                                </div>
                                               



                                            </div>

                                        </div>
                                        <div className="invite-cta">
                                            <button className="btn">Send Invite</button>
                                        </div>
                                    </Form>
                                )
                            }}

                        </Formik>
                </Modal.Body>
            </Modal>
        )
    }
    return (
        <div className="inapp-content">
            <div className="page-content cards setting-page">
                {showInvite && <InviteStaff/>}
                <div className="page-heading">
                    <h3>Settings</h3>
                </div>
                <div className="page-menus">
                <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile">
                    Store Details
                    </NavLink>
                    {/* <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/store">
                        Store Details
                    </NavLink> */}
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/staff">
                        Staff Account
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/withdrawal">
                        Withdrawal Method
                    </NavLink>
                </div>

            </div>
            <div className="cards setting-page">
                <div className="light-heading">
                    <h4>Staff Account - 1 of 5</h4>
                    <button className="btn" onClick={()=>setShowInviteModal(true)}>
                        <img src={Mail} alt="" />
                        Invite Someone
                    </button>
                </div>
                <div className="staff-table">
                    <TableComponent
                        childComponent={<ReportTable />}
                    />
                </div>

            </div>
        </div>
    )
}

const StaffProfiles = () => {
    return (
        <InAppTemplate pageHeadingTitle="Personal Profile" childComponent={<InAppContent />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {


    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(StaffProfiles);