import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';
import { connect } from 'react-redux';
import { useSearchParams, useLocation } from "react-router-dom";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { OnBoardingFormValues } from "../../../types"
import { Modal } from 'react-bootstrap'
import { history } from "../../../shared/_helpers/history";

import Logo from "../../../assets/logo2.png"
import FB from "../../../assets/icons/fb.svg"
import TW from "../../../assets/icons/tw.svg"
import IG from "../../../assets/icons/ig.svg"
import YT from "../../../assets/icons/yt.svg"
import Mail from "../../../assets/icons/mail.svg"
import Call from "../../../assets/icons/call.svg"
import SMS from "../../../assets/icons/sms.svg"
import { allowNumbersOnly } from "../../../shared/utils"
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"


const confirmAccountEmailAction = async ({ pageProps, action }: any) => {

    await pageProps.ConfirmAccountEmail(action);
}

const getOtpForConfirmPhoneAction = async ({ pageProps, action }: any) => {

    await pageProps.GetOtpForVendorPhone(action);
}

const confirmPhonenumAction = async ({ pageProps, action }: any) => {

    await pageProps.ConfirmAccountPhone(action);
}

const FooterWrap = () => {
    return (
        <div className="footer_wrap">
            <div className="footer_navs">
                <div className="each_navlist_wrap">
                    <div className="logo_wrap">
                        <img src={Logo} />
                    </div>
                    <div className="nav_items">
                        <div className="nav_heading">Get to Know Us</div>
                        <Link to="/careers">Careers</Link>
                        <Link to="/blog">Blog</Link>
                        <Link to="/contact">Contact Us </Link>
                        <Link to="/help">Help</Link>
                        <Link to="/press">Press Releases</Link>
                    </div>
                </div>
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Top Cities</div>
                        <Link to="/careers">Toronto</Link>
                        <Link to="/blog">Calgary</Link>
                        <Link to="/contact">
                            Edmonton
                        </Link>
                        <Link to="/help">
                            Vancouver
                        </Link>
                        <Link to="/press">
                            Winnipeg
                        </Link>
                        <Link to="/press">
                            Halifax
                        </Link>
                        <Link to="/press">
                            Ottawa
                        </Link>
                        <Link to="/press">
                            Montreal
                        </Link>
                    </div>
                </div>
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Top Categories</div>
                        <Link to="/careers">
                            Produce
                        </Link>
                        <Link to="/blog">
                            Diary &amp; Eggs
                        </Link>
                        <Link to="/contact">
                            Health
                        </Link>
                        <Link to="/help">
                            Fashion
                        </Link>
                        <Link to="/press">
                            Beauty
                        </Link>
                        <Link to="/press">
                            Meats
                        </Link>
                        <Link to="/press">
                            Bikes
                        </Link>
                    </div>
                </div>
                <div className="each_navlist_wrap">
                    <div className="nav_items">
                        <div className="nav_heading">Become a Seller</div>
                        <Link to="/careers">
                            How to List Your Shop
                        </Link>
                        <Link to="/blog">
                            Benefits of Selling on MamiMarkets
                        </Link>
                        <Link to="/contact">
                            Start Selling on MamiMarkets
                        </Link>
                    </div>
                </div>
            </div>
            <div className="subsribe_box">
                <div className="sub_heading">Get Bi-weekly Updates from Us!</div>
                <div className="input_wrap">
                    <input type="text" placeholder="Email" />
                    <button type="button" className="btn subscribe_btn">Subscribe</button>
                </div>
            </div>
            <div className="page_bottom">
                <div className="page_bottom_section">
                    <div className="bottom_section_heading">Contact</div>
                    <div className="bottom_section_content">hello@mamimarkets.com</div>
                    <div className="social_links bottom_section_content">
                        <div className="each_icon">
                            <img src={FB} />
                        </div>
                        <div className="each_icon">
                            <img src={IG} />
                        </div>
                        <div className="each_icon">
                            <img src={TW} />
                        </div>
                        <div className="each_icon">
                            <img src={YT} />
                        </div>
                    </div>
                </div>
                <div className="page_bottom_section">
                    <div className="bottom_section_heading">Nigeria</div>
                    <div className="bottom_section_content">126 Joel Street Address,</div>
                    <div className="bottom_section_content">Ikeja GRA, Ikeja,</div>
                    <div className="bottom_section_content">Lagos, Nigeria.</div>
                </div>
                <div className="page_bottom_section">
                    <div className="each_footlink">
                        <Link to="/legal">
                            Legal
                        </Link>
                    </div>
                    <div className="each_footlink">
                        <Link to="/policy">
                            Privacy Policy
                        </Link>
                    </div>
                    <div className="each_footlink">
                        <Link to="/terms">
                            Terms and Conditions
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PhonePop = ({ pageProps, isPhonePopShown, setPhonePopShown }: any) => {
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    let statusValidationSchema = Yup.object().shape({
        phoneCode: Yup.string()
            .required("Required")

    });

    let initialValues: any = {
        phoneCode: "",
    }
    return (
        <>

            <Modal className="phone-pop" show={isPhonePopShown} onHide={() => setPhonePopShown(false)}>
                <Modal.Header closeButton>
                {(pageProps?.confirmAccountPhoneRequest?.request_status
                                        && pageProps.confirmAccountPhoneRequest.request_status !== "CONFIRM_PHONE_SUCCESS") &&
                    <Modal.Title className="spread">
                        Enter the Code
                        <div className="sub-txt">Type in the code sent to your phone or through the phone call.</div>
                    </Modal.Title>
                    }
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={statusValidationSchema}
                    onSubmit={(values: any, actions) => {
                        // setPhonePopShown(false)
                        confirmPhonenumAction({
                            pageProps,
                            action: {
                                email: params.get("email"),
                                // code:"121212"
                                code: values.phoneCode
                            }
                        })
                        // history.replace("/app")
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            setFieldTouched,
                            handleChange,
                            setFieldValue,
                        } = props;
                        return (
                            <Form>
                                <Modal.Body>
                                    {(pageProps?.confirmAccountPhoneRequest?.request_status
                                        && pageProps.confirmAccountPhoneRequest.request_status !== "CONFIRM_PHONE_SUCCESS") &&
                                        <>

                                            <div className="form-group">

                                                <div className={errors.phoneCode && touched.phoneCode ? "invalid-input-wrap" : ""}>
                                                    <Field
                                                        value={allowNumbersOnly(values.phoneCode)}
                                                        name="phoneCode"
                                                        type="text"
                                                        placeholder="Enter code"
                                                        onChange={handleChange}
                                                        className={`form-control inapp-input ${errors.phoneCode && touched.phoneCode ?
                                                            "is-invalid-input "
                                                            : !errors.phoneCode && values.phoneCode !== "" ? "valid-input" : ""}`}
                                                    >

                                                    </Field>
                                                </div>
                                                <ErrorMessage name="phoneCode" className="form-input-error" component="div" />
                                            </div>
                                            {pageProps?.confirmAccountPhoneRequest?.request_status
                                                && pageProps.confirmAccountPhoneRequest.request_status === "CONFIRM_PHONE_FAILURE" &&
                                                <AlertMsg type="error" message={pageProps.confirmAccountPhoneRequest?.request_data.error} />
                                            }
                                            <button className="btn" type="submit"
                                                disabled={pageProps.confirmAccountPhoneRequest.is_request_processing}
                                            >
                                                {pageProps.confirmAccountPhoneRequest.is_request_processing ? "Please wait..." : "Done"}
                                            </button>
                                        </>
                                    }
                                    {(pageProps?.confirmAccountPhoneRequest?.request_status
                                        && pageProps.confirmAccountPhoneRequest.request_status === "CONFIRM_PHONE_SUCCESS") &&
                                        <div className="success-message-wrap">
                                            <div className="success-animation">
                                                <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                            </div>
                                            <div className="success-message-txt">Your Phone number was successfully verified</div>

                                        </div>
                                    }
                                </Modal.Body>



                            </Form>
                        )
                    }}

                </Formik>


            </Modal>
        </>

    )
}
const VerificationContent = ({ pageProps }: any) => {
    const [isPhonePopShown, setPhonePopShown] = useState(false)
    const [phoneOtpRequest, setPhoneOtpRequest] = useState(false)
    const location = useLocation()
    const params = new URLSearchParams(location.search);
    // const [searchParams] = useSearchParams();

    useEffect(() => {
        // pageProps.logoutAction();
        getOtpForConfirmPhoneAction({
            pageProps,
            action: "CLEAR"
        })
        confirmPhonenumAction({
            pageProps,
            action: "CLEAR"
        })
        // confirmAccountEmailAction({
        //     pageProps,
        //     action: "CLEAR"
        // })
        if (pageProps.confirmEmailRequest.request_status !== "CONFIRM_EMAIL_SUCCESS") {
            confirmAccountEmailAction({
                pageProps,
                action: {
                    email: params.get("email"),
                    token: params.get("token")
                }
            })
        }

    }, [])
    useEffect(() => {
        if (phoneOtpRequest) {
            if (pageProps?.confirmAccountPhoneRequest?.request_status
                && pageProps.confirmAccountPhoneRequest.request_status !== "CONFIRM_PHONE_SUCCESS") {

                getOtpForConfirmPhoneAction({
                    pageProps,
                    action: `?email=${params.get("email")}`
                })
                setPhoneOtpRequest(false)

            }
        }

    }, [phoneOtpRequest])
    return (
        <div className="verify-content">
            {(pageProps?.getOtpForVendorPhoneRequest?.request_status
                && pageProps.getOtpForVendorPhoneRequest.request_status === "GET_VENDOR_PHONE_OTP_SUCCESS") &&
                <PhonePop pageProps={pageProps}
                    // isPhonePopShown={isPhonePopShown} setPhonePopShown={setPhonePopShown} />}
                    isPhonePopShown={pageProps.getOtpForVendorPhoneRequest.request_status === "GET_VENDOR_PHONE_OTP_SUCCESS"}
                    setPhonePopShown={() => {
                        getOtpForConfirmPhoneAction({
                            pageProps,
                            action: "CLEAR"
                        })
                    }}
                />
            }
            <div className="verify-heading">
                <div className="verify-title">Email Verification </div>
                <div className="other-txt">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, </div>
            </div>

            {(pageProps?.confirmEmailRequest?.request_status
                && pageProps.confirmEmailRequest.request_status === "CONFIRM_EMAIL_SUCCESS") &&
                <div className="verify-steps">
                    <div className="each-step">
                        <div className="step_heading">
                            <h4>Step 1</h4>
                            <h3>Email Verification</h3>
                            <div className="step-txt"></div>
                        </div>
                        <div className="step-details">
                            <div className="mail-wrap">
                                <img src={Mail} alt="" />
                                <div className="mail-txt">Email Verified Successfully</div>
                            </div>
                        </div>
                    </div>
                    <div className="each-step">
                        <div className="step_heading">
                            <h4>Step 2</h4>
                            <h3>Verify Phone number</h3>
                            <div className="step-txt">How would like to get your verification code?</div>
                        </div>
                        <div className="step-details">
                            <div className="each-phone-option" onClick={() => setPhonePopShown(true)}>
                                <div className="icon">
                                    <img src={Call} alt="" />
                                </div>
                                <div className="text">Phone Call</div>
                            </div>
                            <div className="each-phone-option" onClick={() => {
                                setPhoneOtpRequest(true)

                            }}>
                                <div className="icon">
                                    <img src={SMS} alt="" />
                                </div>
                                <div className="text">
                                    {pageProps.getOtpForVendorPhoneRequest.is_request_processing ? "Please wait..." : "Text Message"}

                                </div>
                            </div>
                            {(pageProps?.getOtpForVendorPhoneRequest?.request_status
                                && pageProps.getOtpForVendorPhoneRequest.request_status === "GET_VENDOR_PHONE_OTP_FAILURE") &&
                                <AlertMsg type="error" message={pageProps.getOtpForVendorPhoneRequest?.request_data.error} />
                            }
                        </div>
                    </div>
                    <div className="each-step">
                        <div className="step_heading">
                            <h4>Step 3</h4>
                            <h3>Start Selling</h3>
                        </div>
                        <div className="step-details">
                            <div className={(pageProps?.confirmAccountPhoneRequest?.request_status
                                && pageProps.confirmAccountPhoneRequest.request_status === "CONFIRM_PHONE_SUCCESS") ? "selling activated" : "selling"}
                                onClick={() => {
                                    if ((pageProps?.confirmAccountPhoneRequest?.request_status
                                        && pageProps.confirmAccountPhoneRequest.request_status === "CONFIRM_PHONE_SUCCESS")) {
                                        history.replace("/")
                                    }
                                }}>
                                Start selling on Mamimarket
                            </div>
                        </div>
                    </div>
                </div>
            }
            {pageProps?.confirmEmailRequest?.request_status
                && pageProps.confirmEmailRequest.request_status === "CONFIRM_EMAIL_FAILURE" &&
                <div className="verify-steps normal">
                    <AlertMsg type="error" message={pageProps.confirmEmailRequest?.request_data.error} />
                    <div className="try-again" onClick={() => {
                        confirmAccountEmailAction({
                            pageProps,
                            action: { email: params.get("email") },
                            token: { token: params.get("token") },
                        })
                    }}>Try again</div>
                </div>
            }
            {pageProps?.confirmEmailRequest?.request_status
                && pageProps.confirmEmailRequest.request_status === "CONFIRM_EMAIL_PENDING" &&
                <div className="verify-steps normal">
                    <div className="loading-txt">Please wait...</div>
                </div>
            }
        </div>
    )
}

const OnboardVerification = (pageProps: any) => {
    return (
        <div className="verify-wrap">
            <div className="verify-head">
                <div className="logo-head">
                    <img src={Logo} alt="" />
                </div>
            </div>
            <VerificationContent pageProps={pageProps} />
            <FooterWrap />
        </div>
    )
}


const mapDispatchToProps = {
    ConfirmAccountEmail: authActions.ConfirmAccountEmail,
    GetOtpForVendorPhone: authActions.GetOtpForVendorPhone,
    ConfirmAccountPhone: authActions.ConfirmAccountPhone,
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
    confirmEmailRequest: state.onboardingOutboundReducers.confirmEmailReducer,
    confirmAccountPhoneRequest: state.onboardingOutboundReducers.confirmAccountPhoneReducer,
    getOtpForVendorPhoneRequest: state.onboardingOutboundReducers.getOtpForVendorPhoneReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(OnboardVerification);