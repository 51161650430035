import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { authConstants } from "../../action-constants/onboarding.constants";

import { Dispatch } from 'redux';

import { history } from "../../../shared/_helpers/history";
import {
    handleRequestErrors,
} from "../../../shared/utils";



const Login = (loginPayload: any, isResendActivation?:boolean) => {

    if (loginPayload !== "CLEAR") {

        let userData: any;

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.LOGIN, http_method: "POST", data: loginPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    if (response?.data) {
                        userData = { ...response.data };
                        userData.lastLogForAuth = Date.now();
                        localStorage.setItem("mamiVendorXAuth", JSON.stringify(userData));

                        let apiConsume2 = ApiService.request({ url: `${routes.GET_MY_STORES}?page=1&size=100`, http_method: "GET" });
                        dispatch(request(apiConsume2));

                        return apiConsume2
                            .then((response2: any) => {
                                if (response2?.data?.results && response2?.data?.results.length >= 1) {
                                    userData.myStores = response2?.data.results;
                                    userData.selectedStore = response2?.data.results[0];
                                    localStorage.setItem("mamiVendorXAuth", JSON.stringify(userData));


                                    let apiConsume3 = ApiService.request({ url: `${routes.GET_PROFILE}`, http_method: "GET" });
                                    dispatch(request(apiConsume3));

                                    return apiConsume3
                                        .then((response3: any) => {
                                            let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                            loggedAccountInfo = JSON.parse(loggedAccountInfo);

                                            userData = { ...loggedAccountInfo };
                                            userData.userProfile = response3.data
                                            localStorage.setItem("mamiVendorXAuth", JSON.stringify(userData));

                                            // userData.myStores = response2?.data.results;
                                            // userData.selectedStore = response2?.data.results[0];
                                            // userData.userProfile = response3
                                            // localStorage.setItem("mamiVendorXAuth", JSON.stringify(userData));
                                            dispatch(success(response.data.data))
                                            history.replace("/app")

                                        })
                                        .catch((error: any) => {
                                            dispatch(failure(handleRequestErrors(error)));
                                        });


                                } else {


                                    let apiConsume3 = ApiService.request({ url: `${routes.GET_PROFILE}`, http_method: "GET" });
                                    dispatch(request(apiConsume3));

                                    return apiConsume3
                                        .then((response3: any) => {
                                            let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                            loggedAccountInfo = JSON.parse(loggedAccountInfo);

                                            userData = { ...loggedAccountInfo };
                                            userData.userProfile = response3.data
                                            localStorage.setItem("mamiVendorXAuth", JSON.stringify(userData));

                                            // userData.myStores = response2?.data.results;
                                            // userData.selectedStore = response2?.data.results[0];
                                            // userData.userProfile = response3
                                            // localStorage.setItem("mamiVendorXAuth", JSON.stringify(userData));
                                            dispatch(success(response.data.data))
                                            history.replace("/app/no-stores")

                                        })
                                        .catch((error: any) => {
                                            dispatch(failure(handleRequestErrors(error)));
                                        });
                                    // dispatch(success(response2.data))
                                    // history.replace("/app/no-stores")
                                }
                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.LOGIN_USER_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.LOGIN_USER_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.LOGIN_USER_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.LOGIN_USER_RESET, clear_data: "" };
    }
}

const ForgotPassword = (loginPayload: any) => {

    if (loginPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.INITIATE_FORGOT_PW, http_method: "POST", data: loginPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success({ email: loginPayload.email }))

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.FORGOT_PASSWORD_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.FORGOT_PASSWORD_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.FORGOT_PASSWORD_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.FORGOT_PASSWORD_RESET, clear_data: "" };
    }
}



const ResetPassword = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.RESET_PW, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // if (response.data.succeeded === true && response.data.message !== "Invalid token supplied.") {

                    //     dispatch(success(response.data))
                    // } else {
                    //     if (response.data.message === "Invalid token supplied.") {
                    //         dispatch(failure("Invalid token supplied."));
                    //     } else {
                    //         dispatch(failure(response.data.errors[0]));
                    //     }

                    // }
                    // history.replace("/account/reset-password/verify")
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {

        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.COMPLETE_PASSWORD_RESET_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.COMPLETE_PASSWORD_RESET_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.COMPLETE_PASSWORD_RESET_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.COMPLETE_PASSWORD_RESET_RESET, clear_data: "" };
    }
}

const GetStoreCategories = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORES_CATS}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.GET_STORE_CATS_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.GET_STORE_CATS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.GET_STORE_CATS_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.GET_STORE_CATS_RESET, clear_data: "" };
    }
}


const GetStoreUrl = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        


        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_URL}/${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));

            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {
                    

                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.GET_URL_OPTIONS_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.GET_URL_OPTIONS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.GET_URL_OPTIONS_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.GET_URL_OPTIONS_RESET, clear_data: "" };
    }
}

const GetOtpForVendorPhone = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_OTP_FOR_PHONE_CONFIRM}${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.GET_VENDOR_PHONE_OTP_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.GET_VENDOR_PHONE_OTP_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.GET_VENDOR_PHONE_OTP_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.GET_VENDOR_PHONE_OTP_RESET, clear_data: "" };
    }
}

const ConfirmAccountPhone = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {
        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.CONFIRM_PHONE_NUM, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.CONFIRM_PHONE_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.CONFIRM_PHONE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.CONFIRM_PHONE_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.CONFIRM_PHONE_RESET, clear_data: "" };
    }
}

const CreateAccount = (actionPayload: any, storeLogo: any) => {

    if (actionPayload !== "CLEAR") {
        
        
        // return;

        return (dispatch: Dispatch) => {
            
            let apiConsume = ApiService.request({ url: routes.LOGO_IMAGE_UPLOAD, http_method: "POST", data: storeLogo });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    
                    let mainData: any = { ...actionPayload }
                    if (response?.data) {
                        mainData.storeModel.logoUrl = response.data.fileURL
                        
                        let apiConsum2 = ApiService.request({ url: routes.REGISTER_WITH_EMAIL, http_method: "POST", data: mainData });
                        dispatch(request(apiConsum2));
                        return apiConsum2
                            .then((response2: any) => {
                                dispatch(success(response2.data))

                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                    }



                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        }

    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.CREATE_ACCOUNT_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.CREATE_ACCOUNT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.CREATE_ACCOUNT_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.CREATE_ACCOUNT_RESET, clear_data: "" };
    }
}

const ConfirmAccountEmail = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.CONFIRM_EMAIL, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.CONFIRM_EMAIL_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.CONFIRM_EMAIL_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.CONFIRM_EMAIL_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.CONFIRM_EMAIL_RESET, clear_data: "" };
    }
}

const ResendActivationEmail = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.RESEND_ACTIVATION_LINK}?email=${actionPayload}`, http_method: "GET", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.RESEND_ACTIVATION_EMAIL_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.RESEND_ACTIVATION_EMAIL_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.RESEND_ACTIVATION_EMAIL_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.RESEND_ACTIVATION_EMAIL_RESET, clear_data: "" };
    }
}

const UploadStoreLogo = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.LOGO_IMAGE_UPLOAD, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: authConstants.UPLOAD_STORE_LOGO_PENDING, params };
    }
    function success(response: any) {
        return { type: authConstants.UPLOAD_STORE_LOGO_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: authConstants.UPLOAD_STORE_LOGO_FAILURE, error };
    }
    function clear() {
        return { type: authConstants.UPLOAD_STORE_LOGO_RESET, clear_data: "" };
    }
}



const Logout = () => {
    localStorage.removeItem("mamiVendorXAuth");
    localStorage.removeItem("state");

    history.push("/login");
    return (dispatch: Dispatch) => {

        dispatch(logout());

    };

    function logout() {
        return { type: authConstants.LOGOUT };
    }

}

export const authActions = {
    Login,
    ConfirmAccountEmail,
    ResendActivationEmail,
    ForgotPassword,
    CreateAccount,
    GetStoreUrl,
    ResetPassword,
    GetOtpForVendorPhone,
    ConfirmAccountPhone,
    GetStoreCategories,
    UploadStoreLogo,
    Logout
};