import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { NavLink, useParams, Link } from "react-router-dom"

import "react-datepicker/dist/react-datepicker.css";

import { TableComponent } from "../../../shared/components/table"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { storeActions } from "../../../redux/actions/in-app"
import { AlertMsg } from "../../../shared/components/alert-msg"

import { useWindowSize } from "../../../hooks/useWindowSize";

import InvoiceIco from "../../../assets/invoice.png"

import { LoadingItem } from "../../../shared/components/loading"
import exportAsImage from "../../../shared/_helpers/exportAImage";
import "../index.scss"

const getStoreOrderDetails = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreOrderDetails(payload);
}

const changeOrderStatusAction = async ({ pageProps, payload, type, callback }: any) => {

    await pageProps.changeOrderStatus(payload, type, callback);
}

const storeSelectedStoreUrl = (urlParam: any) => {
    const index = urlParam.lastIndexOf('/');

    const slicedUrl = urlParam.slice(index + 1);

    return slicedUrl;
}

const downloadChart = (orderId: any) => {
    // setIsPrintingInvoice(true)
    let chartElem = document.getElementById("order-details");
    if (chartElem) {
        const mainChartElem: HTMLElement = chartElem!;
        // html2canvas(orderElem).then(canvas => {
        //     document.body.appendChild(canvas)
        // });
        exportAsImage(mainChartElem, `order-details-${orderId}`);
    }



}

const OrderStatusCta = ({ orderId, reportData, pageProps, ctaTxt, actionFlag }: any,) => {
    const [orderStatusData, setOrderStatus] = useState<any>();

    useEffect(() => {
        let payload = `OrderId=${orderId}`;

        if (orderStatusData) {
            changeOrderStatusAction({
                pageProps,
                payload: { orderId },
                type: orderStatusData,
                callback: () => {
                    getStoreOrderDetails({
                        pageProps,
                        payload
                    })
                }
            })
        }


    }, [orderStatusData])
    return (
        <>
            {actionFlag !== "done" &&


                <div

                    className={pageProps.changeOrderStatusRequest.is_request_processing ? "btn change-status-cta faded" : "btn change-status-cta"}
                    onClick={() => setOrderStatus(actionFlag)}
                >
                    {pageProps.changeOrderStatusRequest.is_request_processing ? "Please wait..." : ctaTxt}
                </div>


            }
            {actionFlag === "done" &&
                <button
                    className="btn order-no-action"
                >

                    Order is now picked up


                </button>
            }

            {/* {
                reportData?.orderStatus === "UnProcessed" &&
                <button
                    disabled={pageProps.changeOrderStatusRequest.is_request_processing}
                    onClick={() => setOrderStatus("is_ready")} className="btn change-status-cta">

                    {pageProps.changeOrderStatusRequest.is_request_processing ? "Please wait..." : "Ready for Pickup"}
                </button>
            }

            {
                reportData?.orderStatus === "ReadyForPickUp" &&
                <button
                    disabled={pageProps.changeOrderStatusRequest.is_request_processing}
                    onClick={() => setOrderStatus("is_picked")} className="btn change-status-cta">

                    {pageProps.changeOrderStatusRequest.is_request_processing ? "Please wait..." : "Order Picked Up"}
                </button>
            }
            {
                reportData?.orderStatus === "PickedUp" &&
                <button
                    disabled={pageProps.changeOrderStatusRequest.is_request_processing}


                    className="btn order-no-action"
                >

                    Order is now picked up


                </button>
            }
            <button> {pageProps.changeOrderStatusRequest.is_request_processing ? "Please wait..." : "Order is now picked up"}</button>
            <>{pageProps.changeOrderStatusRequest.is_request_processing}</> */}
        </>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const [startDate, onStartChange] = useState<any>();
    const [endDate, onEndChange] = useState<any>();
    const [showDeliveryModal, setShowDeliveryModal] = useState(false);

    const { orderId } = useParams();
    const screenResolution: any = useWindowSize();
    let page = 1,
        size = 200;

    useEffect(() => {
        let payload = `OrderId=${orderId}`;

        // changeOrderStatusRequest({
        //     pageProps,
        //     payload: "CLEAR"
        // })


        getStoreOrderDetails({
            pageProps,
            payload
        })



    }, [])



    const ReportTable = ({ reportData }: any) => {
        // let orderList: any[] = reportData?.results;
        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let originBase = window.location.origin !== "https://vendor.mamimarkets.com" ? "https://staging.mamimarkets.com" : "https://mamimarkets.com";
        return (
            <>
                <thead>
                    <tr>
                        {/* <th>
                                <span>S/N</span>
                            </th> */}
                        <th>
                            <span>Product</span>
                        </th>
                        <th><span>Quantity Ordered</span></th>
                        <th><span>Quantity Sold</span></th>
                        <th>Category</th>
                        <th>Order Price</th>
                        <th>Final Price</th>

                        <th> <span>View </span></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reportData.map((eachItem: any, index: any) => {
                            return (
                                <tr key={index}>
                                    {/* <td>
                                            {index + 1}
                                        </td> */}
                                    <td>
                                        <div className="product-info-items">
                                            <span className="product-img"><img src={eachItem?.productUrl[0]} alt="" /></span>
                                            <span className="name-ofprod">{eachItem.name}</span>
                                        </div>

                                    </td>

                                    <td>
                                        {eachItem.quantity}
                                    </td>
                                    <td>
                                        {eachItem.actualQuantity}
                                    </td>
                                    <td>
                                        {(eachItem.categories && eachItem.categories.length >= 1) &&
                                            eachItem.categories.map((eachCar: any, catIdx: any) => {
                                                return (
                                                    <span key={catIdx} className="each-cat-txt">{eachCar}</span>
                                                )
                                            })
                                        }
                                        {!eachItem.categories && "N/A"}
                                    </td>
                                    <td>
                                        ${eachItem.subTotal}
                                    </td>
                                    <td>
                                        ${eachItem.actualSubTotal}
                                    </td>
                                    <td>
                                        <div className="view-order">
                                            <a target="_blank" href={`${originBase}/stores/${storeSelectedStoreUrl(loggedAccountInfo?.selectedStore?.url)}/products/${eachItem?.productId}`}>View product</a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }


                </tbody>
            </>
        )




    }

    const OrderDetails = ({ reportData }: any) => {
        let ctaTxt: any = "",
            actionFlag: any = "";

        if (reportData?.orderStatus === "PickedUp") {
            ctaTxt = "Order is now picked up"
            actionFlag = "done"
        }

        if (reportData?.orderStatus === "ReadyForPickUp") {
            ctaTxt = "Order Picked Up"
            actionFlag = "is_picked"
        }

        if (reportData?.orderStatus === "UnProcessed") {
            ctaTxt = "Ready for Pickup"
            actionFlag = "is_ready"
        }


        return (
            <div className="page-content lists-wrap cards with-heading order-wrap">
                <div className="orders-header">

                    <div className="order-info-head">
                        <h3>Order #{reportData?.orderNumber}</h3>
                        <div className="order-ctas">

                            <OrderStatusCta orderId={orderId} reportData={reportData} pageProps={pageProps} ctaTxt={ctaTxt} actionFlag={actionFlag} />
                            <button className="btn download-invoice" onClick={() => downloadChart(orderId)}>
                                <img src={InvoiceIco} alt="" /> Download</button>
                        </div>
                    </div>
                    <div className="orders-txt for-cart">Here, you can see the content of the cart pertinent to your store so you can properly arrange for the items to be delivered to the customer.</div>
                </div>
                <div className="list-contanier " id="order-details">

                    <div className="list-content-wrap  products-list incart">


                        <div>

                            <div className="all-items">


                                <div className="all-items-list">
                                    {/* {screenResolution?.width >= 768 && */}
                                    <TableComponent
                                        childComponent={<ReportTable reportData={reportData?.vendorProducts} />}
                                    />
                                    {/* } */}





                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )

    }
    return (
        <div className="inapp-content">

            <div className="page-content lists-wrap with-heading">

                <div className="list-contanier order-info">

                    <div className=" order-details">
                        {pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_SUCCESS" &&

                            <OrderDetails reportData={pageProps.getStoreOrderDetailsRequest.request_data.response} />

                        }
                        {pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_PENDING" &&
                            <LoadingItem />
                        }
                        {pageProps.getStoreOrderDetailsRequest.request_status === "GET_VENDOR_ORDER_DETAILS_FAILURE" &&
                            <AlertMsg type="error" message={pageProps.getStoreOrderDetailsRequest?.request_data.error} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
const OrderDetails = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Orders Details" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    getStoreOrderDetails: storeActions.GetStoreOrderDetails,
    changeOrderStatus: storeActions.ChangeOrderStatus,
};

const mapStateToProps = (state: any) => ({
    // return {
    getStoreOrderDetailsRequest: state.allProductReducers.getStoreOrderDetailsReducer,
    changeOrderStatusRequest: state.allProductReducers.changeOrderStatusReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);