import React from 'react'
import '../../../scss/help-center.scss'


export const Tickets = [
    {
        ticketId: "#395",
        status: 'Not started',
        title: 'Order tracking',
        description: "I am writing to express my deep frustration regarding the order tracking experience on your platform. As a loyal customer, I have come across platforms that allow...",
        img: "/assets/images/monitor.jpg"
    },
    {
        ticketId: "#394",
        status: 'Ongoing',
        title: 'Product quality',
        description: "The product appears to be of inferior quality, with noticeable defects and an overall lack of durability. This not only reflects poorly on your commitment to ensuring accurate...",
    },

]
interface chatModal{
    handleChatOpen: () => void
}
export const TicketDropdown: React.FC<chatModal> = ({handleChatOpen}) => {
    return (
        <div className="dropdownContainer">
            <h4 className="title">Active tickets</h4>
            <div className="ticketContent">
                {
                    Tickets.map((ticket, index) => {
                        return (
                            <div key={index} className="card">
                                <div className="flex">
                                    <p className="id">Ticket ID {ticket.ticketId}</p>
                                    <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}}>
                                        <div className={ticket.status === 'Not started' ? "dot" : "dot2"} />
                                        <p className={ticket.status === 'Not started' ? "red" : "blue"}>
                                            {ticket.status}
                                        </p>
                                    </div>
                                </div>

                                <h6 className="name"  onClick={() => {
                                    handleChatOpen()
                                }}>{ticket.title}</h6>

                                <p className="description">{ticket.description}</p>
                                <div>
                                    {ticket.img ? <img src={ticket.img} alt='' width={280} height={200} style={{ borderRadius: '3px' }} /> : null}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div >
    )
}