import { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';


import { InAppTemplate } from "../../../shared/templates/inapp";

import "../index.scss"

import { history } from "../../../shared/_helpers/history";
import { storeActions } from "../../../redux/actions/in-app"




const addNewProduct = async ({ pageProps, payload }: any) => {

    await pageProps.createProductAction(payload);
}

const updateProduct = async ({ pageProps, payload }: any) => {

    await pageProps.updateStoreAction(payload);
}



const InAppContent = ({ pageProps }: any) => {


    useEffect(() => {

        if ((!pageProps?.createStoreProductRequest || (pageProps?.createStoreProductRequest?.request_status
            && pageProps.createStoreProductRequest.request_status !== "CREATE_PRODUCT_SUCCESS") &&
            (!pageProps?.updateStoreProductRequest || (pageProps?.updateStoreProductRequest?.request_status
                && pageProps.updateStoreProductRequest.request_status !== "UPDATE_PRODUCT_SUCCESS")))
        ) {
            history.replace("/app/my-products/new")
        }


    }, [])






    return (
        <div className="inapp-content">
            <div className="page-content form-page">

                {/* <div className="addproduct-heading">
                    <h3>Success</h3>
                    {pageProps?.createStoreProductRequest?.request_status
                        && pageProps.createStoreProductRequest.request_status === "CREATE_PRODUCT_SUCCESS" &&
                        <div className="addproduct-top">A new product has been added to your store</div>
                    }
                    {pageProps?.updateStoreProductRequest?.request_status
                        && pageProps.updateStoreProductRequest.request_status === "UPDATE_PRODUCT_SUCCESS" &&
                        <div className="addproduct-top">Your store product has been updated</div>
                    }
                </div> */}
                {pageProps?.createStoreProductRequest?.request_status
                    && pageProps.createStoreProductRequest.request_status === "CREATE_PRODUCT_SUCCESS" &&

                    <div className="product-form">
                        <div className="success-message-wrap">
                            <div className="success-animation">
                                <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                            </div>
                            <div className="success-message-txt">Product was successfully created</div>
                            <div className="product-cta">
                                <button
                                    onClick={() => {
                                        addNewProduct({
                                            pageProps,
                                            payload: "CLEAR"
                                        })
                                        history.replace("/app/my-products/new")
                                    }}
                                    className="btn">
                                    Create Another Product?
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {pageProps?.updateStoreProductRequest?.request_status
                    && pageProps.updateStoreProductRequest.request_status === "UPDATE_PRODUCT_SUCCESS" &&

                    <div className="product-form">
                        <div className="success-message-wrap">
                            <div className="success-animation">
                                <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                            </div>
                            <div className="success-message-txt">Product was successfully updated</div>
                            {/* <div className="product-cta">
                                <button
                                    onClick={() => {
                                        updateProduct({
                                            pageProps,
                                            payload: "CLEAR"
                                        })
                                        history.replace("/app/my-products/new")
                                    }}
                                    className="btn">
                                    Create Another Product?
                                </button>
                            </div> */}
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
const NewProductSuccess = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Create Product Successfullt" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}

const mapDispatchToProps = {
    createProductAction: storeActions.CreateStoreProduct,
    updateStoreAction: storeActions.UpdateStoreProduct,
};

const mapStateToProps = (state: any) => ({
    createStoreProductRequest: state.allProductReducers.createStoreProductReducer,
    updateStoreProductRequest: state.allProductReducers.updateStoreProductReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(NewProductSuccess);