import { combineReducers } from "redux";
import { authConstants } from "../action-constants/onboarding.constants";
import {
    outboundReducers,
    inAppReducers
} from "./all-reducers"

const rootReducer = (state: any, action: any) => {
    if (action.type === authConstants.LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

const onboardingOutboundReducers = combineReducers({
    loginReducer: outboundReducers.loginReducer,
    forgotPasswordReducer: outboundReducers.forgotPasswordReducer,
    creatUserReducer: outboundReducers.creatUserReducer,
    resetPasswordReducer: outboundReducers.resetPasswordReducer,
    getUrlReducer: outboundReducers.getUrlReducer,
    confirmEmailReducer: outboundReducers.confirmEmailReducer,
    resendActivationEmailReducer: outboundReducers.resendActivationEmailReducer,
    getOtpForVendorPhoneReducer: outboundReducers.getOtpForVendorPhoneReducer,
    confirmAccountPhoneReducer: outboundReducers.confirmAccountPhoneReducer,
    getStoreCatsReducer: outboundReducers.getStoreCatsReducer,
    uploadStoreLogoReducer: outboundReducers.uploadStoreLogoReducer,
});

const allProductReducers = combineReducers({
    createStoreProductReducer: inAppReducers.createStoreProductReducer,
    uploadStoreBulkProductsReducer: inAppReducers.uploadStoreBulkProductsReducer,
    getStoreDetailsReducer: inAppReducers.getStoreDetailsReducer,
    updateStoreDetailsReducer: inAppReducers.updateStoreDetailsReducer,
    updateStoreProductReducer: inAppReducers.updateStoreProductReducer,
    getProductMetaDataReducer: inAppReducers.getProductMetaDataReducer,
    getProductListReducer: inAppReducers.getProductListReducer,
    toggleProductReducer: inAppReducers.toggleProductReducer,
    getImageGalleryReducer: inAppReducers.getImageGalleryReducer,
    getAProductReducer: inAppReducers.getAProductReducer,
    updateProductImageReducer: inAppReducers.updateProductImageReducer,
    
    getStoreOrderReducer: inAppReducers.getStoreOrderReducer,
    getStoreOrderDetailsReducer: inAppReducers.getStoreOrderDetailsReducer,
    createStorePayoutReducer: inAppReducers.createStorePayoutReducer,
    getStorePayoutAccReducer: inAppReducers.getStorePayoutAccReducer,
    
    getDashbardTopDataReducer: inAppReducers.getDashbardTopDataReducer,
    getDashbardMiddleDataReducer: inAppReducers.getDashbardMiddleDataReducer,
    getDashbardBottompDataReducer: inAppReducers.getDashbardBottompDataReducer,
    
    deactivatePayoutAccountReducer: inAppReducers.deactivatePayoutAccountReducer,
    createPayoutInfoTokenReducer: inAppReducers.createPayoutInfoTokenReducer,
    getPayoutPendingLinkReducer: inAppReducers.getPayoutPendingLinkReducer,
    changeOrderStatusReducer: inAppReducers.changeOrderStatusReducer,
    getStorePaymentsHistoryReducer: inAppReducers.getStorePaymentsHistoryReducer,
    addNewStoreReducer: inAppReducers.addNewStoreReducer,
});


const appReducer = combineReducers({
    onboardingOutboundReducers,
    allProductReducers
});

export default rootReducer;