import { connect } from 'react-redux';
import { useState, useEffect } from 'react';
import { publicIp } from 'public-ip';
import { NavLink } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PaymentIcon from 'react-payment-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'

import PhoneInput from 'react-phone-input-2'

import { loadStripe } from "@stripe/stripe-js"
import { CardElement, Elements, useStripe, useElements, PaymentElement, CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from "@stripe/react-stripe-js"

import Cards from 'react-credit-cards';

import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';

import "react-credit-cards/lib/styles.scss"

import Walmart from "../../../assets/icons/walmart.svg"
import MsCard from "../../../assets/ms-card.png"
import CardCta1 from "../../../assets/card-cta.png"
import LeftArrow from "../../../assets/left-arr.png"
import Bankico from "../../../assets/bankico.png"
import CardCta2 from "../../../assets/card-cta2.png"
import DownloadIco from "../../../assets/download.png"
import WithdrawalIco from "../../../assets/icons/dashboard/withdrawal.png";

import { TableComponent } from "../../../shared/components/table"
import Modal from 'react-bootstrap/Modal'
import { storeActions, GetGeoCodeInfoWithPostalCode } from "../../../redux/actions/in-app"
import { AlertMsg } from "../../../shared/components/alert-msg"
import { LoadingItem } from "../../../shared/components/loading"

import ReactPaginate from 'react-paginate';

import { appConstants } from "../../../redux/action-constants/inapp.constants";

import { allowNumbersOnly, formatCreditCardNumber, formatCVC, formatExpirationDate, getDateFromISO } from "../../../shared/utils"

const createPayoutAccAction = async ({ pageProps, payload }: any) => {

    await pageProps.createPayoutAccountAction(payload);
}

const deactivatePayoutAcccountAction = async ({ pageProps, payload }: any) => {

    await pageProps.deactivatePayoutAccount(payload);
}



const createTokenizeData = async ({ pageProps, payload, type }: any) => {

    await pageProps.createTokenizeData(payload, type);
}

const getStorePayAccounts = async ({ pageProps, payload }: any) => {

    await pageProps.getStorePaymentAccounts(payload);
}

const getPayoutPendingLink = async ({ pageProps, payload }: any) => {

    await pageProps.getPayoutPendingLink(payload);
}

const getStorePaymentsHistory = async ({ pageProps, payload }: any) => {

    await pageProps.getStorePaymentsHistory(payload);
}

const CardPayoutForm = ({ stripe, elements, setCardTokenized }: any) => {
    const [cardHolder, setCardHolder] = useState<string>("")
    const [cardHolderError, setCardHolderError] = useState<boolean>(false)
    const [cardCardNumDone, setCardNumDone] = useState<boolean>(true)
    const [cardCardNumCheck, setCardNumCheck] = useState<string>("")
    const [cardCvvDone, setCardCvvDone] = useState<boolean>(true)
    const [cardCvvCheck, setCardCvvCheck] = useState<string>("")
    const [cardExpiryDone, setCardExpiryDone] = useState<boolean>(true)
    const [cardExpiryCheck, setCardExpiryCheck] = useState<string>("")

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return
        }
        if (cardHolder == "" || cardHolder.length < 4) {
            setCardHolderError(true)
        } else {
            setCardHolderError(false)
        }

        if (cardCardNumCheck == "") {
            setCardNumDone(false)
        }
        if (cardCvvCheck == "") {
            setCardCvvDone(false)
        }

        if (cardExpiryCheck == "") {
            setCardExpiryDone(false)
        }

        const cardNumberElement = elements.getElement(CardNumberElement)

        if (cardHolder !== "" && !cardHolderError && cardCardNumCheck !== "" && cardCardNumDone && cardCvvCheck !== "" && cardCvvDone && cardExpiryCheck !== "" && cardExpiryDone) {

            const res = await stripe.createToken(cardNumberElement, { name: cardHolder, currency:"CAD" })
            if (res?.token) {
                
                setCardTokenized(res)
            }

            
        }
    }
    return (
        <div className="form-wrap withdrawal-form">
            <div className="card_allo"></div>wed
            <div className="form-group">
                <label htmlFor="">Card number</label>
                <CardNumberElement
                    onChange={(event) => {
                        
                        if (event?.empty === false) {
                            setCardNumCheck("card")
                        } else {
                            setCardNumCheck("")
                        }
                        if (event?.complete === true) {
                            setCardNumDone(true)
                        } else {
                            setCardNumDone(false)
                        }
                    }}
                    options={{
                        showIcon: true,
                        classes: {
                            base: `card2 ${cardCardNumDone ? "" : "is-invalid-input"}`
                        }
                    }} />

            </div>
            <div className="two-sided">
                <div className="form-group">
                    <label htmlFor="">Expiration date</label>
                    <CardExpiryElement
                        onChange={(event) => {
                            
                            if (event?.empty === false) {
                                setCardExpiryCheck("exp")
                            } else {
                                setCardExpiryCheck("")
                            }
                            if (event?.complete === true) {
                                setCardExpiryDone(true)
                            } else {
                                setCardExpiryDone(false)
                            }
                        }}
                        options={{
                            classes: {
                                base: `card2 ${cardExpiryDone ? "" : "is-invalid-input"}`
                            }
                        }} />
                </div>
                <div className="form-group">
                    <label htmlFor="">CVV</label>
                    <CardCvcElement
                        onChange={(event) => {
                            
                            if (event?.empty === false) {
                                setCardCvvCheck("cvv")
                            } else {
                                setCardCvvCheck("")
                            }
                            if (event?.complete === true) {
                                setCardCvvDone(true)
                            } else {
                                setCardCvvDone(false)
                            }
                        }}
                        options={{
                            placeholder: "000",
                            classes: {
                                base: `card2 ${cardCvvDone ? "" : "is-invalid-input"}`
                            }
                        }} />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="">Cardholder name</label>
                <input
                    id="name"
                    value={cardHolder}
                    name="name"
                    type="text"
                    placeholder="Enter name on card"
                    onChange={(e: any) => {
                        setCardHolder(e.target.value)
                        if (e.target.value.trim().length >= 4) {
                            setCardHolderError(false)
                        } else {
                            setCardHolderError(true)
                        }
                    }}
                    className={`input-wrap ${cardHolderError ?
                        "is-invalid-input "
                        : cardHolder !== "" ? "valid-input" : ""}`}
                />
                {cardHolderError && <div className="form-input-error">Required</div>}
                {/* <ErrorMessage name="name" className="form-input-error" component="div" /> */}
            </div>
            <div className="payout-cta">
                <button
                    // disabled={tokenRequest?.is_request_processing}
                    onClick={handleSubmit}
                    className="btn"
                // className={tokenRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                >
                    Verify information
                    {/* {tokenRequest.is_request_processing ? "Please wait..." : "Verify information"} */}
                </button>
            </div>
        </div>
    )
}

const CardPayoutFormWrap = ({ setCardTokenized }: any) => {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardPayoutForm setCardTokenized={setCardTokenized} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    )
}

const CreatePayoutAccountModal = ({ showPayoutModal, setShowPayoutModal, pageProps, stripePromiseInfo }: any) => {
    const [payoutAccountData, setPayoutAccountData] = useState();
    const [payoutAccountCity, setPayoutAccountCity] = useState();
    const [payoutStage, setPayoutStage] = useState("acc_details");
    const [payoutType, setPayoutType] = useState<any>();


    const [newPostalCode, setNewPostalCode] = useState();
    const [postalCodeAreaData, setPostalCodeArea] = useState<any>();
    const [postalCodeAreaError, setPostalCodeAreaError] = useState<any>();

    const [extractedInfo, setExtractedInfo] = useState<any>();
    const [ownerAddress, setOwnerAddress] = useState<any>(null);
    const [ownerExtractedInfo, setOwnerExtractedInfo] = useState<any>();
    const [ownerAddressSearched, setOwnerAddressSearched] = useState<any>();
    const [storeFormInitValues, setStoreFormInitValues] = useState<any>({
        // password: "",
        // confirmPassword: "",
        // storeUrl: "",
        // storeName: "",
        // phoneNumber: "",
        // storeEmail: "",
        // ownerTitle: "",
        // storeProvince: "",
        // firstName: "",
        // lastName: "",
        // ownerPhone: "",
        // ownerCity: "",
        // ownerAddress: "",
        // ownerPostCode: "",
        // howHear: "",
        // storeCats:"",
        // storeAddress: "",
        // agreed: false
        country: "Canada",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        phone: "",
        name: "",
        postalCode: "",
        firstName: "",
        lastName: ""
    });
    // const [cardNum, setClientSecret] = useState<any>();

    const [clientSecret, setClientSecret] = useState<any>();
    const [tokenizedData, setDataToTokenize] = useState<any>();
    const [cardtokenizedData, setCardTokenized] = useState<any>();
    const [ipAddress, setIpAddress] = useState<any>();


    let tokenRequest = pageProps.createPayoutInfoTokenRequest;
    useEffect(() => {
        getMyIp()
        createTokenizeData({
            pageProps,
            payload: "CLEAR"
        })
        setStoreFormInitValues({
            country: "Canada",
            email: "",
            addressLine1: "",
            city: "",
            state: "",
            phone: "",
            name: "",
            postalCode: "",
            firstName: "",
            lastName: ""
        })
        setOwnerAddressSearched({
            label: "",
            value: "",
        })
        // let publishKey = "pk_test_51LcbNDHJH8AKEGL7VaMkQ6xHS9lBcI6QQlnpVxbTDw2AVaazrKXywMsXwoYPjrkyscQxAjPFn56eW7RN5fzC4ChK00htA72jFT"
        // let secretKey = "pi_3MX1RtHJH8AKEGL70tU50HKj_secret_Z4ymWltCDYHnNL6zp2SzydNaZ"
        // const stripePromise = loadStripe(publishKey);

        // setStripePromise(stripePromise)
        // setClientSecret(secretKey)
    }, [])

    useEffect(() => {

        if (payoutAccountData) {
            createPayoutAccAction({ pageProps, payload: payoutAccountData })
            // .then(() => {

            

            // })
        }


    }, [payoutAccountData])

    useEffect(() => {

        if (tokenizedData) {
            createPayoutAccAction({
                pageProps,
                payload: "CLEAR"
            })
            setOwnerAddressSearched({
                label: "",
                value: "",
            })
            setStoreFormInitValues({
                country: "Canada",
                email: "",
                addressLine1: "",
                city: "",
                state: "",
                phone: "",
                name: "",
                postalCode: "",
                firstName: "",
                lastName: ""
            })
            createTokenizeData({
                pageProps,
                payload: "CLEAR"
            })
            createTokenizeData({ pageProps, payload: tokenizedData, type: payoutType })
            // .then(() => {

            

            // })
        }


    }, [tokenizedData])

    useEffect(() => {
        if(cardtokenizedData){
            createPayoutAccAction({
                pageProps,
                payload: "CLEAR"
            })
        }
    }, [cardtokenizedData])

    useEffect(() => {
        if (ownerExtractedInfo) {

            // setPostalCodeAreaError("")
            let tempStoreFormInitValues = { ...storeFormInitValues }
            tempStoreFormInitValues.ownerCity = ownerExtractedInfo?.postalCodeArea
            tempStoreFormInitValues.ownerState = ownerExtractedInfo?.provinceChosenFull
            tempStoreFormInitValues.ownerPostCode = ownerExtractedInfo?.postCode
            setPostalCodeArea(ownerExtractedInfo?.postCode)
            setStoreFormInitValues(tempStoreFormInitValues)
            setOwnerAddressSearched({
                label: ownerExtractedInfo?.newAddress,
                value: ownerExtractedInfo?.newAddress,
            })
        }

    }, [ownerExtractedInfo])

    useEffect(() => {
        if (newPostalCode) {
            setPostalCodeAreaError("")
            GetGeoCodeInfoWithPostalCode(newPostalCode, extractedInfo?.topLevelArea).then((res: any) => {
                
                let tempStoreFormInitValues = { ...storeFormInitValues }
                if (res?.status === "success" && res?.data) {

                    tempStoreFormInitValues.ownerCity = res?.data
                    // setPostalCodeArea(res?.data)
                } else {
                    setPostalCodeAreaError(res?.error)
                    // tempStoreFormInitValues.ownerCity = ""
                    // setPostalCodeArea("")
                }
                setStoreFormInitValues(tempStoreFormInitValues)
            })
        }

    }, [newPostalCode])

    async function getMyIp() {

        let ipAddress = await publicIp();
        //    
        setIpAddress(ipAddress)
        // return ipAddress;
    }

    let detailsValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("Required")

    });

    let payoutInitialValues: any = {
        country: "Canada",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        phone: "",
        name: "",
        postalCode: "",
        firstName: "",
        lastName: ""
    }

    let cardPayoutInitialValues: any = {
        number: "",
        cvc: "",
        expiry: "",
        name: "",
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
    }

    let cardDetailsValidationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Required"),
        number: Yup.string()
            .required("Required"),
        cvc: Yup.string()
            .required("Required")
            .min(3, "Invalid"),
        expiry: Yup.string()
            .required("Required")
            .min(5, "Invalid"),
        addressLine1: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip: Yup.string(),

    });

    let bankPayoutInitialValues: any = {
        accountNumber: "",
        accountHolderName: "",
        currency: "CAD",
        accountHolderType: "",
        routingNumber: "",
        institutionNumber: "",
        accountype: "",
    }


    let bankDetailsValidationSchema = Yup.object().shape({
        accountNumber: Yup.string()
            .required("Required"),
        accountHolderName: Yup.string()
            .required("Required"),
        routingNumber: Yup.string()
            .required("Required"),
        accountHolderType: Yup.string()
            .required("Required"),
        accountype: Yup.string()
            .required("Required"),
        institutionNumber: Yup.string()
            .required("Required"),


    });

    let extractedData: any = {};
    const handleSelectedOwnerAddress = async (address: any) => {

        setOwnerAddress(address)


        // getLatLng

        const [place] = await geocodeByPlaceId(address?.value.place_id);
        let addressMeta = place?.address_components;



        // geocodeByAddress(address?.label)
        //     .then(results => getLatLng(results[0]))
        //     .then(({ lat, lng }: any) => {
        
        
        //         setAddressLatLng({ lat, lng })
        //     }
        //     );


        let extractedInfoFromAddress = () => {
            let streetNumber: any = '',
                streetName: any = '',
                areaName: any = '',
                townName: any = '',
                postalCodeArea: any = '',
                fullAddress: any,
                topLevelArea: any,
                provinceChosenFull: any,
                provinceChosen: any;

            for (let componentIndex = 0; componentIndex < addressMeta.length; componentIndex++) {

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "postal_code" && !extractedData?.postCode
                ) {
                    extractedData.postCode = addressMeta[componentIndex]?.long_name;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "street_number" && streetNumber === ""
                ) {

                    streetNumber = `${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "route" && streetName === ""
                ) {
                    streetName = `${streetNumber !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 1
                    && (
                        addressMeta[componentIndex]?.types.includes("neighborhood") ||
                        addressMeta[componentIndex]?.types.includes("locality")
                    )
                    && (areaName.split(",").length - 1) < 1

                    // && areaName === ""
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                
                
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5") ||
                        addressMeta[componentIndex]?.types.includes("locality") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")


                    )
                    // && postalCodeArea.length === 0
                    // && areaName === ""
                ) {
                    postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )
                    && ((postalCodeArea.split(",").length - 1) < 1)

                ) {
                    // postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name}`;
                    topLevelArea = addressMeta[componentIndex]?.long_name
                }
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )


                ) {
                    provinceChosen = addressMeta[componentIndex]?.short_name
                    provinceChosenFull = addressMeta[componentIndex]?.long_name
                }


                // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
                fullAddress = `${streetNumber} ${streetName} ${areaName}`;
                extractedData.newAddress = fullAddress;
                extractedData.postalCodeArea = postalCodeArea;
                extractedData.topLevelArea = topLevelArea;
                extractedData.provinceChosen = provinceChosen;
                extractedData.provinceChosenFull = provinceChosenFull;


                if (extractedData?.postCode && extractedData?.newAddress
                    && extractedData?.provinceChosen && extractedData?.provinceChosenFull) {
                    break;
                }

            }

            
            return extractedData
        }
        setOwnerExtractedInfo(extractedInfoFromAddress())



    }


    const CardDetailsInfo = () => {
        const stripe: any = useStripe();
        const elements: any = useElements();

        const collectCardDetails = () => {
            let cardInfo = elements.getElement(PaymentElement)

            
        }


        return (
            <>
                {/* <PaymentElement options={{ layout:"tabs", paym}} /> */}
                <PaymentElement options={{
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'],
                    layout: "tabs"
                }}
                />
                <div className="payout-cta">
                    <button
                        disabled={pageProps.createStorePayoutRequest?.is_request_processing}
                        className={pageProps.createStorePayoutRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                        onClick={() => collectCardDetails()}
                    >
                        {pageProps.createStorePayoutRequest.is_request_processing ? "Please wait..." : "Verify information"}
                    </button>
                </div>
                {/* <PaymentElement options={{fields:{billingDetails}}} /> */}
            </>
        )
    }






    return (
        <Modal className="payout_wrap" show={showPayoutModal} onHide={() => setShowPayoutModal(false)}>
            {
                (tokenRequest.request_status !== "CREATE_PAYOUT_TOKEN_SUCCESS" && !cardtokenizedData) &&
                // tokenRequest.request_status !== "CREATE_PAYOUT_TOKEN_RESET" &&
                <>
                    <Modal.Header closeButton>
                        {/* <div className="reason_heading">Reason for return </div> */}

                        {/* <div className="send-head"></div> */}
                        Add withdrawal method
                    </Modal.Header>
                    <Modal.Body>
                        <div className="heading-help">
                            To add a withdrawal method to your account, select one of the  channels below and fill in the appropriate information
                        </div>

                        <Tab.Container defaultActiveKey="bank_info">
                            <Nav className="acc_details_tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="bank_info">
                                        Bank details
                                        <span className="underline"></span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="card_info">
                                        Card details
                                        <span className="underline"></span>
                                    </Nav.Link>
                                </Nav.Item>

                            </Nav>

                            <Tab.Content className="acc_details_tabs_content">
                                <Tab.Pane eventKey="card_info">
                                    <Elements stripe={stripePromiseInfo}>
                                        <CardPayoutFormWrap setCardTokenized={setCardTokenized} />
                                    </Elements>

                                    {/* <Formik
                                        initialValues={cardPayoutInitialValues}
                                      
                                        onSubmit={(values: any, actions: any) => {

                                            let getDate = new Date();
                                            let year = getDate.getFullYear()
                                            
                                            let payload: any = {
                                                number: values.number.replace(/ /g, ''),
                                                cvc: values.cvc,
                                                expMonth: parseInt(values.expiry.split("/")[0]),
                                                expYear: parseInt(`20${values.expiry.split("/")[1]}`),
                                                name: values.name,
                                              

                                            }

                                           

                                        }}
                                    >
                                        {(props: FormikProps<any>) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                setFieldTouched,
                                                handleChange,
                                                setFieldValue,
                                            } = props;
                                            return (
                                                <Form>
                                                    <Elements stripe={stripePromiseInfo}>
                                                        <div className="form-wrap withdrawal-form">


                                                            <CardElement id="card_payment_wrap"
                                                                onChange={(event) => {
                                                                    
                                                                }}
                                                            />
                                                            <div className="form-group">
                                                                <label htmlFor="">Card number</label>
                                                                <CardNumberElement options={{
                                                                    showIcon: true,
                                                                    classes: {
                                                                        base: "card2"
                                                                    }
                                                                }} />
                                                                <Field
                                                                    id="number"
                                                                    value={values.number}
                                                                    name="number"
                                                                    type="tel"
                                                                    pattern="[\d| ]{16,22}"
                                                                    placeholder="0000 0000 0000 0000"
                                                                    // onChange={handleChange}
                                                                    onChange={(e: any) => {
                                                                        let mainValue = formatCreditCardNumber(e.target.value)
                                                                        setFieldTouched("number", true)
                                                                        setFieldValue("number", mainValue)
                                                                    }}
                                                                    className={`input-wrap ${errors.number && touched.number ?
                                                                        "is-invalid-input "
                                                                        : values.number !== "" ? "valid-input" : ""}`}
                                                                />
                                                                <ErrorMessage name="number" className="form-input-error" component="div" />
                                                            </div>
                                                            <div className="two-sided">
                                                                <div className="form-group">
                                                                    <label htmlFor="">Expiration date</label>
                                                                    <CardExpiryElement options={{
                                                                        classes: {
                                                                            base: "card2"
                                                                        }
                                                                    }} />
                                                                    <Field
                                                                        id="expiry"
                                                                        value={values.expiry}
                                                                        name="expiry"
                                                                        type="text"
                                                                        placeholder="MM/YY"
                                                                        onChange={(e: any) => {
                                                                            let mainValue = formatExpirationDate(e.target.value)
                                                                            setFieldTouched("expiry", true)
                                                                            setFieldValue("expiry", mainValue)
                                                                        }}
                                                                        className={`input-wrap ${errors.expiry && touched.expiry ?
                                                                            "is-invalid-input "
                                                                            : values.expiry !== "" ? "valid-input" : ""}`}
                                                                    />
                                                                    <ErrorMessage name="expiry" className="form-input-error" component="div" />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="">CVV</label>
                                                                    <CardCvcElement options={{
                                                                        placeholder: "000",
                                                                        classes: {
                                                                            base: "card2"
                                                                        }
                                                                    }} />
                                                                    <Field
                                                                        id="cvc"
                                                                        value={values.cvc}
                                                                        name="cvc"
                                                                        type="text"
                                                                        placeholder=""
                                                                        onChange={(e: any) => {
                                                                            let mainValue = formatCVC(e.target.value)
                                                                            setFieldTouched("cvc", true)
                                                                            setFieldValue("cvc", mainValue)
                                                                        }}
                                                                        className={`input-wrap ${errors.cvc && touched.cvc ?
                                                                            "is-invalid-input "
                                                                            : values.cvc !== "" ? "valid-input" : ""}`}
                                                                    />
                                                                    <ErrorMessage name="cvc" className="form-input-error" component="div" />
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Cardholder name</label>
                                                                <Field
                                                                    id="name"
                                                                    value={values.name}
                                                                    name="name"
                                                                    type="text"
                                                                    placeholder="Enter name on card"
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.name && touched.name ?
                                                                        "is-invalid-input "
                                                                        : values.name !== "" ? "valid-input" : ""}`}
                                                                />
                                                                <ErrorMessage name="name" className="form-input-error" component="div" />
                                                            </div>


                                                        </div>
                                                        {tokenRequest.request_status === "CREATE_PAYOUT_TOKEN_FAILURE" &&
                                                            <AlertMsg type="error" message={pageProps.createStorePayoutRequest?.request_data.error} />
                                                        }
                                                        <div className="payout-cta">
                                                        <button
                                                            disabled={tokenRequest?.is_request_processing}

                                                            className={tokenRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                                                        >
                                                            {tokenRequest.is_request_processing ? "Please wait..." : "Verify information"}
                                                        </button>
                                                    </div>
                                                        <TokenizeCardDetailsInfo />
                                                    </Elements>
                                                </Form>
                                            )
                                        }}

                                    </Formik> */}
                                    {/* <div className="card_coming_soon">
                                        Coming soon
                                    </div> */}

                                </Tab.Pane>
                                <Tab.Pane eventKey="bank_info">
                                    <Formik
                                        initialValues={bankPayoutInitialValues}
                                        validationSchema={bankDetailsValidationSchema}
                                        onSubmit={(values: any, actions: any) => {

                                            setCardTokenized("")
                                            let payload: any = {
                                                accountNumber: values.accountNumber,
                                                accountHolderName: values.accountHolderName,
                                                currency: values.currency,
                                                accountHolderType: parseInt(values.accountHolderType),
                                                routingNumber: `${values.routingNumber}-${values.institutionNumber}`,
                                                accountType: parseInt(values.accountype),


                                            }

                                            
                                            setPayoutType("bank")
                                            setDataToTokenize(payload)

                                        }}
                                    >
                                        {(props: FormikProps<any>) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                setFieldTouched,
                                                handleChange,
                                                setFieldValue,
                                            } = props;
                                            return (
                                                <Form>
                                                    <div className="form-wrap withdrawal-form">
                                                        <div className="form-group">
                                                            <label htmlFor="">Account number</label>
                                                            <Field
                                                                id="accountNumber"
                                                                value={allowNumbersOnly(values.accountNumber)}
                                                                name="accountNumber"
                                                                type="text"
                                                                // pattern="[\d| ]{16,22}"
                                                                placeholder="0000 000"
                                                                onChange={handleChange}
                                                                className={`input-wrap ${errors.accountNumber && touched.accountNumber ?
                                                                    "is-invalid-input "
                                                                    : values.accountNumber !== "" ? "valid-input" : ""}`}
                                                            />
                                                            <ErrorMessage name="accountNumber" className="form-input-error" component="div" />
                                                        </div>
                                                        <div className="two-sided">
                                                            <div className="form-group">
                                                                <label htmlFor="">Bank transit number</label>
                                                                <Field
                                                                    id="routingNumber"
                                                                    value={allowNumbersOnly(values.routingNumber)}
                                                                    name="routingNumber"
                                                                    type="text"
                                                                    placeholder="0 0 0 0 0"
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.routingNumber && touched.routingNumber ?
                                                                        "is-invalid-input "
                                                                        : values.routingNumber !== "" ? "valid-input" : ""}`}
                                                                />
                                                                <ErrorMessage name="routingNumber" className="form-input-error" component="div" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Institution number</label>
                                                                <Field
                                                                    id="institutionNumber"
                                                                    value={allowNumbersOnly(values.institutionNumber)}
                                                                    name="institutionNumber"
                                                                    type="text"
                                                                    placeholder="0 0 0"
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.institutionNumber && touched.institutionNumber ?
                                                                        "is-invalid-input "
                                                                        : values.institutionNumber !== "" ? "valid-input" : ""}`}
                                                                />
                                                                <ErrorMessage name="institutionNumber" className="form-input-error" component="div" />
                                                            </div>
                                                        </div>
                                                        <div className="two-sided">
                                                            <div className="form-group">
                                                                <label htmlFor="">Account type</label>
                                                                <select name="accountype"
                                                                    value={values.accountype} id=""
                                                                    // onChange={(e: any) => {

                                                                    //     setPayoutAccountCity(e.target.value)
                                                                    // }}
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.accountype && touched.accountype ?
                                                                        "is-invalid-input "
                                                                        : values.accountype !== "" ? "valid-input" : ""}`}

                                                                >
                                                                    <option value="">Select account type</option>
                                                                    <option value="1">Savings</option>
                                                                    <option value="2">Checking</option>
                                                                </select>
                                                                <ErrorMessage name="accountype" className="form-input-error" component="div" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Account Mode</label>
                                                                <select name="accountHolderType" value={values.accountHolderType} id=""
                                                                    // onChange={(e: any) => {

                                                                    //     setPayoutAccountCity(e.target.value)
                                                                    // }}
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.accountHolderType && touched.accountHolderType ?
                                                                        "is-invalid-input "
                                                                        : values.accountHolderType !== "" ? "valid-input" : ""}`}

                                                                >
                                                                    <option value="">Select account mode</option>
                                                                    <option value="1">Individual</option>
                                                                    <option value="2">Company</option>
                                                                </select>
                                                                <ErrorMessage name="accountHolderType" className="form-input-error" component="div" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Bank Name</label>
                                                            <Field
                                                                id="accountHolderName"
                                                                value={values.accountHolderName}
                                                                name="accountHolderName"
                                                                type="text"
                                                                placeholder="Bank Name"
                                                                onChange={handleChange}
                                                                className={`input-wrap ${errors.accountHolderName && touched.accountHolderName ?
                                                                    "is-invalid-input "
                                                                    : values.accountHolderName !== "" ? "valid-input" : ""}`}
                                                            />
                                                            <ErrorMessage name="accountHolderName" className="form-input-error" component="div" />
                                                        </div>
                                                    </div>
                                                    {tokenRequest.request_status === "CREATE_PAYOUT_TOKEN_FAILURE" &&
                                                        <AlertMsg type="error" message={pageProps.createPayoutInfoTokenRequest?.request_data.error} />
                                                    }
                                                    <div className="payout-cta">
                                                        <button
                                                            disabled={tokenRequest?.is_request_processing}

                                                            className={tokenRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                                                        >
                                                            {tokenRequest.is_request_processing ? "Please wait..." : "Verify information"}
                                                        </button>
                                                    </div>
                                                </Form>
                                            )
                                        }}

                                    </Formik>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Modal.Body>
                </>
            }

            {
                (tokenRequest.request_status === "CREATE_PAYOUT_TOKEN_SUCCESS" || cardtokenizedData) &&
                <>
                    <Modal.Header closeButton>
                        {/* <div className="reason_heading">Reason for return </div> */}

                        {/* <div className="send-head"></div> */}
                        Verify information
                    </Modal.Header>
                    <Modal.Body>
                        <div className="heading-help">
                            By confirming the accuracy of your details, you can trust that your withdrawals are safe and secure on our platform
                        </div>
                        <Formik
                            initialValues={storeFormInitValues}
                            validationSchema={detailsValidationSchema}
                            onSubmit={(values: any, actions: any) => {
                                let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                loggedAccountInfo = JSON.parse(loggedAccountInfo);

                                

                                let payload: any = {
                                    storeId: loggedAccountInfo.selectedStore.id,
                                    country: "CA",
                                    email: values.email,
                                    addressLine1: ownerAddressSearched?.label,
                                    // addressLine2: values.addressLine2,
                                    city: values.city || storeFormInitValues?.ownerCity,
                                    // city: values.city,
                                    state: values.state || storeFormInitValues?.ownerState,
                                    phone: `+${values.phone}`,
                                    name: `${values.firstName} ${values.lastName}`,
                                    postalCode: values.postalCode || storeFormInitValues?.ownerPostCode,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    externalAccountToken: tokenRequest.request_data.response || cardtokenizedData?.token?.id,
                                    ip:ipAddress
                                }

                                
                                createPayoutAccAction({ pageProps, payload })
                                // setPayoutAccountData(payload)

                            }}
                        >
                            {(props: FormikProps<any>) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    setFieldTouched,
                                    handleChange,
                                    setFieldValue,
                                } = props;
                                return (
                                    <Form>
                                        <div className="form-wrap withdrawal-form">
                                            <div className="">
                                                <div className="two-sided">
                                                    <div className="form-group">
                                                        <label htmlFor="">First Name</label>
                                                        <Field
                                                            id="firstName"
                                                            value={values.firstName}
                                                            name="firstName"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.firstName && touched.firstName ?
                                                                "is-invalid-input "
                                                                : values.firstName !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="firstName" className="form-input-error" component="div" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="">Last Name</label>
                                                        <Field
                                                            id="lastName"
                                                            value={values.lastName}
                                                            name="lastName"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.lastName && touched.lastName ?
                                                                "is-invalid-input "
                                                                : values.lastName !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="lastName" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="two-sided">

                                                    <div className="form-group">
                                                        <label htmlFor="">Phone Number</label>
                                                        {/* <Field
                                                            id="phone"
                                                            value={values.phone}
                                                            name="phone"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.phone && touched.phone ?
                                                                "is-invalid-input "
                                                                : values.phone !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="phone" className="form-input-error" component="div" /> */}
                                                        <PhoneInput
                                                            country={'ca'}
                                                            enableTerritories={false}
                                                            enableSearch={true}
                                                            disableSearchIcon={true}
                                                            inputClass={`input-wrap ${errors.phone && touched.phone ?
                                                                "is-invalid-input "
                                                                : values.phone !== "" ? "valid-input" : ""}`}
                                                            value={values.phone}
                                                            onChange={(phone: string): void => {

                                                                setFieldTouched("phone", true)
                                                                setFieldValue("phone", phone)
                                                            }}
                                                        />
                                                        <ErrorMessage name="phone" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Email</label>
                                                        <Field
                                                            id="email"
                                                            value={values.email}
                                                            name="email"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.email && touched.email ?
                                                                "is-invalid-input "
                                                                : values.email !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="email" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="form-group address-wrap">
                                                    <label htmlFor="">Home address</label>
                                                    {/* <Field
                                                            id="postalCode"
                                                            value={values.postalCode}
                                                            name="postalCode"
                                                            type="text"
                                                            placeholder="Enter your home address"
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.postalCode && touched.postalCode ?
                                                                "is-invalid-input "
                                                                : values.postalCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="postalCode" className="form-input-error" component="div" /> */}
                                                    <GooglePlacesAutocomplete
                                                        selectProps={{
                                                            value: ownerAddressSearched,
                                                            // onChange: handleSelectedOwnerAddress,
                                                            onChange: (address) => {
                                                                setFieldValue("postalCode", "")
                                                                handleSelectedOwnerAddress(address)

                                                                setFieldValue("addressLine1", "set")
                                                            },
                                                            placeholder: "Enter your home address",
                                                            openMenuOnClick: false,
                                                            classNamePrefix: "store-address-select",

                                                            noOptionsMessage: () => 'Type a valid address'
                                                        }}
                                                        debounce={500}
                                                        onLoadFailed={() => 'Unable to suggest address'}
                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                country: ['ca'],
                                                            }
                                                        }}
                                                        apiKey={appConstants.GOOGLE_MAP_KEY}
                                                    />
                                                </div>

                                                <div className="two-sided">

                                                    <div className="form-group">
                                                        <label htmlFor="">City</label>
                                                        {/* <Field
                                                    id="city"
                                                    value={values.city}
                                                    name="city"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.city && touched.city ?
                                                        "is-invalid-input "
                                                        : values.city !== "" ? "valid-input" : ""}`}
                                                /> */}
                                                        <Field
                                                            id="city"
                                                            value={values.city || storeFormInitValues?.ownerCity}
                                                            name="city"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={() => { }}
                                                            className={`input-wrap ${errors.city && touched.city ?
                                                                "is-invalid-input "
                                                                : values.city !== "" ? "valid-input" : ""}`}
                                                        />

                                                        <ErrorMessage name="city" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">State</label>
                                                        <Field
                                                            id="state"
                                                            value={values.state || storeFormInitValues?.ownerState}
                                                            name="state"
                                                            type="text"
                                                            placeholder="State"
                                                            onChange={() => { }}
                                                            className={`input-wrap ${errors.state && touched.state ?
                                                                "is-invalid-input "
                                                                : values.state !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="state" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="two-sided">
                                                    <div className="form-group">
                                                        <label htmlFor="">Postal Code</label>
                                                        <Field
                                                            id="postalCode"
                                                            value={values.postalCode || storeFormInitValues?.ownerPostCode}
                                                            name="postalCode"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e: any) => {
                                                                setFieldValue("postalCode", e.target.value.trim())
                                                                if (e.target.value.trim().length >= 5) {
                                                                    // if (postalCodeAreaData) {
                                                                    setNewPostalCode(e.target.value.trim())
                                                                    // } 
                                                                    // else {
                                                                    //     setAddressSearched({
                                                                    //         label: values.newAddress,
                                                                    //         value: values.newAddress
                                                                    //     })
                                                                    //     setIsAddressPrefil(false)
                                                                    // }
                                                                }
                                                            }}
                                                            className={`input-wrap ${errors.postalCode && touched.postalCode ?
                                                                "is-invalid-input "
                                                                : values.postalCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="postalCode" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Country</label>
                                                        <Field
                                                            id="country"
                                                            value={values.country}
                                                            name="country"
                                                            disabled
                                                            type="text"
                                                            placeholder=""
                                                            onChange={() => { }}
                                                            className={`input-wrap ${errors.postalCode && touched.postalCode ?
                                                                "is-invalid-input "
                                                                : values.postalCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="postalCode" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {pageProps.createStorePayoutRequest.request_status === "CREATE_PAYOUT_ACCOUNT_FAILURE" &&
                                            <AlertMsg type="error" message={pageProps.createStorePayoutRequest?.request_data.error} />
                                        }
                                        <div className="payout-cta sided">
                                            <button
                                                type="button"
                                                disabled={pageProps.createStorePayoutRequest?.is_request_processing}
                                                // className=""
                                                onClick={() => {
                                                    createTokenizeData({
                                                        pageProps,
                                                        payload: "CLEAR"
                                                    })
                                                    setCardTokenized("")
                                                }}
                                                className={pageProps.createStorePayoutRequest.is_request_processing ? "btn btn-disabled alt-btn" : "btn alt-btn"}
                                            >
                                                <img src={LeftArrow} alt="" /> Go Back
                                            </button>
                                            <button
                                                disabled={pageProps.createStorePayoutRequest?.is_request_processing}
                                                // className=""
                                                className={pageProps.createStorePayoutRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                                            >
                                                {pageProps.createStorePayoutRequest.is_request_processing ? "Please wait..." : "Add withdrawal method"}
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }}

                        </Formik>
                    </Modal.Body>
                </>
            }
        </Modal>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const [showInvite, setShowInviteModal] = useState(false);
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stripePromiseInfo, setStripePromise] = useState<any>();
    const [showDeactivate, setShowDeactivate] = useState<boolean>(false);
    useEffect(() => {

        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        // let payload = `Page=${page}&Size=${size}&StoreId=${loggedAccountInfo.selectedStore.id}`;
        let payload = `Page=${pageNumber}&Size=${pageSize}&StoreId=${loggedAccountInfo.selectedStore.id}`;
        getStorePayAccounts({
            pageProps,
            payload
        })
        getStorePaymentsHistory({
            pageProps,
            payload
        })
        createPayoutAccAction({
            pageProps,
            payload: "CLEAR"
        })
        deactivatePayoutAcccountAction({
            pageProps,
            payload: "CLEAR"
        })
        createTokenizeData({
            pageProps,
            payload: "CLEAR"
        })
        getPayoutPendingLink({
            pageProps,
            payload: "CLEAR"
        })


    }, [])


    useEffect(() => {
        if (pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS") {
            let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
            loggedAccountInfo = JSON.parse(loggedAccountInfo);
            // let payload = `Page=${page}&Size=${size}&StoreId=${loggedAccountInfo.selectedStore.id}`;
            let payload = `Page=${pageNumber}&Size=${pageSize}&StoreId=${loggedAccountInfo.selectedStore.id}`;
            setTimeout(() => {
                getStorePayAccounts({
                    pageProps,
                    payload
                })
            }, 2500);


        }




    }, [pageProps.deactivatePayoutAccountReducerRequest.request_status])

    useEffect(() => {

        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        // let payload = `Page=${page}&Size=${size}&StoreId=${loggedAccountInfo.selectedStore.id}`;
        let payload = `Page=${pageNumber}&Size=${pageSize}&StoreId=${loggedAccountInfo.selectedStore.id}`;

        getStorePaymentsHistory({
            pageProps,
            payload
        })



    }, [pageNumber, pageSize])




    useEffect(() => {
        if (pageProps.getStorePayoutAccRequest.request_status == "GET_STORE_PAY_ACCOUNTS_SUCCESS") {
            const stripePromiseData = loadStripe(pageProps.getStorePayoutAccRequest.request_data?.publishkey?.publishableKey);

            setStripePromise(stripePromiseData)
        }




    }, [pageProps.getStorePayoutAccRequest.request_status])


    let detailsValidationSchema = Yup.object().shape({
        staffMail: Yup.string()
            .required("Required")

    });
    let initialValues: any = {
        staffMail: "",
    }
    const ReportTable = ({ reportData }: any) => {

        if (reportData?.results) {
            return (
                <>
                    <thead>
                        <tr>
                            <th> <span>S/N</span></th>
                            <th>Transaction ID</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Amount</th>
                            {/* <th> <span>Invoice</span> </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportData?.results.map((eachData: any, index: any) => {
                                return (
                                    <tr>
                                        <td> <span>{index + 1}</span> </td>
                                        <td>{eachData?.vendorOrderId}</td>
                                        <td>{getDateFromISO({ date: eachData.createdDate }) || "N/A"}</td>
                                        <td>
                                            <div className="txt-status success">
                                                <span className="status-tick"></span>
                                                {eachData?.payoutStatus}
                                            </div>

                                        </td>

                                        <td>${eachData?.amount}</td>
                                        {/* <td>
                                            <div className="txt-status success">
                                                <span className="status-tick"></span>
                                                Successful
                                            </div>
                                        </td>
                                        <td>
                                            <div className="get-invoice">
                                                <img src={DownloadIco} alt="" /> Download
                                            </div>
                                        </td> */}
                                    </tr>
                                )
                            })
                        }

                        {/* <tr>
                            <td> <span>03</span> </td>
                            <td>B13A84A3F5E7</td>
                            <td>18/12/2022 - 7:11AM</td>

                            <td>$600.23</td>
                            <td>
                                <div className="txt-status success">
                                    <span className="status-tick"></span>
                                    Successful
                                </div>
                            </td>
                            <td>
                                <div className="get-invoice">
                                    <img src={DownloadIco} alt="" /> Download
                                </div>
                            </td>
                        </tr> */}
                    </tbody>

                </>
            )
        }
        return <></>
    }





    const InviteStaff = () => {
        return (
            <Modal className="invite_staff_wrap" show={showInvite} onHide={() => setShowInviteModal(false)}>
                <Modal.Body>
                    <div className="business-info">
                        <img src={Walmart} alt="" />
                        <div className="business-name">Walmart</div>
                        <div className="sub-info">Business Account</div>
                    </div>
                    <div className="send-head">Send Invite Link</div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={detailsValidationSchema}
                        onSubmit={(values: any, actions) => {

                        }}
                    >
                        {(props: FormikProps<any>) => {
                            const {
                                values,
                                touched,
                                errors,
                                setFieldTouched,
                                handleChange,
                                setFieldValue,
                            } = props;
                            return (
                                <Form>
                                    <div className="form-wrap">
                                        <div className="">

                                            <div className="form-group">
                                                <Field
                                                    id="staffMail"
                                                    value={values.staffMail}
                                                    name="staffMail"
                                                    type="text"
                                                    placeholder="Staff email"
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.staffMail && touched.staffMail ?
                                                        "is-invalid-input "
                                                        : values.staffMail !== "" ? "valid-input" : ""}`}
                                                />
                                                <ErrorMessage name="staffMail" className="form-input-error" component="div" />
                                            </div>




                                        </div>

                                    </div>
                                    <div className="invite-cta">
                                        <button className="btn">Send Invite</button>
                                    </div>
                                </Form>
                            )
                        }}

                    </Formik>
                </Modal.Body>
            </Modal>
        )
    }

    const handlePageClick = (event: any) => {

        setPageNumber(event?.selected + 1)

    }

    
    return (
        <div className="inapp-content">
            <div className="page-content setting-page">
                <div className="head-section">
                    {showInvite && <InviteStaff />}
                    {showPayoutModal && stripePromiseInfo &&
                        <CreatePayoutAccountModal
                            pageProps={pageProps}
                            showPayoutModal={showPayoutModal}
                            stripePromiseInfo={stripePromiseInfo}
                            setShowPayoutModal={setShowPayoutModal}
                        />
                    }
                    <div className="page-heading">
                        <h3>Account Settings</h3>
                    </div>
                    <div className="page-menus">
                        <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile">
                            Store Details
                        </NavLink>
                        {/* <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/store">
                        Store Details
                    </NavLink> */}
                        <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/staff">
                            Staff Account
                        </NavLink>
                        <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/withdrawal">
                            Withdrawal Method
                        </NavLink>
                    </div>
                </div>

                <div className="payout-list-wrap">
                    {pageProps.getStorePayoutAccRequest.request_status === "GET_STORE_PAY_ACCOUNTS_PENDING" &&
                        <LoadingItem />
                    }
                    {pageProps.getStorePayoutAccRequest.request_status === "GET_STORE_PAY_ACCOUNTS_SUCCESS" &&
                        <>
                            <div className="payout-options">
                                <div className="existing-payout-setups">
                                    {/* <div className="each-payout-setup for-card">
                                        <div className="db-card-top">
                                            <div className="card-brand"><img src={MsCard} /> </div>
                                            <div className="account-cta"><img src={CardCta1} /></div>
                                        </div>
                                        <div className="card-num">**** **** **** 2789</div>
                                        <div className="other-card-info">
                                            <div className="left">
                                                <div className="title">Name</div>
                                                <div className="info-detail">Tayo Olayinka</div>
                                            </div>
                                            <div className="right">
                                                <div className="title">EXPIRY DATE</div>
                                                <div className="info-detail">11/24</div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {
                                        pageProps.getStorePayoutAccRequest.request_data.response?.results && pageProps.getStorePayoutAccRequest.request_data.response?.results.length >= 1 &&
                                        <>
                                            {
                                                pageProps.getStorePayoutAccRequest.request_data.response?.results.map((eachAccount: any, index: any) => {
                                                    if (eachAccount?.hasExternalAccount === false || eachAccount?.accountStatus === "required update" || eachAccount?.accountStatus === "InActive") {
                                                        return (
                                                            <div className="each-payout-setup for-bank" key={index}>
                                                                <div className="left">
                                                                    <div className="left-top">
                                                                        {/* <img src={Bankico} alt="" /> */}
                                                                        <div className="top-txt">PENDING ACCOUNT</div>
                                                                    </div>
                                                                    <div className="left-middle">
                                                                        ********
                                                                    </div>
                                                                    <div className="left-bottom">
                                                                        <div className="title-txt">Pending action</div>
                                                                        {!pageProps.getPayoutPendingLinkRequest?.is_request_processing &&
                                                                            <div className="pending-cta" onClick={() => {
                                                                                let payload = {
                                                                                    accountId: eachAccount?.id
                                                                                }
                                                                                getPayoutPendingLink({
                                                                                    pageProps,
                                                                                    payload
                                                                                })
                                                                            }}>
                                                                                Proceed now
                                                                            </div>
                                                                        }


                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <div className="account-cta"><img src={CardCta2} /></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    if (eachAccount?.accountStatus === "Created") {
                                                        return (
                                                            <div className="each-payout-setup for-bank" key={index}>
                                                                <div className="left">
                                                                    <div className="left-top">
                                                                        {/* <img src={Bankico} alt="" /> */}
                                                                        <div className="top-txt">UNDER REVIEW</div>
                                                                    </div>
                                                                    <div className="left-middle">
                                                                        ********
                                                                    </div>
                                                                    <div className="left-bottom">
                                                                        {/* <div className="title-txt">Pending action</div> */}



                                                                    </div>
                                                                </div>
                                                                <div className="right">
                                                                    <div className="account-cta"><img src={CardCta2} /></div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    if (eachAccount?.accountStatus === "Active") {
                                                        return (
                                                            <>
                                                                {
                                                                    eachAccount?.bankInfo &&
                                                                    <div className="each-payout-setup for-bank">
                                                                        {showDeactivate &&
                                                                            <div className="remove_account">
                                                                                <div className="remove_wrap">
                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status !== "DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS" &&
                                                                                        <div className="confirm_remove_txt">
                                                                                            Please confirm you want to disable <span>**** **** **** {eachAccount?.cardInfo?.last4} </span>as withdrawal account
                                                                                        </div>
                                                                                    }
                                                                                    {(pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_RESET" ||
                                                                                        pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_FAILURE") &&
                                                                                        <div className="remove_ctas">
                                                                                            <button className='btn back' onClick={() => setShowDeactivate(false)}>Cancel</button>
                                                                                            <button className='btn confirm'
                                                                                                onClick={() => {
                                                                                                    let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                                                                                    loggedAccountInfo = JSON.parse(loggedAccountInfo);
                                                                                                    let payload = {
                                                                                                        accountId: eachAccount?.number,
                                                                                                        storeId: loggedAccountInfo.selectedStore.id
                                                                                                    }
                                                                                                    deactivatePayoutAcccountAction({ pageProps, payload })
                                                                                                }}
                                                                                            >Disable</button>
                                                                                        </div>
                                                                                    }
                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_PENDING" &&
                                                                                        <div className="confirm_remove_txt">
                                                                                            Deactivating ...
                                                                                        </div>
                                                                                    }
                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS" &&
                                                                                        <AlertMsg type="success" message={`**** **** **** ${eachAccount?.cardInfo?.last4} successfully deleted`} />
                                                                                    }

                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_FAILURE" &&
                                                                                        <AlertMsg type="error" message={pageProps.deactivatePayoutAccountReducerRequest?.request_data.error} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <div className="left">

                                                                            <div className="left-top">
                                                                                <img src={Bankico} alt="" />
                                                                                <div className="top-txt">BANK WITHDRAWAL</div>
                                                                            </div>


                                                                            <div className="left-middle">
                                                                                ****{eachAccount?.bankInfo?.last4}
                                                                            </div>
                                                                            <div className="left-bottom">
                                                                                <div className="title-txt">Routing number</div>
                                                                                <div className="type-txt">{eachAccount?.bankInfo?.routingNumber}</div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="right">
                                                                            <div className="account-cta"><img src={CardCta2} /></div>
                                                                        </div>

                                                                    </div>
                                                                }
                                                                {
                                                                    eachAccount?.cardInfo &&
                                                                    <div className="each-payout-setup for-card">
                                                                        {showDeactivate &&
                                                                            <div className="remove_account">
                                                                                <div className="remove_wrap">
                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status !== "DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS" &&
                                                                                        <div className="confirm_remove_txt">
                                                                                            Please confirm you want to disable <span>**** **** **** {eachAccount?.cardInfo?.last4} </span>as withdrawal account
                                                                                        </div>
                                                                                    }
                                                                                    {(pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_RESET" ||
                                                                                        pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_FAILURE") &&
                                                                                        <div className="remove_ctas">
                                                                                            <button className='btn back' onClick={() => setShowDeactivate(false)}>Cancel</button>
                                                                                            <button className='btn confirm'
                                                                                                onClick={() => {
                                                                                                    let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                                                                                    loggedAccountInfo = JSON.parse(loggedAccountInfo);
                                                                                                    let payload = {
                                                                                                        accountId: eachAccount?.number,
                                                                                                        storeId: loggedAccountInfo.selectedStore.id
                                                                                                    }
                                                                                                    deactivatePayoutAcccountAction({ pageProps, payload })
                                                                                                }}
                                                                                            >Disable</button>
                                                                                        </div>
                                                                                    }
                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_PENDING" &&
                                                                                        <div className="confirm_remove_txt">
                                                                                            Deactivating ...
                                                                                        </div>
                                                                                    }
                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS" &&
                                                                                        <AlertMsg type="success" message={`**** **** **** ${eachAccount?.cardInfo?.last4} successfully deleted`} />
                                                                                    }

                                                                                    {pageProps.deactivatePayoutAccountReducerRequest.request_status === "DEACTIVATE_PAYOUT_ACCOUNT_FAILURE" &&
                                                                                        <AlertMsg type="error" message={pageProps.deactivatePayoutAccountReducerRequest?.request_data.error} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        <div className="db-card-top">
                                                                            <div className="card-brand">
                                                                                {/* <img src={MsCard} /> */}
                                                                                {eachAccount?.cardInfo?.brand &&
                                                                                    <PaymentIcon
                                                                                        id={eachAccount?.cardInfo?.brand.toLowerCase()}
                                                                                        style={{ width: 40 }}
                                                                                        className="payment-icon"
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div className="account-cta">
                                                                                <Dropdown align="end" >
                                                                                    <Dropdown.Toggle id="dropdown-basic" className="">
                                                                                        <img src={CardCta1} />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item onClick={() => setShowDeactivate(true)} className=""> Disable account</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>

                                                                            </div>
                                                                        </div>
                                                                        <div className="card-num">**** **** **** {eachAccount?.cardInfo?.last4}</div>
                                                                        <div className="other-card-info">
                                                                            <div className="left">
                                                                                <div className="title">Name</div>
                                                                                <div className="info-detail">{eachAccount?.cardInfo?.name}</div>
                                                                            </div>
                                                                            <div className="right">
                                                                                <div className="title">EXPIRY DATE</div>
                                                                                <div className="info-detail">{eachAccount?.cardInfo?.expMonth}/{eachAccount?.cardInfo?.expYear}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </>
                                                        )
                                                    }
                                                    return <div key={index}></div>;
                                                })
                                            }

                                        </>
                                    }
                                    {/* <div className="each-payout-setup for-bank">
                                        <div className="left">
                                            <div className="left-top">
                                                <img src={Bankico} alt="" />
                                                <div className="top-txt">BANK WITHDRAWAL</div>
                                            </div>
                                            <div className="left-middle">
                                                532****290
                                            </div>
                                            <div className="left-bottom">
                                                <div className="title-txt">Transit number</div>
                                                <div className="type-txt">004</div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="account-cta"><img src={CardCta2} /></div>
                                        </div>
                                    </div> */}


                                    <div className="each-payout-setup add-withwrawal-cta" onClick={() => setShowPayoutModal(true)}>
                                        <div className="inner">
                                            <img src={WithdrawalIco} />
                                            <div className="withdrawal-txt">Add a withdrawal method</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pageProps.getPayoutPendingLinkRequest.request_status === "GET_PAYOUT_PENDING_LINK_FAILURE" &&
                                <div className="pending-cta with-error">
                                    {pageProps.getPayoutPendingLinkRequest?.request_data.error}
                                </div>
                            }
                            {pageProps.getPayoutPendingLinkRequest?.is_request_processing &&
                                <div className="pending-cta ongoing">
                                    Please wait ...
                                </div>
                            }
                            {/* <div className="light-heading">
                                
                                <div></div>
                                <button className="btn other-btn" onClick={() => setShowPayoutModal(true)}>
                                    +<span>Add Payout Account</span>
                                </button>
                            </div> */}
                            <div className="payout-table">
                                {pageProps.getStorePaymentsHistoryRequest.request_status === "GET_STORE_PAYMENT_HISTORY_PENDING" &&
                                    <LoadingItem />
                                }
                                {pageProps.getStorePaymentsHistoryRequest.request_status === "GET_STORE_PAYMENT_HISTORY_SUCCESS" &&
                                    <>
                                        <TableComponent
                                            childComponent={
                                                <ReportTable
                                                    // reportData=""
                                                    reportData={pageProps.getStorePaymentsHistoryRequest.request_data.response}
                                                />
                                            }
                                        />

                                        {(pageProps?.getStorePaymentsHistoryRequest?.request_data?.response?.totalNumberOfRecords > pageSize) &&
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                // initialPage={1}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={4}
                                                forcePage={pageNumber - 1}
                                                // onClick={handleItemsClick}
                                                className="pagination_items"
                                                pageClassName="page_num"
                                                pageLinkClassName="page_link"
                                                activeClassName="active_page_link"
                                                previousClassName="previous_page_link"
                                                nextClassName="next_page_link"
                                                pageCount={pageProps?.getStorePaymentsHistoryRequest?.request_data?.response?.pageNumber}
                                                previousLabel="<"
                                                // @ts-ignore
                                                renderOnZeroPageCount={null}
                                            />
                                        }
                                    </>
                                }
                                {pageProps.getStorePaymentsHistoryRequest.request_status === "GET_STORE_PAYMENT_HISTORY_FAILURE" &&
                                    <AlertMsg type="error" message={pageProps.getStorePaymentsHistoryRequest?.request_data.error} />
                                }
                            </div>
                        </>
                    }


                    {pageProps.getStorePayoutAccRequest.request_status === "GET_STORE_PAY_ACCOUNTS_FAILURE" &&
                        <AlertMsg type="error" message={pageProps.getStorePayoutAccRequest?.request_data.error} />
                    }

                </div>
            </div>


        </div>
    )
}

const Withdrawal = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Withdrawal Settings" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    createPayoutAccountAction: storeActions.CreatePayoutAccount,
    getStorePaymentAccounts: storeActions.GetStorePaymentAccount,
    deactivatePayoutAccount: storeActions.DeactivatePayoutAccount,
    createTokenizeData: storeActions.CreateTokenizeData,
    getPayoutPendingLink: storeActions.GetPayoutPendingLink,
    getStorePaymentsHistory: storeActions.GetStorePaymentsHistory,
};

const mapStateToProps = (state: any) => ({
    // return {
    createStorePayoutRequest: state.allProductReducers.createStorePayoutReducer,
    getStorePayoutAccRequest: state.allProductReducers.getStorePayoutAccReducer,
    createPayoutInfoTokenRequest: state.allProductReducers.createPayoutInfoTokenReducer,
    deactivatePayoutAccountReducerRequest: state.allProductReducers.deactivatePayoutAccountReducer,
    getPayoutPendingLinkRequest: state.allProductReducers.getPayoutPendingLinkReducer,
    getStorePaymentsHistoryRequest: state.allProductReducers.getStorePaymentsHistoryReducer,

    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);