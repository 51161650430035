import { connect } from 'react-redux';
import { InAppTemplate } from "../../../shared/templates/inapp";
import Info from "../../../assets/icons/info.svg";
import { HelpTab } from '../help-center';

import "../index.scss"




const InAppContent = ({ pageProps }: any) => {
    return (
        <div className="inapp-content">
            {/* <div className="page-heading">Dashboard</div> */}
            <div className="page-content dashboard">
                <div className="top-section">
                    <div className="each-topsection cards no-stores-wrap">
                        <div className="section-wrap">
                            <div className="section-title">Your store is under review!</div>
                            <div className="txt-content">
                                <p>Thank you for submitting your store for signing up on MamiMarkets.Com. Our team is currently reviewing your application and will get back to you as soon as possible.</p>
                                <p>During the review process, your store will not be visible to customers. This means that you won't be able to make any sales until your store is approved. We apologize for any inconvenience this may cause.</p>
                                <p>In the meantime, you can still access your dashboard and make any necessary updates to your store. If our team requires any additional information from you, we'll reach out to you via email. Once your store is approved, you'll receive an email with instructions on how to get started.</p>
                                <p>Thank you for your patience during this process. If you have any questions or concerns, please don't hesitate to contact our support team.</p>




                            </div>

                            <div className="no-stores-cta">
                                <button className="btn"onClick={()=>window.location.href = 'mailto:hello@mamimarkets.com'} >Contact MamiMarkets</button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

const NoStoresFound = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="No Stores Found" noStores={true} childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {


    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(NoStoresFound);