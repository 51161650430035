
import { appConstants } from "../../action-constants/inapp.constants"
// import {ReducerParams} from "../../../types"


let userLogged = localStorage.getItem('mamiVXAuth') || "{}";

let user = JSON.parse(userLogged);
const initialState = Object.keys(user).length >= 1 ? { loggedIn: true, user } : { loggedIn: false };

const initialGalleryState :any = {
    // isEndOfCatalogue: false,
    results: [],
    // nextItemsBatch: [],
    // currentPage: 1
  }




export const createStoreProductReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.CREATE_PRODUCT_PENDING:
            return {
                request_status: appConstants.CREATE_PRODUCT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.CREATE_PRODUCT_SUCCESS:
            return {
                request_status: appConstants.CREATE_PRODUCT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CREATE_PRODUCT_FAILURE:
            return {
                request_status: appConstants.CREATE_PRODUCT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CREATE_PRODUCT_RESET:
            return {
                request_status: appConstants.CREATE_PRODUCT_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const uploadStoreBulkProductsReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.UPLOAD_MULTIPLE_PENDING:
            return {
                request_status: appConstants.UPLOAD_MULTIPLE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.UPLOAD_MULTIPLE_SUCCESS:
            return {
                request_status: appConstants.UPLOAD_MULTIPLE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPLOAD_MULTIPLE_FAILURE:
            return {
                request_status: appConstants.UPLOAD_MULTIPLE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPLOAD_MULTIPLE_RESET:
            return {
                request_status: appConstants.UPLOAD_MULTIPLE_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const updateStoreProductReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.UPDATE_PRODUCT_PENDING:
            return {
                request_status: appConstants.UPDATE_PRODUCT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.UPDATE_PRODUCT_SUCCESS:
            return {
                request_status: appConstants.UPDATE_PRODUCT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPDATE_PRODUCT_FAILURE:
            return {
                request_status: appConstants.UPDATE_PRODUCT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPDATE_PRODUCT_RESET:
            return {
                request_status: appConstants.UPDATE_PRODUCT_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const updateProductImageReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.UPLOAD_PRODUCT_PICTURE_PENDING:
            return {
                request_status: appConstants.UPLOAD_PRODUCT_PICTURE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.UPLOAD_PRODUCT_PICTURE_SUCCESS:
            return {
                request_status: appConstants.UPLOAD_PRODUCT_PICTURE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPLOAD_PRODUCT_PICTURE_FAILURE:
            return {
                request_status: appConstants.UPLOAD_PRODUCT_PICTURE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPLOAD_PRODUCT_PICTURE_RESET:
            return {
                request_status: appConstants.UPLOAD_PRODUCT_PICTURE_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getProductListReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_PRODUCTS_PENDING:
            return {
                request_status: appConstants.GET_PRODUCTS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_PRODUCTS_SUCCESS:
            return {
                request_status: appConstants.GET_PRODUCTS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_PRODUCTS_FAILURE:
            return {
                request_status: appConstants.GET_PRODUCTS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_PRODUCTS_RESET:
            return {
                request_status: appConstants.GET_PRODUCTS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const toggleProductReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.TOGGLE_A_PRODUCT_PENDING:
            return {
                request_status: appConstants.TOGGLE_A_PRODUCT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.TOGGLE_A_PRODUCT_SUCCESS:
            return {
                request_status: appConstants.TOGGLE_A_PRODUCT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.TOGGLE_A_PRODUCT_FAILURE:
            return {
                request_status: appConstants.TOGGLE_A_PRODUCT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.TOGGLE_A_PRODUCT_RESET:
            return {
                request_status: appConstants.TOGGLE_A_PRODUCT_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getAProductReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_A_PRODUCT_PENDING:
            return {
                request_status: appConstants.GET_A_PRODUCT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_A_PRODUCT_SUCCESS:
            return {
                request_status: appConstants.GET_A_PRODUCT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_A_PRODUCT_FAILURE:
            return {
                request_status: appConstants.GET_A_PRODUCT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_A_PRODUCT_RESET:
            return {
                request_status: appConstants.GET_A_PRODUCT_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getProductMetaDataReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_PRODUCT_META_PENDING:
            return {
                request_status: appConstants.GET_PRODUCT_META_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_PRODUCT_META_SUCCESS:
            return {
                request_status: appConstants.GET_PRODUCT_META_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_PRODUCT_META_FAILURE:
            return {
                request_status: appConstants.GET_PRODUCT_META_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_PRODUCT_META_RESET:
            return {
                request_status: appConstants.GET_PRODUCT_META_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getImageGalleryReducer = (state:any = initialGalleryState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_IMAGE_GALLERY_PENDING:
            // return {
            //     request_status: appConstants.GET_IMAGE_GALLERY_PENDING,
            //     is_request_processing: true,
            //     request_data: action
            // };
            return Object.assign({}, state, {
                request_status: appConstants.GET_IMAGE_GALLERY_PENDING,
                is_request_processing: true,
                results: [],
                request_data: action,
                galleryData:[]
              })
        case appConstants.GET_IMAGE_GALLERY_SUCCESS:
            
            
            // initialGalleryState.results.concat(action?.response?.results)

            
            // if(action.response.pageNumber===action.response.totalNumberOfPages){
            //     return {
            //         request_status: appConstants.GET_IMAGE_GALLERY_SUCCESS,
            //         is_request_processing: false,
            //         request_data: action,
            //         galleryData :state,
            //         endOfGallery: true
            //     };
            // }
            // return Object.assign({}, state, {
            //     results: state.results.concat(action.response.results),
            //     request_status: appConstants.GET_IMAGE_GALLERY_SUCCESS,
            //     is_request_processing: false,
            //     request_data: action,
            //   })

            return {
                ...state,
                request_status: appConstants.GET_IMAGE_GALLERY_SUCCESS,
                is_request_processing: false,
                request_data: action,
                galleryData :action.results,
                // endOfGallery:false
            };
        case appConstants.GET_IMAGE_GALLERY_NEWBATCH:
            
            
            // initialGalleryState.results.concat(action?.response?.results)
            
            return {
                ...state,
                request_status: appConstants.GET_IMAGE_GALLERY_NEWBATCH,
                is_request_processing: false,
                request_data: action,
                galleryData :[...state.galleryData,...action.results],
            };
        case appConstants.GET_IMAGE_GALLERY_FAILURE:
            return {
                request_status: appConstants.GET_IMAGE_GALLERY_FAILURE,
                is_request_processing: false,
                galleryData :action.results,
                request_data: action
            };
        case appConstants.GET_IMAGE_GALLERY_RESET:
            return {
                request_status: appConstants.GET_IMAGE_GALLERY_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getStoreOrderReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_VENDOR_ORDERS_PENDING:
            return {
                request_status: appConstants.GET_VENDOR_ORDERS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_VENDOR_ORDERS_SUCCESS:
            return {
                request_status: appConstants.GET_VENDOR_ORDERS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_VENDOR_ORDERS_FAILURE:
            return {
                request_status: appConstants.GET_VENDOR_ORDERS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_VENDOR_ORDERS_RESET:
            return {
                request_status: appConstants.GET_VENDOR_ORDERS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getStoreOrderDetailsReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_VENDOR_ORDER_DETAILS_PENDING:
            return {
                request_status: appConstants.GET_VENDOR_ORDER_DETAILS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_VENDOR_ORDER_DETAILS_SUCCESS:
            return {
                request_status: appConstants.GET_VENDOR_ORDER_DETAILS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_VENDOR_ORDER_DETAILS_FAILURE:
            return {
                request_status: appConstants.GET_VENDOR_ORDER_DETAILS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_VENDOR_ORDER_DETAILS_RESET:
            return {
                request_status: appConstants.GET_VENDOR_ORDER_DETAILS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const createStorePayoutReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.CREATE_PAYOUT_ACCOUNT_PENDING:
            return {
                request_status: appConstants.CREATE_PAYOUT_ACCOUNT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.CREATE_PAYOUT_ACCOUNT_SUCCESS:
            return {
                request_status: appConstants.CREATE_PAYOUT_ACCOUNT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CREATE_PAYOUT_ACCOUNT_FAILURE:
            return {
                request_status: appConstants.CREATE_PAYOUT_ACCOUNT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CREATE_PAYOUT_ACCOUNT_RESET:
            return {
                request_status: appConstants.CREATE_PAYOUT_ACCOUNT_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getStorePayoutAccReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_STORE_PAY_ACCOUNTS_PENDING:
            return {
                request_status: appConstants.GET_STORE_PAY_ACCOUNTS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_STORE_PAY_ACCOUNTS_SUCCESS:
            return {
                request_status: appConstants.GET_STORE_PAY_ACCOUNTS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_STORE_PAY_ACCOUNTS_FAILURE:
            return {
                request_status: appConstants.GET_STORE_PAY_ACCOUNTS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_STORE_PAY_ACCOUNTS_RESET:
            return {
                request_status: appConstants.GET_STORE_PAY_ACCOUNTS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getStoreDetailsReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_STORE_DETAILS_PENDING:
            return {
                request_status: appConstants.GET_STORE_DETAILS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_STORE_DETAILS_SUCCESS:
            return {
                request_status: appConstants.GET_STORE_DETAILS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_STORE_DETAILS_FAILURE:
            return {
                request_status: appConstants.GET_STORE_DETAILS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_STORE_DETAILS_RESET:
            return {
                request_status: appConstants.GET_STORE_DETAILS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const updateStoreDetailsReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.UPDATE_STORE_DETAILS_PENDING:
            return {
                request_status: appConstants.UPDATE_STORE_DETAILS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.UPDATE_STORE_DETAILS_SUCCESS:
            return {
                request_status: appConstants.UPDATE_STORE_DETAILS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPDATE_STORE_DETAILS_FAILURE:
            return {
                request_status: appConstants.UPDATE_STORE_DETAILS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.UPDATE_STORE_DETAILS_RESET:
            return {
                request_status: appConstants.UPDATE_STORE_DETAILS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getDashbardTopDataReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_DASHBOARD_TOP_DATA_PENDING:
            return {
                request_status: appConstants.GET_DASHBOARD_TOP_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_TOP_DATA_SUCCESS:
            return {
                request_status: appConstants.GET_DASHBOARD_TOP_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_TOP_DATA_FAILURE:
            return {
                request_status: appConstants.GET_DASHBOARD_TOP_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_TOP_DATA_RESET:
            return {
                request_status: appConstants.GET_DASHBOARD_TOP_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getDashbardMiddleDataReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_DASHBOARD_MIDDLE_DATA_PENDING:
            return {
                request_status: appConstants.GET_DASHBOARD_MIDDLE_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_MIDDLE_DATA_SUCCESS:
            return {
                request_status: appConstants.GET_DASHBOARD_MIDDLE_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_MIDDLE_DATA_FAILURE:
            return {
                request_status: appConstants.GET_DASHBOARD_MIDDLE_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_MIDDLE_DATA_RESET:
            return {
                request_status: appConstants.GET_DASHBOARD_MIDDLE_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getDashbardBottompDataReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_DASHBOARD_BOTTOM_DATA_PENDING:
            return {
                request_status: appConstants.GET_DASHBOARD_BOTTOM_DATA_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_BOTTOM_DATA_SUCCESS:
            return {
                request_status: appConstants.GET_DASHBOARD_BOTTOM_DATA_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_BOTTOM_DATA_FAILURE:
            return {
                request_status: appConstants.GET_DASHBOARD_BOTTOM_DATA_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_DASHBOARD_BOTTOM_DATA_RESET:
            return {
                request_status: appConstants.GET_DASHBOARD_BOTTOM_DATA_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const createPayoutInfoTokenReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.CREATE_PAYOUT_TOKEN_PENDING:
            return {
                request_status: appConstants.CREATE_PAYOUT_TOKEN_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.CREATE_PAYOUT_TOKEN_SUCCESS:
            return {
                request_status: appConstants.CREATE_PAYOUT_TOKEN_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CREATE_PAYOUT_TOKEN_FAILURE:
            return {
                request_status: appConstants.CREATE_PAYOUT_TOKEN_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CREATE_PAYOUT_TOKEN_RESET:
            return {
                request_status: appConstants.CREATE_PAYOUT_TOKEN_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const deactivatePayoutAccountReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.DEACTIVATE_PAYOUT_ACCOUNT_PENDING:
            return {
                request_status: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS:
            return {
                request_status: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.DEACTIVATE_PAYOUT_ACCOUNT_FAILURE:
            return {
                request_status: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.DEACTIVATE_PAYOUT_ACCOUNT_RESET:
            return {
                request_status: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getPayoutPendingLinkReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_PAYOUT_PENDING_LINK_PENDING:
            return {
                request_status: appConstants.GET_PAYOUT_PENDING_LINK_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_PAYOUT_PENDING_LINK_SUCCESS:
            return {
                request_status: appConstants.GET_PAYOUT_PENDING_LINK_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_PAYOUT_PENDING_LINK_FAILURE:
            return {
                request_status: appConstants.GET_PAYOUT_PENDING_LINK_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_PAYOUT_PENDING_LINK_RESET:
            return {
                request_status: appConstants.GET_PAYOUT_PENDING_LINK_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const getStorePaymentsHistoryReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.GET_STORE_PAYMENT_HISTORY_PENDING:
            return {
                request_status: appConstants.GET_STORE_PAYMENT_HISTORY_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS:
            return {
                request_status: appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE:
            return {
                request_status: appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.GET_STORE_PAYMENT_HISTORY_RESET:
            return {
                request_status: appConstants.GET_STORE_PAYMENT_HISTORY_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const changeOrderStatusReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.CHANGE_ORDER_STATUS_PENDING:
            return {
                request_status: appConstants.CHANGE_ORDER_STATUS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.CHANGE_ORDER_STATUS_SUCCESS:
            return {
                request_status: appConstants.CHANGE_ORDER_STATUS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CHANGE_ORDER_STATUS_FAILURE:
            return {
                request_status: appConstants.CHANGE_ORDER_STATUS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.CHANGE_ORDER_STATUS_RESET:
            return {
                request_status: appConstants.CHANGE_ORDER_STATUS_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}

export const addNewStoreReducer = (state = initialState, action: any) => {
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case appConstants.ADD_A_NEW_STORE_PENDING:
            return {
                request_status: appConstants.ADD_A_NEW_STORE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case appConstants.ADD_A_NEW_STORE_SUCCESS:
            return {
                request_status: appConstants.ADD_A_NEW_STORE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.ADD_A_NEW_STORE_FAILURE:
            return {
                request_status: appConstants.ADD_A_NEW_STORE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case appConstants.ADD_A_NEW_STORE_RESET:
            return {
                request_status: appConstants.ADD_A_NEW_STORE_RESET,
                is_request_processing: false,
                request_data: {},
            };


        default:
            return { ...state }
    }
}