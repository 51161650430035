
import {authConstants} from "../../action-constants/onboarding.constants"
// import {ReducerParams} from "../../../types"


let userLogged = localStorage.getItem('mamiVXAuth') || "{}";

let user = JSON.parse(userLogged);
const initialState = Object.keys(user).length>=1 ? { loggedIn: true, user } : {loggedIn: false};


export const loginReducer = (state = initialState, action:any)=>{
    // export const loginReducer = ({state = initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.LOGIN_USER_PENDING:
            return {
                request_status: authConstants.LOGIN_USER_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.LOGIN_USER_SUCCESS:
            return {
                request_status: authConstants.LOGIN_USER_SUCCESS,
                loggedIn: true,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.LOGIN_USER_FAILURE:
            return {
                request_status: authConstants.LOGIN_USER_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.LOGIN_USER_RESET:
            return {
                request_status: authConstants.LOGIN_USER_RESET,
                is_request_processing: false,
                request_data: {},
            };
        
        case authConstants.LOGOUT:
            return {
                // test:"dsdsds",
                // request_data: action,
                ...state
              };
            
        case authConstants.LOGOUT_USER_SUCCESS:
                return {
                  ...state
                };

        default:
            return { ...state }
    }
}

export const forgotPasswordReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.FORGOT_PASSWORD_PENDING:
            return {
                request_status: authConstants.FORGOT_PASSWORD_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                request_status: authConstants.FORGOT_PASSWORD_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.FORGOT_PASSWORD_FAILURE:
            return {
                request_status: authConstants.FORGOT_PASSWORD_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.FORGOT_PASSWORD_RESET:
            return {
                request_status: authConstants.FORGOT_PASSWORD_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}


export const resetPasswordReducer = (state=[], action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.COMPLETE_PASSWORD_RESET_PENDING:
            return {
                request_status: authConstants.COMPLETE_PASSWORD_RESET_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.COMPLETE_PASSWORD_RESET_SUCCESS:
            return {
                request_status: authConstants.COMPLETE_PASSWORD_RESET_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.COMPLETE_PASSWORD_RESET_FAILURE:
            return {
                request_status: authConstants.COMPLETE_PASSWORD_RESET_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.COMPLETE_PASSWORD_RESET_RESET:
            return {
                request_status: authConstants.COMPLETE_PASSWORD_RESET_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const creatUserReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.CREATE_ACCOUNT_PENDING:
            return {
                request_status: authConstants.CREATE_ACCOUNT_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.CREATE_ACCOUNT_SUCCESS:
            return {
                request_status: authConstants.CREATE_ACCOUNT_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.CREATE_ACCOUNT_FAILURE:
            return {
                request_status: authConstants.CREATE_ACCOUNT_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.CREATE_ACCOUNT_RESET:
            return {
                request_status: authConstants.CREATE_ACCOUNT_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const getUrlReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.GET_URL_OPTIONS_PENDING:
            return {
                request_status: authConstants.GET_URL_OPTIONS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.GET_URL_OPTIONS_SUCCESS:
            return {
                request_status: authConstants.GET_URL_OPTIONS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.GET_URL_OPTIONS_FAILURE:
            return {
                request_status: authConstants.GET_URL_OPTIONS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.GET_URL_OPTIONS_RESET:
            return {
                request_status: authConstants.GET_URL_OPTIONS_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const confirmEmailReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.CONFIRM_EMAIL_PENDING:
            return {
                request_status: authConstants.CONFIRM_EMAIL_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.CONFIRM_EMAIL_SUCCESS:
            return {
                request_status: authConstants.CONFIRM_EMAIL_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.CONFIRM_EMAIL_FAILURE:
            return {
                request_status: authConstants.CONFIRM_EMAIL_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.CONFIRM_EMAIL_RESET:
            return {
                request_status: authConstants.CONFIRM_EMAIL_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const resendActivationEmailReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.RESEND_ACTIVATION_EMAIL_PENDING:
            return {
                request_status: authConstants.RESEND_ACTIVATION_EMAIL_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.RESEND_ACTIVATION_EMAIL_SUCCESS:
            return {
                request_status: authConstants.RESEND_ACTIVATION_EMAIL_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.RESEND_ACTIVATION_EMAIL_FAILURE:
            return {
                request_status: authConstants.RESEND_ACTIVATION_EMAIL_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.RESEND_ACTIVATION_EMAIL_RESET:
            return {
                request_status: authConstants.RESEND_ACTIVATION_EMAIL_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const getOtpForVendorPhoneReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.GET_VENDOR_PHONE_OTP_PENDING:
            return {
                request_status: authConstants.GET_VENDOR_PHONE_OTP_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.GET_VENDOR_PHONE_OTP_SUCCESS:
            return {
                request_status: authConstants.GET_VENDOR_PHONE_OTP_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.GET_VENDOR_PHONE_OTP_FAILURE:
            return {
                request_status: authConstants.GET_VENDOR_PHONE_OTP_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.GET_VENDOR_PHONE_OTP_RESET:
            return {
                request_status: authConstants.GET_VENDOR_PHONE_OTP_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const confirmAccountPhoneReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.CONFIRM_PHONE_PENDING:
            return {
                request_status: authConstants.CONFIRM_PHONE_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.CONFIRM_PHONE_SUCCESS:
            return {
                request_status: authConstants.CONFIRM_PHONE_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.CONFIRM_PHONE_FAILURE:
            return {
                request_status: authConstants.CONFIRM_PHONE_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.CONFIRM_PHONE_RESET:
            return {
                request_status: authConstants.CONFIRM_PHONE_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const getStoreCatsReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.GET_STORE_CATS_PENDING:
            return {
                request_status: authConstants.GET_STORE_CATS_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.GET_STORE_CATS_SUCCESS:
            return {
                request_status: authConstants.GET_STORE_CATS_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.GET_STORE_CATS_FAILURE:
            return {
                request_status: authConstants.GET_STORE_CATS_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.GET_STORE_CATS_RESET:
            return {
                request_status: authConstants.GET_STORE_CATS_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}

export const uploadStoreLogoReducer = (state=initialState, action:any)=>{
    // export const forgotPasswordReducer = ({state=initialState, action}:ReducerParams)=>{
    switch (action.type) {
        case authConstants.UPLOAD_STORE_LOGO_PENDING:
            return {
                request_status: authConstants.UPLOAD_STORE_LOGO_PENDING,
                is_request_processing: true,
                request_data: action
            };
        case authConstants.UPLOAD_STORE_LOGO_SUCCESS:
            return {
                request_status: authConstants.UPLOAD_STORE_LOGO_SUCCESS,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.UPLOAD_STORE_LOGO_FAILURE:
            return {
                request_status: authConstants.UPLOAD_STORE_LOGO_FAILURE,
                is_request_processing: false,
                request_data: action
            };
        case authConstants.UPLOAD_STORE_LOGO_RESET:
            return {
                request_status: authConstants.UPLOAD_STORE_LOGO_RESET,
                is_request_processing: false,
                request_data: {},
            };
        

        default:
            return {...state} 
    }
}