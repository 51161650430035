import { LiaShippingFastSolid } from 'react-icons/lia'
import { MdOutlineLocalShipping } from 'react-icons/md'
import { TbMapSearch } from 'react-icons/tb'
import { CiCreditCard1 } from 'react-icons/ci'
import { BiUser } from 'react-icons/bi'
import { TbArrowsExchange2 } from 'react-icons/tb'
import { PiInfo } from 'react-icons/pi'
import '../../../scss/faq.scss'
import { useState } from 'react'
import { questions, HelpQuestions } from './questions'
import { GoSearch } from 'react-icons/go'
import { FaAngleUp } from 'react-icons/fa6'
import { TicketModal } from './ticket-modal'
import { TicketDropdown } from './ticketdropdown'
import { SupportChat } from './support-chat'
import { InAppTemplate } from '../../../shared/templates/inapp'


const customers = [
    {
        name: 'Order Listings',
        icon: <LiaShippingFastSolid className='text-xl text-textlightColor' />
    },
    {
        name: 'Product Listings',
        icon: <CiCreditCard1 className='text-xl text-textlightColor' />
    },
    {
        name: 'Order Management',
        icon: <TbMapSearch className='text-xl text-textlightColor' />
    },
    {
        name: 'Payments and Payouts',
        icon: <CiCreditCard1 className='text-xl text-textlightColor' />
    },
    {
        name: 'Shipping and Delivery',
        icon: <TbArrowsExchange2 className='text-xl text-textlightColor' />
    },
    {
        name: 'Customer Relations',
        icon: <PiInfo className='text-xl text-textlightColor' />
    },
    {
        name: 'Store Management',
        icon: <TbMapSearch className='text-xl text-textlightColor' />
    },
    {
        name: 'Policies and Compliance',
        icon: <PiInfo className='text-xl text-textlightColor' />
    },
]



export const HelpTab = ({ pageProps }: any) => {
    const [isActive, setIsActive] = useState<number>(0)
    const [item, setItem] = useState<Array<any>>(HelpQuestions[0]?.data)
    const [show, setShow] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [rotate, setRotate] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)


    const handleOnchange = (id: number) => {
        setIsActive(id)

        setItem(HelpQuestions[id]?.data)

    }
    const handleOnclick = () => {
        setShow(true)
    }
    const handleModalClose = () => {
        setShow(false)
    }
    const handleDropdown = () => {
        setOpen(!open)
        setRotate(!rotate)
    }
    const handleChatOpen = () => {
        setOpen(false)
        setIsOpen(true)
    }
    const handleChatClose = () => {
        setIsOpen(false)
    }

    return (
        <div className="tabsContainer">
            {
                isOpen && <SupportChat handleChatClose={handleChatClose} />
            }
            <div className="tabPane">
                <h4>Hi! How can we help?</h4>
                <p className="keyword">Type in a question or search keyword</p>
                <div className="searchbox">
                    <GoSearch className="icon" color='#B7B7B7' />
                    <input type='search' placeholder='Enter question here' className="input2" />
                </div>
                <div className="customers">
                    {
                        customers?.map((customer, id) => {
                            return (
                                <div key={id} className={isActive === id ? "customs" : "customerPane"} onClick={() => handleOnchange(id)}>
                                    <div className={isActive === id ? "icon1" : "icon2"}>
                                        {customer.icon}
                                    </div>
                                    <p className={isActive === id ? "name1" : "name"}>{customer.name}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="createTicket">
                    <div className="createContent">
                        <h4 className="ticketTitle"> Do you still need our help?</h4>
                        <p className="teamtitle">Contact our support team</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button className="btn" onClick={() => handleOnclick()}>Create ticket</button>
                            {/* <button className="btn">Contact us</button> */}
                        </div>
                    </div>
                </div>
                {
                    show && <TicketModal handleModalClose={handleModalClose} />
                }
            </div>
            <div className="tabpane2">
                <div className="support" onClick={() => {
                    handleDropdown()
                }}>
                    <div className="red_dot" />
                    <p className="two">2 support tickets</p>
                    <FaAngleUp className={rotate ? "angle" : "angle2"} />
                </div>
                {
                    open && <TicketDropdown handleChatOpen={handleChatOpen} 
                     />
                }

                {item?.map((question: any, index: number) => (
                    <>
                        <div className="question2" key={index}>
                            <div style={{marginBottom: '.5rem'}}>
                                <div className="titleIcon">
                                    {question.icon}
                                </div>
                                <h6 className="questionTitle">{question.title}</h6>
                            </div>
                            <div className="flex">
                                <h3 className="question">{question.question}</h3>

                            </div>
                            <p className="answer">{question.answer}</p>

                        </div>
                    </>
                ))}
            </div>

        </div>
    )
}
const HelpCenter = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Help Center" noStores={false} childComponent={<HelpTab pageProps={pageProps} />} />
    )
}
export default HelpCenter;