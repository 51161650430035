import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authActions } from "../../../redux/actions/onboarding";
import { storeActions } from "../../../redux/actions/in-app";
import { MultiSelect } from "react-multi-select-component";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    geocodeByPlaceId,
} from "react-google-places-autocomplete";
import PhoneInput from "react-phone-input-2";
import Shop from "../../../assets/icons/store-logo.svg";
import { appConstants } from "../../../redux/action-constants/inapp.constants";
import { authConstants } from "../../../redux/action-constants/onboarding.constants";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";



const getStoreUrlAction = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreUrlAction(payload);
}

const getStoreCatsAction = async ({ pageProps, payload }: any) => {

    await pageProps.getStoreCategoriesAction(payload);
}

const handleCreateNewStore = async ({ pageProps, payload, storeLogo }: any) => {

    await pageProps.addNewStoreAction(payload, storeLogo)
}

const AddANewStore = ({
    showNewStore,
    setShowNewStore,
    pageProps
}: any) => {

    const [allCatList, setAllCatList] = useState<any>([{ value: "", label: "" }]);
    const [extractedInfo, setExtractedInfo] = useState<any>();
    const [addressLatLng, setAddressLatLng] = useState<any>();
    const [isShowmore, setIsShowmore] = useState<boolean>(false);
    const [isLogoMissing, setIsLogoMissing] = useState<boolean>(false);
    const [addressSearched, setAddressSearched] = useState<any>();
    const [storeLogo, setStoreLogo] = useState<any>({ image: Shop });
    const [storeLogoConverted, setStoreLogoConverted] = useState<any>();
    const [storeFormInitValues, setStoreFormInitValues] = useState<any>({
        storeName: "",
        storeCat: [],
        storeAddress: "",
        storePostCode: "",
        storeProvince: "",
        storeNumber: "",
        storeEmail: "",
    });
    let getCatsReq = pageProps?.getStoreCatsRequest;
    let createnewStoreReq = pageProps?.addNewStoreRequest;
    let getUrls = pageProps?.getUrlRequest;
    let extractedData: any = {};
    useEffect(() => {

        getStoreCatsAction({
            pageProps,
            payload: `Page=1&Size=1000`
        })
        getStoreUrlAction({
            pageProps,
            payload: "CLEAR"
        })
        handleCreateNewStore({
            pageProps,
            payload: "CLEAR"
        })
    }, [])


    // useEffect(() => {
    //     if (!isComponentMounted) {
    //       setIsComponentMounted(true);
    //       setCategories([]);
    //     } else if (
    //       Array.isArray(
    //         pageProps?.getStoreCatsRequest?.request_data?.response?.results
    //       )
    //     ) {
    //       const all_cats =
    //         pageProps?.getStoreCatsRequest?.request_data?.response?.results?.map(
    //           (element: { name: string }) => {
    //             return {
    //               value: element?.name,
    //               label: element?.name,
    //             };
    //           }
    //         );
    //       setCategories(all_cats);
    //     }
    //   }, [pageProps?.getStoreCatsRequest, isComponentMounted]);

    useEffect(() => {
        if (getCatsReq?.request_status === authConstants?.GET_STORE_CATS_SUCCESS && getCatsReq.request_data?.response?.results) {
            let catData = getCatsReq.request_data?.response?.results;
            let allCat = []

            for (let index = 0; index < catData.length - 1; index++) {
                allCat.push({
                    value: catData[index]?.name,
                    label: catData[index]?.name,
                })
            }

            setAllCatList(allCat)
        }
    }, [getCatsReq?.request_status]);

    useEffect(() => {
        if (extractedInfo) {
            // console.log("extractedInfo", extractedInfo)
            // setPostalCodeAreaError("")
            let tempStoreFormInitValues = { ...storeFormInitValues }
            tempStoreFormInitValues.storeProvince = extractedInfo?.provinceChosenFull
            tempStoreFormInitValues.storePostCode = extractedInfo?.postCode
            setStoreFormInitValues(tempStoreFormInitValues)

            // console.log("tempStoreFormInitValues", tempStoreFormInitValues)

        }

    }, [extractedInfo])

    const handleSelectedAddress = async (address: any, setFieldValue: any) => {

        setAddressSearched(address)

        // console.log("addresses 2", address)
        // getLatLng

        const [place] = await geocodeByPlaceId(address?.value.place_id);
        let addressMeta = place?.address_components;

        // console.log("placeMeta", place)

        geocodeByAddress(address?.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }: any) => {
                // console.log("addressLat", lat)
                // console.log("addressLng", lng)
                setAddressLatLng({ lat, lng })
            }
            );


        let extractedInfoFromAddress = () => {
            let streetNumber: any = '',
                streetName: any = '',
                areaName: any = '',
                townName: any = '',
                postalCodeArea: any = '',
                fullAddress: any,
                topLevelArea: any,
                provinceChosenFull: any,
                provinceChosen: any;

            for (let componentIndex = 0; componentIndex < addressMeta.length; componentIndex++) {

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "postal_code" && !extractedData?.postCode
                ) {
                    extractedData.postCode = addressMeta[componentIndex]?.long_name;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "street_number" && streetNumber === ""
                ) {

                    streetNumber = `${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "route" && streetName === ""
                ) {
                    streetName = `${streetNumber !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 1
                    && (
                        addressMeta[componentIndex]?.types.includes("neighborhood") ||
                        addressMeta[componentIndex]?.types.includes("locality")
                    )
                    && (areaName.split(",").length - 1) < 1

                    // && areaName === ""
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                // console.log("postalCodeArea.length",postalCodeArea.length)
                // console.log("administrative_area_level_2",addressMeta[componentIndex]?.types.includes("administrative_area_level_2"))
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5") ||
                        addressMeta[componentIndex]?.types.includes("locality") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")


                    )
                    // && postalCodeArea.length === 0
                    // && areaName === ""
                ) {
                    postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )
                    && ((postalCodeArea.split(",").length - 1) < 1)

                ) {
                    postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name}`;
                    topLevelArea = addressMeta[componentIndex]?.long_name
                }
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )


                ) {
                    provinceChosen = addressMeta[componentIndex]?.short_name
                    provinceChosenFull = addressMeta[componentIndex]?.long_name
                }


                // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
                fullAddress = `${streetNumber} ${streetName} ${areaName}`;
                extractedData.newAddress = fullAddress;
                extractedData.postalCodeArea = postalCodeArea;
                extractedData.topLevelArea = topLevelArea;
                extractedData.provinceChosen = provinceChosen;
                extractedData.provinceChosenFull = provinceChosenFull;


                setFieldValue("storeProvince", provinceChosenFull)
                setFieldValue("storePostCode", extractedData?.postCode)

                if (extractedData?.postCode && extractedData?.newAddress
                    && extractedData?.provinceChosen && extractedData?.provinceChosenFull) {
                    break;
                }

            }

            // console.log("data is ", extractedData)
            return extractedData
        }
        setExtractedInfo(extractedInfoFromAddress())



    }

    const genrateDefaultLogo = async (image: any, filename: string) => {


        let getFileData = await fetch(image)
            .then(async response => {
                const contentType = response.headers.get('content-type')
                const blob = await response.blob()
                // @ts-ignore
                const file = new File([blob], filename, { contentType })


                return { file };
            })
        return getFileData

    }

    const dataURLtoFile = (dataurl: any, filename: any) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const handleLogoUpload = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setStoreLogo({ file, image: reader.result });
        };

        reader.readAsDataURL(file);
    };

    let storeDetailsInitialValues: any = {
        storeName: "",
        storeCat: [],
        storeAddress: "",
        storePostCode: "",
        storeProvince: "",
        storeNumber: "",
        storeEmail: "",
        storeUrl: "https://mamimarkets.com/{store-name}",
        agreed: false

        //   phoneNumber: "",
    }




    let storeDetailsValidationSchema = Yup.object().shape({
        storeName: Yup.string()
            .required("Required"),
        storeCat: Yup.array()
            .required("Required"),
        storeAddress: Yup.string()
            .required("Required"),
        storePostCode: Yup.string()
            .required("Required"),
        storeProvince: Yup.string()
            .required("Required"),
        storeNumber: Yup.string()
            .required("Required"),
        storeEmail: Yup.string()
            .required("Required"),
        storeUrl: Yup.string()
            .required("Required"),


    });


    return (
        <div className="modal_wrap">
            <Modal className="new_store_wrap" show={showNewStore} onHide={() => setShowNewStore(false)}>
                <Modal.Header closeButton>
                    {/* <div className="reason_heading">Reason for return </div> */}

                    {/* <div className="send-head"></div> */}
                    Add a new store
                </Modal.Header>
                <Modal.Body>
                    {createnewStoreReq?.request_status !== appConstants?.ADD_A_NEW_STORE_SUCCESS &&
                        <Formik
                            initialValues={storeDetailsInitialValues}
                            validationSchema={storeDetailsValidationSchema}
                            onSubmit={async (values: any) => {
                               
                                if (storeLogo) {
                                    setIsLogoMissing(false)
                                    let catsList = values.storeCat.map((ele: { value: string }) => ele?.value);

                                  
                                    let payload = {
                                        name: values?.storeName,
                                        streetAddress: values?.storeAddress,
                                        zipOrPostcode: values?.storePostCode,
                                        country: "CA",
                                        state: values?.storeProvince,
                                        // city: values?.storeProvince,
                                        email: values?.storeEmail,
                                        phoneNumber: values?.storeNumber,
                                        url: values?.storeUrl,
                                        tags: catsList.toString(),
                                        // tags: values.storeCat.map((ele: { value: string }) => ele?.value),
                                        longitude: addressLatLng.lng,
                                        latitude: addressLatLng.lat,
                                        // "isBulkBuy": true,
                                    }


                                   

                                    let logoFormData: any = new FormData();

                                    if (!storeLogo?.file) {
                                        // let logdataURLtoFile = dataURLtoFile(storeLogo?.image, `${values?.storeName}-logo.png`)
                                        // logoFormData.append("logo", logdataURLtoFile);

                                        genrateDefaultLogo(storeLogo?.image, `${values?.storeName}-logo.png`)
                                            .then((res: any) => {


                                                logoFormData.append("logo", res?.file);


                                                setStoreLogoConverted(logoFormData)

                                            })
                                    } else {
                                        logoFormData.append("logo", storeLogo?.file);
                                        
                                        // setStoreLogoConverted(logoFormData)

                                       
                                        await handleCreateNewStore({ pageProps, payload, storeLogo: logoFormData })
                                    }
                                    // console.log("storeLogoConverted", storeLogoConverted, storeLogo?.file)
                                    if (storeLogoConverted) {


                                        await handleCreateNewStore({ pageProps, payload, storeLogo: storeLogoConverted })
                                    }
                                } else {
                                    setIsLogoMissing(true)
                                }

                            }}
                        >
                            {(props: FormikProps<any>) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    setFieldTouched,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    setFieldError,
                                } = props;
                                return (
                                    <Form>
                                        {getCatsReq?.request_status === authConstants?.GET_STORE_CATS_SUCCESS && allCatList.length >= 1 &&
                                            <div className="form-wrap new_store-form">
                                                <div
                                                    className="logo_url"
                                                >
                                                    <div className="store-logo" style={{ position: "relative" }}>
                                                        <div className="logo-icon">

                                                            <label htmlFor="vendor-logo">
                                                                <img src={storeLogo?.image} alt="" />
                                                            </label>
                                                            <input
                                                                accept="image/*"
                                                                onChange={(e: any) => handleLogoUpload(e)}
                                                                type="file"
                                                                name=""
                                                                id="vendor-logo"
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "0",
                                                                    top: "2%",
                                                                    opacity: "0",
                                                                    width: 0
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="inputbox" id="form">
                                                    <input
                                                        name="storeName"
                                                        onChange={handleChange}
                                                        onBlur={(event) => {
                                                            handleBlur(event);


                                                            pageProps.getStoreUrlAction(values.storeName);
                                                        }}
                                                        type="text"
                                                        placeholder="Store name"
                                                    />
                                                    {!!errors.storeName && touched.storeName && (
                                                        <p className="error">{errors.storeName}</p>
                                                    )}

                                                    <p className="link">https://mamimarkets.com/</p>
                                                </div> */}
                                                    <div className="form-group">
                                                        <label htmlFor="">Store name</label>
                                                        <Field
                                                            id="storeName"
                                                            value={values.storeName}
                                                            name="storeName"
                                                            type="text"
                                                            placeholder="Store name"
                                                            onChange={handleChange}
                                                            onBlur={(event: any) => {
                                                                handleBlur(event);

                                                                setIsShowmore(false)
                                                                getStoreUrlAction({
                                                                    pageProps,
                                                                    payload: values.storeName
                                                                });
                                                            }}
                                                            className={`input-wrap ${errors.storeName && touched.storeName ?
                                                                "is-invalid-input "
                                                                : values.storeName !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <p className="link">{values?.storeUrl}</p>
                                                        <ErrorMessage name="storeName" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="store_urls_list">
                                                    {getUrls?.request_status === authConstants?.GET_URL_OPTIONS_PENDING &&
                                                        <div className="loading_urls"> Please wait...</div>
                                                    }
                                                    {getUrls?.request_status === authConstants?.GET_URL_OPTIONS_SUCCESS &&
                                                        <div className="url_options">
                                                            <div className="url_header_wrap">
                                                                <p className="url_header">Choose your preferred store url</p>
                                                                {isShowmore && <span className="show_urls" onClick={() => {
                                                                    setIsShowmore(false)
                                                                }}>Show all &gt;&gt; </span>}
                                                            </div>

                                                            {!isShowmore && getUrls?.request_data?.response.map(
                                                                (eachItem: any, index: any) => {
                                                                    return (
                                                                        <div className="each-options" key={index}>
                                                                            {/* <span className={`custom_radio`}> */}
                                                                            <span
                                                                                className={`custom_radio ${values.storeUrl === eachItem ? "selected_radio" : ""
                                                                                    } `}
                                                                            >
                                                                                <input
                                                                                    onChange={(e) => {
                                                                                        setFieldValue("storeUrl", e.target.value);
                                                                                        setIsShowmore(true)
                                                                                    }}
                                                                                    name="storeUrl"
                                                                                    type="radio"
                                                                                    id={eachItem}
                                                                                    value={eachItem}
                                                                                />
                                                                            </span>
                                                                            <label htmlFor={eachItem}>{eachItem}</label>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    }
                                                    {getUrls?.request_status === authConstants?.GET_URL_OPTIONS_FAILURE &&
                                                        <AlertMsg type="error" message={getUrls?.request_data?.error} />
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="">Store category</label>
                                                    <MultiSelect
                                                        hasSelectAll={false}
                                                        options={allCatList}
                                                        value={values.storeCat}
                                                        onChange={(selectedCats: any) => {
                                                            setFieldValue("storeCat", selectedCats);
                                                            setFieldTouched("storeCat", true);

                                                        }}
                                                        labelledBy="Select"
                                                        className={`biz-cat ${errors.storeCat && values.storeCat.length === 0 ? "invalid-input-wrap" : ""
                                                            }`}
                                                        overrideStrings={{
                                                            search: "Choose store categories",
                                                            selectSomeItems: "What kind of store is it?",
                                                            allItemsAreSelected: "All Categories are chosen.",
                                                            noOptions: "No categories match",
                                                        }}
                                                    />

                                                    <ErrorMessage name="storeCat" className="form-input-error" component="div" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="">Store&apos;s address</label>
                                                    <div className={errors.storeAddress ? "invalid-input-wrap address-wrap" : "address-wrap"}>
                                                        <GooglePlacesAutocomplete
                                                            selectProps={{
                                                                value: addressSearched,
                                                                // value: storeAddress,
                                                                onChange: (address) => {
                                                                    handleSelectedAddress(address, setFieldValue)
                                                                    setFieldValue("storeAddress", "set")
                                                                    // setFieldValue("storePostCode", " ")
                                                                    // setFieldTouched("storeAddress", true)
                                                                    setFieldError("storeAddress", "")
                                                                    // setFieldError("storePostCode", "")
                                                                },
                                                                // onChange: setStoreAddress,
                                                                placeholder: "Store address",
                                                                // autoFocus: true,
                                                                classNamePrefix: "store-address-select",
                                                                openMenuOnClick: false,
                                                                className: "",
                                                                noOptionsMessage: () => 'Type a valid address'
                                                            }}
                                                            autocompletionRequest={{
                                                                componentRestrictions: {
                                                                    country: ['ca'],
                                                                }
                                                            }}
                                                            apiKey={appConstants.GOOGLE_MAP_KEY}
                                                        />

                                                    </div>
                                                    <ErrorMessage name="storeAddress" className="form-input-error" component="div" />
                                                </div>
                                                <div className="two-sided">
                                                    <div className="form-group">
                                                        <label htmlFor="">Province</label>
                                                        <Field
                                                            id="storeProvince"
                                                            value={values.storeProvince || storeFormInitValues?.storeProvince}
                                                            name="storeProvince"
                                                            type="text"
                                                            placeholder="Province"
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.storeProvince && touched.storeProvince ?
                                                                "is-invalid-input "
                                                                : values.storeProvince !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="storeProvince" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Postal code</label>
                                                        <Field
                                                            id="storePostCode"
                                                            value={values.storePostCode || storeFormInitValues?.storePostCode}
                                                            name="storePostCode"
                                                            type="text"
                                                            placeholder="Postal Code"
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.storePostCode && touched.storePostCode ?
                                                                "is-invalid-input "
                                                                : values.storePostCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="storePostCode" className="form-input-error" component="div" />
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="">Store&apos;s email address</label>
                                                    <Field
                                                        id="storeEmail"
                                                        value={values.storeEmail}
                                                        name="storeEmail"
                                                        type="text"
                                                        placeholder="Email Address"
                                                        onChange={handleChange}
                                                        className={`input-wrap ${errors.storeEmail && touched.storeEmail ?
                                                            "is-invalid-input "
                                                            : values.storeEmail !== "" ? "valid-input" : ""}`}
                                                    />
                                                    <ErrorMessage name="storeEmail" className="form-input-error" component="div" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="">Store&apos;s phone number</label>
                                                    <PhoneInput
                                                        country={'ca'}
                                                        enableTerritories={false}
                                                        enableSearch={true}
                                                        disableSearchIcon={true}
                                                        inputClass={`input-wrap ${errors.storeNumber && touched.storeNumber ?
                                                            "is-invalid-input "
                                                            : values.storeNumber !== "" ? "valid-input" : ""}`}
                                                        value={values.storeNumber}
                                                        onChange={(phone: string): void => {

                                                            setFieldTouched("storeNumber", true)
                                                            setFieldValue("storeNumber", phone)
                                                        }}
                                                    />
                                                    {/* <Field
                                                        id="storeNumber"
                                                        value={values.storeNumber}
                                                        name="storeNumber"
                                                        type="text"
                                                        placeholder="Phone number"
                                                        onChange={handleChange}
                                                        className={`input-wrap ${errors.storeNumber && touched.storeNumber ?
                                                            "is-invalid-input "
                                                            : values.storeNumber !== "" ? "valid-input" : ""}`}
                                                    /> */}
                                                    <ErrorMessage name="storeNumber" className="form-input-error" component="div" />
                                                </div>

                                                <div className="terms_txt">

                                                    <label htmlFor="agree_store">
                                                        <input type="checkbox" name="agreed" value={values.agreed} onChange={handleChange} id="agree_store" />
                                                        I accept MamiMarket&apos;s <a href="">Terms of Service</a> and <a href=""> Privacy Policy</a>
                                                    </label>
                                                </div>

                                                {isLogoMissing &&
                                                    <AlertMsg type="error" message="Please upload store logo" />

                                                }

                                                {createnewStoreReq?.request_status === appConstants?.ADD_A_NEW_STORE_FAILURE &&
                                                    <AlertMsg type="error" message={createnewStoreReq?.request_data?.error} />
                                                }

                                                <div className="payout-cta">
                                                    <button
                                                        disabled={values.agreed ? createnewStoreReq?.is_request_processing : true}
                                                        className={createnewStoreReq.is_request_processing ? "btn btn-disabled" : "btn"}
                                                    >
                                                        {createnewStoreReq.is_request_processing ? "Please wait..." : "Add Store"}
                                                    </button>
                                                </div>
                                            </div>

                                        }
                                        {getCatsReq?.request_status === authConstants?.GET_STORE_CATS_PENDING &&
                                            <LoadingItem />
                                        }

                                        {getCatsReq?.request_status === authConstants?.GET_STORE_CATS_FAILURE &&
                                            <AlertMsg type="error" message={getCatsReq?.request_data?.error} />
                                        }



                                    </Form>
                                )
                            }}
                        </Formik>
                    }

                    {createnewStoreReq?.request_status === appConstants?.ADD_A_NEW_STORE_SUCCESS &&
                        <AlertMsg type="success" message="Store has been successfully created" />
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}


const NewStoreModalWrap = (pageProps: any) => {
    useEffect(() => {

        getStoreCatsAction({
            pageProps,
            payload: "CLEAR"
        })
        // handleCreateNewStore({
        //     pageProps,
        //     payload: "CLEAR"
        // })

    }, [])
    return (
        <>
            {pageProps?.showNewStore &&
                <AddANewStore
                    pageProps={pageProps}
                    showNewStore={pageProps?.showNewStore}
                    setShowNewStore={pageProps?.setShowNewStore}
                />
            }
        </>
    )
}

const mapDispatchToProps = {
    getStoreUrlAction: authActions.GetStoreUrl,
    getStoreCategoriesAction: authActions.GetStoreCategories,
    addNewStoreAction: storeActions.AddNewStore,
};

const mapStateToProps = (state: any) => ({
    getUrlRequest: state.onboardingOutboundReducers.getUrlReducer,
    getStoreCatsRequest: state.onboardingOutboundReducers.getStoreCatsReducer,
    addNewStoreRequest: state.allProductReducers.addNewStoreReducer,
})

export default connect(mapStateToProps, mapDispatchToProps)(NewStoreModalWrap);