import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { appConstants } from "../../action-constants/inapp.constants";

import { Dispatch } from 'redux';

import { history } from "../../../shared/_helpers/history";
import {
    handleRequestErrors,
} from "../../../shared/utils";


const CheckIfAreaFromPostalCodeMatchCurrentArea = (geocodeData: any, currentTopArea: any) => {
    
    

    let postalCodeArea = ``;
    let addressDataBulk = geocodeData;
    for (let componentIndex = 0; componentIndex < addressDataBulk.length; componentIndex++) {
        if (addressDataBulk[componentIndex]?.types.length >= 2
            && (
                addressDataBulk[componentIndex]?.types.includes("administrative_area_level_1")
            )
        ) {
            let newTopArea = addressDataBulk[componentIndex]?.long_name;

            
            if (currentTopArea === newTopArea) {
                return true
            } else {
                return false
            }

        }


    }





}

const ExtractAreaFromPostalCodeAndArea = (geocodeData: any) => {

    let postalCodeArea = ``,
        postalCodeProvince = ``;
    let addressDataBulk = geocodeData?.address_components;
    for (let componentIndex = 0; componentIndex < addressDataBulk.length; componentIndex++) {

        if (addressDataBulk[componentIndex]?.types.length >= 2
            && (
                addressDataBulk[componentIndex]?.types.includes("neighborhood") ||
                addressDataBulk[componentIndex]?.types.includes("locality") ||
                addressDataBulk[componentIndex]?.types.includes("administrative_area_level_5") ||
                addressDataBulk[componentIndex]?.types.includes("administrative_area_level_4") ||
                addressDataBulk[componentIndex]?.types.includes("administrative_area_level_3")
            )
            && postalCodeArea.length === 0
            // && areaName === ""
        ) {
            postalCodeArea += `${addressDataBulk[componentIndex]?.long_name}`;

        }

        if (addressDataBulk[componentIndex]?.types.length >= 2
            && (
                // addressDataBulk[componentIndex]?.types.includes("neighborhood") ||
                addressDataBulk[componentIndex]?.types.includes("locality")
                // ||
                // addressDataBulk[componentIndex]?.types.includes("administrative_area_level_5") ||
                // addressDataBulk[componentIndex]?.types.includes("administrative_area_level_4") ||
                // addressDataBulk[componentIndex]?.types.includes("administrative_area_level_3")
            )
            // && postalCodeArea.length === 0
            // && areaName === ""
        ) {
            postalCodeArea = `${addressDataBulk[componentIndex]?.long_name}`;

        }

        // if (addressDataBulk[componentIndex]?.types.length >= 2
        //     && (

        //         // addressDataBulk[componentIndex]?.types.includes("administrative_area_level_2") ||
        //         addressDataBulk[componentIndex]?.types.includes("administrative_area_level_1")
        //     )
        //     && ((postalCodeArea.split(",").length - 1) < 1)

        // ) {

        //     postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressDataBulk[componentIndex]?.short_name}`;
        // }


    }




    return postalCodeArea;
}

export const GetGeoCodeInfoWithPostalCode = (requestData: any, currentTopArea?: any) => {

    let apiConsume = ApiService.request({ url: `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${requestData}&key=${appConstants.GOOGLE_MAP_KEY}`, http_method: "GET" });


    return apiConsume
        .then((response: any) => {

            if (response?.data) {
                if (response?.data?.results.length >= 1) {
                    if (CheckIfAreaFromPostalCodeMatchCurrentArea(response?.data?.results[0]?.address_components, currentTopArea)) {
                        return {
                            status: "success",
                            data: ExtractAreaFromPostalCodeAndArea(response?.data?.results[0])
                            // data: response.data
                        }
                    } else {
                        return {
                            status: "error",
                            error: "Postal code not supported"
                        };
                    }
                } else {
                    return {
                        status: "error",
                        error: "Postal code not supported"
                    };
                }

            } else {
                return {
                    status: "error",
                    error: "An Error Occured. Please try again"
                };
            }
        })
        .catch((error: any) => {
            return {
                status: "error",
                error: handleRequestErrors(error)
            };

        });

}





const CreateStoreProduct = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.CREATE_PRODUCT, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    history.replace("/app/my-products")
                    // history.replace("/app/my-products/success")

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.CREATE_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.CREATE_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.CREATE_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.CREATE_PRODUCT_RESET, clear_data: "" };
    }
}



const UpdateStoreProduct = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.UPDATE_PRODUCT, http_method: "PUT", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    dispatch(success(response.data))
                    history.replace("/app/my-products")
                    // history.replace("/app/my-products/success")

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPDATE_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPDATE_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPDATE_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPDATE_PRODUCT_RESET, clear_data: "" };
    }
}

const UploadProductImage = (actionPayload: any, otherNames: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.PRODUCT_IMAGE_UPLOAD, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {

                    let responseData = { ...response.data, otherNames }
                    dispatch(success(responseData))

                    // }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPLOAD_PRODUCT_PICTURE_RESET, clear_data: "" };
    }
}

const GetMyProductList = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {
        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let payload = `StoreId=${loggedAccountInfo.selectedStore.id}&${actionPayload}`
        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_VENDORS_PRODUCTS}?${payload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_PRODUCTS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_PRODUCTS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_PRODUCTS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_PRODUCTS_RESET, clear_data: "" };
    }
}

const ToggleAProduct = (actionPayload: any, callback: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.TOGGLE_PRODUCT}${actionPayload?.storeId}/Product/${actionPayload?.productId}/Toggle`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    callback()
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.TOGGLE_A_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.TOGGLE_A_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.TOGGLE_A_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.TOGGLE_A_PRODUCT_RESET, clear_data: "" };
    }
}

const GetStoreOrders = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {
        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let payload = `StoreId=${loggedAccountInfo.selectedStore.id}&${actionPayload}`
        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORE_ORDERS}?${payload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_VENDOR_ORDERS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_VENDOR_ORDERS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_VENDOR_ORDERS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_VENDOR_ORDERS_RESET, clear_data: "" };
    }
}

const GetStoreOrderDetails = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {
        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let payload = `StoreId=${loggedAccountInfo.selectedStore.id}&${actionPayload}`
        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORE_ORDER_DETAIL}?${payload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_VENDOR_ORDER_DETAILS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_VENDOR_ORDER_DETAILS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_VENDOR_ORDER_DETAILS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_VENDOR_ORDER_DETAILS_RESET, clear_data: "" };
    }
}

const UpdateStoreDetails = (actionPayload: any, storeLogo?: any) => {

    if (actionPayload !== "CLEAR") {
        return (dispatch: Dispatch) => {
            if (storeLogo) {
                let apiConsume = ApiService.request({ url: routes.LOGO_IMAGE_UPLOAD, http_method: "POST", data: storeLogo });
                dispatch(request(apiConsume));
                return apiConsume
                    .then((response: any) => {
                        
                        let mainData: any = { ...actionPayload }
                        if (response?.data) {
                            mainData.logoUrl = response.data.fileURL
                            
                            let apiConsum2 = ApiService.request({ url: routes.UPDATE_STORE_DETAILS, http_method: "PUT", data: mainData });
                            dispatch(request(apiConsum2));
                            return apiConsum2
                                .then((response2: any) => {
                                    dispatch(success(response2.data))

                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                        }



                    })
                    .catch((error: any) => {
                        dispatch(failure(handleRequestErrors(error)));
                    });
            } else {

                let apiConsume = ApiService.request({ url: `${routes.UPDATE_STORE_DETAILS}`, http_method: "PUT", data: actionPayload });
                dispatch(request(apiConsume));
                return apiConsume
                    .then((response: any) => {
                        dispatch(success(response.data))
                    })
                    .catch((error: any) => {
                        dispatch(failure(handleRequestErrors(error)));
                    });
            }
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPDATE_STORE_DETAILS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPDATE_STORE_DETAILS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPDATE_STORE_DETAILS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPDATE_STORE_DETAILS_RESET, clear_data: "" };
    }
}

const GetStoreDetails = (actionPayload: any, withCategory?: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_STORE_DETAILS}/${actionPayload}/detail`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    // 
                    if (withCategory) {
                        let catPayload = `Page=${1}&Size=${1000}`;
                        let apiConsume2 = ApiService.request({ url: `${routes.GET_STORES_CATS}?${catPayload}`, http_method: "GET" });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {
                                let resultData = { ...response.data, fetchedCats: response2.data?.results }
                                dispatch(success(resultData))
                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                    } else {
                        dispatch(success(response.data))
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORE_DETAILS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_STORE_DETAILS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_STORE_DETAILS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORE_DETAILS_RESET, clear_data: "" };
    }
}

const GetAProduct = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_A_PRODUCT}?Id=${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_A_PRODUCT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_A_PRODUCT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_A_PRODUCT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_A_PRODUCT_RESET, clear_data: "" };
    }
}

const GetProductMetaData = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let metaData: any = {};
            let apiConsume = ApiService.request({ url: `${routes.GET_CATEGORIES}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    // dispatch(success(response.data))
                    // }
                    let apiConsume2 = ApiService.request({ url: `${routes.GET_UNITS}`, http_method: "GET" });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            // if (response?.data) {
                            metaData.units = response2.data.results;
                            metaData.categories = response.data.results;

                            dispatch(success(metaData))
                            // }
                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_PRODUCT_META_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_PRODUCT_META_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_PRODUCT_META_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_PRODUCT_META_RESET, clear_data: "" };
    }
}

const GetImageGallery = (actionPayload: any, isNewBatch: boolean) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_IMAGE_GALLERY}?${actionPayload || ""}`, http_method: "GET" });
            if (!isNewBatch) {
                dispatch(request(apiConsume));
            }
            return apiConsume
                .then((response: any) => {
                    // dispatch(success(response))
                    if (!isNewBatch) {
                        
                        dispatch(success(response))
                    } else {
                        dispatch(nextBatchLoaded(response))
                    }
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_SUCCESS, response: response.data, results: response.data.results };
    }
    function nextBatchLoaded(response: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_NEWBATCH, response: response.data, results: response.data.results };
    }
    function failure(error: any) {
        return { type: appConstants.GET_IMAGE_GALLERY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_IMAGE_GALLERY_RESET, clear_data: "" };
    }
}

const GetStorePaymentAccount = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_PAY_ACCOUNTS}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    let apiConsume2 = ApiService.request({ url: `${routes.GET_PUBLISH_KEY}`, http_method: "GET" });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            dispatch(success(response.data, response2.data))
                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORE_PAY_ACCOUNTS_PENDING, params };
    }
    function success(response: any, publishkey: any) {
        return { type: appConstants.GET_STORE_PAY_ACCOUNTS_SUCCESS, response, publishkey };
    }
    function failure(error: any) {
        return { type: appConstants.GET_STORE_PAY_ACCOUNTS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORE_PAY_ACCOUNTS_RESET, clear_data: "" };
    }
}

const GetStorePaymentsHistory = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.GET_PAYOUT_HISTORY}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_STORE_PAYMENT_HISTORY_RESET, clear_data: "" };
    }
}

const CreatePayoutAccount = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: routes.CREATE_PAYOUT_ACC, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {
                    dispatch(success(response.data))
                    
                    // history.replace("/app")
                    window.location.href = response.data.link

                    // let apiConsume2 = ApiService.request({ url: `${routes.CREATE_PAYOUT_LINK}/${actionPayload?.storeId}`, http_method: "GET" });
                    // dispatch(request(apiConsume2));
                    // return apiConsume
                    //     .then((response2: any) => {
                    //         dispatch(success(response2.data))
                    
                    //     })
                    //     .catch((error: any) => {
                    //         dispatch(failure(handleRequestErrors(error)));
                    //     });
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.CREATE_PAYOUT_ACCOUNT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.CREATE_PAYOUT_ACCOUNT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.CREATE_PAYOUT_ACCOUNT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.CREATE_PAYOUT_ACCOUNT_RESET, clear_data: "" };
    }
}

const DeactivatePayoutAccount = (actionPayload: any,) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {



            let apiConsume = ApiService.request({ url: routes.DE_ACTIVATE_WITHDRAWAL_METHOD, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    dispatch(success(response.data))




                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.DEACTIVATE_PAYOUT_ACCOUNT_RESET, clear_data: "" };
    }
}

const GetPayoutPendingLink = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);


        return (dispatch: Dispatch) => {

            // let apiConsume = ApiService.request({ url: routes.CREATE_PAYOUT_ACC, http_method: "POST", data: actionPayload });
            let apiConsume = ApiService.request({ url: `${routes.CREATE_PAYOUT_LINK}/${loggedAccountInfo.selectedStore.id}/${actionPayload?.accountId}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {
                    dispatch(success(response.data))
                    
                    // history.replace("/app")
                    window.location.href = response.data.link

                    // let apiConsume2 = ApiService.request({ url: `${routes.CREATE_PAYOUT_LINK}/${actionPayload?.storeId}`, http_method: "GET" });
                    // dispatch(request(apiConsume2));
                    // return apiConsume
                    //     .then((response2: any) => {
                    //         dispatch(success(response2.data))
                    
                    //     })
                    //     .catch((error: any) => {
                    //         dispatch(failure(handleRequestErrors(error)));
                    //     });
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_PAYOUT_PENDING_LINK_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_PAYOUT_PENDING_LINK_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_PAYOUT_PENDING_LINK_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_PAYOUT_PENDING_LINK_RESET, clear_data: "" };
    }
}


const GetDashbardTopData = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let dataSummary: any = {};
            let apiConsume = ApiService.request({ url: `${routes.SUMMARY_REPORT}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    // dispatch(success(response.data))
                    // }
                    
                    let apiConsume2 = ApiService.request({ url: `${routes.CUSTOMER_COUNT}?${actionPayload}`, http_method: "GET" });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            // if (response?.data) {
                            
                            dataSummary.orders = response.data;
                            dataSummary.customer = response2.data;

                            // dispatch(success(dataSummary))

                            let apiConsume3 = ApiService.request({ url: `${routes.GET_PUBLISH_KEY}`, http_method: "GET" });
                            dispatch(request(apiConsume3));
                            return apiConsume3
                                .then((response3: any) => {
                                    // if (response?.data) {
                                    
                                    dataSummary.orders = response.data;
                                    dataSummary.customer = response2.data;

                                    dispatch(success(dataSummary, response3?.data))
                                    // }
                                })
                                .catch((error: any) => {
                                    dispatch(failure(handleRequestErrors(error)));
                                });
                            // }
                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_DASHBOARD_TOP_DATA_PENDING, params };
    }
    function success(response: any, publishkey: any) {
        return { type: appConstants.GET_DASHBOARD_TOP_DATA_SUCCESS, response, publishkey };
    }
    function failure(error: any) {
        return { type: appConstants.GET_DASHBOARD_TOP_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_DASHBOARD_TOP_DATA_RESET, clear_data: "" };
    }
}

const GetDashbardMiddleData = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {

        return (dispatch: Dispatch) => {

            let apiConsume = ApiService.request({ url: `${routes.REVIEW_SUMMARY}?${actionPayload}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    dispatch(success(response.data))
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_DASHBOARD_MIDDLE_DATA_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_DASHBOARD_MIDDLE_DATA_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_DASHBOARD_MIDDLE_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_DASHBOARD_MIDDLE_DATA_RESET, clear_data: "" };
    }
}


const GetDashbardBottompData = (actionPayload: any, actionPayload2: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let dataSummary: any = {};
            let apiConsume = ApiService.request({ url: `${routes.ORDER_GRAPH_SUMMARY}?${actionPayload2}`, http_method: "GET" });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    // if (response?.data) {


                    // dispatch(success(response.data))
                    // }
                    
                    let apiConsume2 = ApiService.request({ url: `${routes.ORDER_ITEM_REPORT}?${actionPayload}`, http_method: "GET" });
                    dispatch(request(apiConsume2));
                    return apiConsume2
                        .then((response2: any) => {
                            // if (response?.data) {
                            
                            dataSummary.graph = response.data;
                            dataSummary.itemsSummary = response2.data;

                            dispatch(success(dataSummary))
                            // }
                        })
                        .catch((error: any) => {
                            dispatch(failure(handleRequestErrors(error)));
                        });
                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_DATA_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_DATA_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_DATA_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.GET_DASHBOARD_BOTTOM_DATA_RESET, clear_data: "" };
    }
}


const CreateTokenizeData = (actionPayload: any, type?: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlOption: any = "";
            if (type === "card") {
                urlOption = routes.TOKEN_CARD_DATA
            }
            if (type === "bank") {
                urlOption = routes.TOKEN_BANK_DATA
            }


            let apiConsume = ApiService.request({ url: urlOption, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    dispatch(success(response.data))




                })
                .catch((error: any) => {

                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.CREATE_PAYOUT_TOKEN_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.CREATE_PAYOUT_TOKEN_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.CREATE_PAYOUT_TOKEN_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.CREATE_PAYOUT_TOKEN_RESET, clear_data: "" };
    }
}

const ChangeOrderStatus = (actionPayload: any, type?: any, callback?: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlOption: any = "", http_method="";
            if (type === "is_ready") {
                urlOption = routes.SET_ORDER__IS_READY
                http_method= "PUT"
            }
            if (type === "is_picked") {
                urlOption = routes.SET_ORDER__IS_PICKED_UP
                http_method= "PUT"
            }
            if (type === "is_confirmed") {
                urlOption = routes.SET_ORDER__IS_CONFIRMED
                http_method= "POST"
            }
            


            let apiConsume = ApiService.request({ url: urlOption, http_method, data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    callback()
                    dispatch(success(response.data))




                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.CHANGE_ORDER_STATUS_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.CHANGE_ORDER_STATUS_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.CHANGE_ORDER_STATUS_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.CHANGE_ORDER_STATUS_RESET, clear_data: "" };
    }
}


const UploadBulkProduct = (actionPayload: any) => {

    if (actionPayload !== "CLEAR") {



        return (dispatch: Dispatch) => {
            let urlOption = routes.UPLOAD_BULK_PRODUCT;



            let apiConsume = ApiService.request({ url: urlOption, http_method: "POST", data: actionPayload });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {

                    dispatch(success(response.data))




                })
                .catch((error: any) => {

                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.UPLOAD_MULTIPLE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.UPLOAD_MULTIPLE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.UPLOAD_MULTIPLE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.UPLOAD_MULTIPLE_RESET, clear_data: "" };
    }
}

const AddNewStore = (actionPayload: any, storeLogo: any) => {

    if (actionPayload !== "CLEAR") {





        return (dispatch: Dispatch) => {
            let apiConsume = ApiService.request({ url: routes.LOGO_IMAGE_UPLOAD, http_method: "POST", data: storeLogo });
            dispatch(request(apiConsume));
            return apiConsume
                .then((response: any) => {
                    
                    let mainData: any = { ...actionPayload }
                    if (response?.data) {
                        mainData.logoUrl = response.data.fileURL
                        let apiConsume2 = ApiService.request({ url: routes.ADD_A_STORE, http_method: "POST", data: mainData });
                        dispatch(request(apiConsume2));
                        return apiConsume2
                            .then((response2: any) => {

                                dispatch(success(response2.data))




                            })
                            .catch((error: any) => {
                                dispatch(failure(handleRequestErrors(error)));
                            });
                    }

                })
                .catch((error: any) => {
                    dispatch(failure(handleRequestErrors(error)));
                });
        };
    }

    return (dispatch: Dispatch) => {
        dispatch(clear());
    };

    function request(params: any) {
        return { type: appConstants.ADD_A_NEW_STORE_PENDING, params };
    }
    function success(response: any) {
        return { type: appConstants.ADD_A_NEW_STORE_SUCCESS, response };
    }
    function failure(error: any) {
        return { type: appConstants.ADD_A_NEW_STORE_FAILURE, error };
    }
    function clear() {
        return { type: appConstants.ADD_A_NEW_STORE_RESET, clear_data: "" };
    }
}



export const storeActions = {
    CreateStoreProduct,
    UploadBulkProduct,
    GetProductMetaData,
    GetImageGallery,
    GetMyProductList,
    ToggleAProduct,
    GetAProduct,
    UpdateStoreProduct,
    UploadProductImage,
    GetStoreOrders,
    GetStoreOrderDetails,
    CreatePayoutAccount,
    DeactivatePayoutAccount,
    GetStorePaymentAccount,
    GetStoreDetails,
    UpdateStoreDetails,
    GetStorePaymentsHistory,

    CreateTokenizeData,
    GetPayoutPendingLink,
    GetDashbardTopData,
    GetDashbardMiddleData,
    GetDashbardBottompData,

    ChangeOrderStatus,
    AddNewStore
};