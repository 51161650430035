import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2'
import * as Yup from 'yup';
// import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";
import Logo from "../../../assets/logo2.png"
import Shop from "../../../assets/icons/shop.png"
import { AlertMsg } from "../../../shared/components/alert-msg"
import { LoadingItem } from "../../../shared/components/loading"
import { MultiSelect } from "react-multi-select-component";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';


import { authActions } from "../../../redux/actions/onboarding"
import { appConstants } from "../../../redux/action-constants/inapp.constants"

const getStoreUrlAction = async ({ pageProps, action }: any) => {

    await pageProps.getStoreUrlAction(action);
}

const getStoreCatsAction = async ({ pageProps, action }: any) => {

    await pageProps.getStoreCategoriesAction(action);
}

const registerVendorAction = async ({ pageProps, action, storeLogo }: any) => {

    await pageProps.createAccountAction(action, storeLogo);
}
const dataURLtoFile = (dataurl:any, filename:any)=> {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
const CreatAccountForm = ({ catListData, pageProps, defaultLogo }: any) => {

    const [selectedUrl, setSelectedUrl] = useState<any>()


    const [extractedInfo, setExtractedInfo] = useState<any>();
    const [addressSearched, setAddressSearched] = useState<any>();
    const [addressLatLng, setAddressLatLng] = useState<any>();
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [storeLogo, setStoreLogo] = useState<any>({ image: Shop });

    // const [storeLogo, setStoreLogo] = useState<any>({ image: defaultLogo?.fileURL });
    const [isStoreLogoNew, setStoreLogoNew] = useState(false);
    const [storeLogoImage, setStoreLogoImage] = useState<any>();
    const [storeFormInitValues, setStoreFormInitValues] = useState<any>({
        password: "",
        confirmPassword: "",
        storeUrl: "",
        storeName: "",
        phoneNumber: "",
        storeEmail: "",
        ownerTitle: "",
        storeProvince: "",
        firstName: "",
        lastName: "",
        ownerPhone: "",
        ownerCity: "",
        ownerAddress: "",
        ownerPostCode: "",
        howHear: "",
        storeCats: "",
        storeAddress: "",
        agreed: false
    });

    const [ownerAddressSearched, setOwnerAddressSearched] = useState<any>();
    const [ownerExtractedInfo, setOwnerExtractedInfo] = useState<any>();
    const [ownerAddress, setOwnerAddress] = useState<any>(null);
    const [ownerCity, setOwnerCity] = useState<any>(null);

    let extractedData: any = {};
    let checkValidationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required('Required').when("password", {
            is: (val: string | any[]) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
            ),
        }),
        storeName: Yup.string()
            .required('Required'),
        storeAddress: Yup.string()
            .required('Required'),
        storeUrl: Yup.string()
            .required('Required'),
        phoneNumber: Yup.string()
            .required('Required'),
        ownerAddress: Yup.string()
            .required('Required'),
        storeEmail: Yup.string()
            .email('valid email is required')
            .required('Required'),
        storePostCode: Yup.string(),
        // .required('Required'),
        storeCats: Yup.string()
            .required('Required'),
        firstName: Yup.string()
            .required('Required'),
        lastName: Yup.string()
            .required('Required'),
        ownerPhone: Yup.string()
            .required('Required'),
    });
    let initialValues: any = {
        password: "",
        confirmPassword: "",
        storeUrl: "",
        storeName: "",
        phoneNumber: "",
        storeEmail: "",
        ownerTitle: "",
        storeProvince: "",
        firstName: "",
        lastName: "",
        ownerPhone: "",
        ownerCity: "",
        ownerAddress: "",
        ownerPostCode: "",
        howHear: "",
        agreed: false
    }

    useEffect(() => {
        if (extractedInfo) {
            // console.log("extractedInfo", extractedInfo)
            // setPostalCodeAreaError("")
            let tempStoreFormInitValues = { ...storeFormInitValues }
            tempStoreFormInitValues.storeProvince = extractedInfo?.provinceChosenFull
            tempStoreFormInitValues.storePostCode = extractedInfo?.postCode
            setStoreFormInitValues(tempStoreFormInitValues)

        }

    }, [extractedInfo])

    useEffect(() => {
        if (ownerExtractedInfo) {
            // console.log("ownerExtractedInfo", ownerExtractedInfo)
            // setPostalCodeAreaError("")
            let tempStoreFormInitValues = { ...storeFormInitValues }
            tempStoreFormInitValues.ownerCity = ownerExtractedInfo?.provinceChosenFull
            tempStoreFormInitValues.ownerPostCode = ownerExtractedInfo?.postCode
            setStoreFormInitValues(tempStoreFormInitValues)

        }

    }, [ownerExtractedInfo])

    const handleLogoUpload = (e: any) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {

            setStoreLogo({ file, image: reader.result })
            setStoreLogoNew(true)
        }

        reader.readAsDataURL(file)
    }

    const handleSelectedAddress = async (address: any) => {

        setAddressSearched(address)

        // console.log("addresses 2", address)
        // getLatLng

        const [place] = await geocodeByPlaceId(address?.value.place_id);
        let addressMeta = place?.address_components;

        // console.log("placeMeta", place)

        geocodeByAddress(address?.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }: any) => {
                // console.log("addressLat", lat)
                // console.log("addressLng", lng)
                setAddressLatLng({ lat, lng })
            }
            );


        let extractedInfoFromAddress = () => {
            let streetNumber: any = '',
                streetName: any = '',
                areaName: any = '',
                townName: any = '',
                postalCodeArea: any = '',
                fullAddress: any,
                topLevelArea: any,
                provinceChosenFull: any,
                provinceChosen: any;

            for (let componentIndex = 0; componentIndex < addressMeta.length; componentIndex++) {

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "postal_code" && !extractedData?.postCode
                ) {
                    extractedData.postCode = addressMeta[componentIndex]?.long_name;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "street_number" && streetNumber === ""
                ) {

                    streetNumber = `${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "route" && streetName === ""
                ) {
                    streetName = `${streetNumber !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 1
                    && (
                        addressMeta[componentIndex]?.types.includes("neighborhood") ||
                        addressMeta[componentIndex]?.types.includes("locality")
                    )
                    && (areaName.split(",").length - 1) < 1

                    // && areaName === ""
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                // console.log("postalCodeArea.length",postalCodeArea.length)
                // console.log("administrative_area_level_2",addressMeta[componentIndex]?.types.includes("administrative_area_level_2"))
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5") ||
                        addressMeta[componentIndex]?.types.includes("locality") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")


                    )
                    // && postalCodeArea.length === 0
                    // && areaName === ""
                ) {
                    postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )
                    && ((postalCodeArea.split(",").length - 1) < 1)

                ) {
                    postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name}`;
                    topLevelArea = addressMeta[componentIndex]?.long_name
                }
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )


                ) {
                    provinceChosen = addressMeta[componentIndex]?.short_name
                    provinceChosenFull = addressMeta[componentIndex]?.long_name
                }


                // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
                fullAddress = `${streetNumber} ${streetName} ${areaName}`;
                extractedData.newAddress = fullAddress;
                extractedData.postalCodeArea = postalCodeArea;
                extractedData.topLevelArea = topLevelArea;
                extractedData.provinceChosen = provinceChosen;
                extractedData.provinceChosenFull = provinceChosenFull;


                if (extractedData?.postCode && extractedData?.newAddress
                    && extractedData?.provinceChosen && extractedData?.provinceChosenFull) {
                    break;
                }

            }

            // console.log("data is ", extractedData)
            return extractedData
        }
        setExtractedInfo(extractedInfoFromAddress())



    }

    const handleSelectedOwnerAddress = async (address: any) => {

        setOwnerAddress(address)


        // getLatLng

        const [place] = await geocodeByPlaceId(address?.value.place_id);
        let addressMeta = place?.address_components;





        let extractedInfoFromAddress = () => {
            let streetNumber: any = '',
                streetName: any = '',
                areaName: any = '',
                townName: any = '',
                postalCodeArea: any = '',
                fullAddress: any,
                topLevelArea: any,
                provinceChosenFull: any,
                provinceChosen: any;

            for (let componentIndex = 0; componentIndex < addressMeta.length; componentIndex++) {

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "postal_code" && !extractedData?.postCode
                ) {
                    extractedData.postCode = addressMeta[componentIndex]?.long_name;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "street_number" && streetNumber === ""
                ) {

                    streetNumber = `${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "route" && streetName === ""
                ) {
                    streetName = `${streetNumber !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 1
                    && (
                        addressMeta[componentIndex]?.types.includes("neighborhood") ||
                        addressMeta[componentIndex]?.types.includes("locality")
                    )
                    && (areaName.split(",").length - 1) < 1

                    // && areaName === ""
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                // console.log("postalCodeArea.length",postalCodeArea.length)
                // console.log("administrative_area_level_2",addressMeta[componentIndex]?.types.includes("administrative_area_level_2"))
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5") ||
                        addressMeta[componentIndex]?.types.includes("locality") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")


                    )
                    // && postalCodeArea.length === 0
                    // && areaName === ""
                ) {
                    postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )
                    && ((postalCodeArea.split(",").length - 1) < 1)

                ) {
                    postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name}`;
                    topLevelArea = addressMeta[componentIndex]?.long_name
                }
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )


                ) {
                    provinceChosen = addressMeta[componentIndex]?.short_name
                    provinceChosenFull = addressMeta[componentIndex]?.long_name
                }


                // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
                fullAddress = `${streetNumber} ${streetName} ${areaName}`;
                extractedData.newAddress = fullAddress;
                extractedData.postalCodeArea = postalCodeArea;
                extractedData.topLevelArea = topLevelArea;
                extractedData.provinceChosen = provinceChosen;
                extractedData.provinceChosenFull = provinceChosenFull;


                if (extractedData?.postCode && extractedData?.newAddress
                    && extractedData?.provinceChosen && extractedData?.provinceChosenFull) {
                    break;
                }

            }

            // console.log("data is ", extractedData)
            return extractedData
        }
        setOwnerExtractedInfo(extractedInfoFromAddress())



    }

    let allCats = pageProps.getStoreCatsRequest?.request_data?.response?.results.map((eachCat: any) => {
        return {
            label: eachCat?.name,
            value: eachCat?.name,
        }
    })
    // setCatsList(allCats)

    return (
        <>
            <div className="content-heading">
                <h2>Create a seller account</h2>
            </div>
            <div className="form-wrap">
                <Formik
                    // initialValues={storeFormInitValues}
                    initialValues={initialValues}

                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        if (storeLogo) {
                            let { storeEmail, password, storeName,
                                storeUrl, phoneNumber, storeProvince,
                                storePostCode, firstName,
                                lastName, ownerPhone,
                                ownerPostCode, howHear } = values;
                            let payload = {
                                email: storeEmail,
                                password,
                                storeModel: {
                                    name: storeName,
                                    url: storeUrl,
                                    phoneNumber,
                                    address: {
                                        city: extractedInfo?.provinceChosenFull,
                                        postalCode: storePostCode || extractedInfo?.postCode,
                                        streetAddress: extractedInfo?.newAddress,
                                        country: "Canada",
                                        countryId: 0,
                                    },
                                    tags: selectedCategories.map((eachCat: any) => eachCat?.label),
                                    email: storeEmail,
                                    longitude: addressLatLng.lng,
                                    latitude: addressLatLng.lat,
                                },
                                userProfileModel: {
                                    firstName, lastName,
                                    phoneNumber: ownerPhone,
                                    postalCode: ownerPostCode || ownerExtractedInfo?.postCode,
                                    streetAddress: ownerExtractedInfo?.newAddress,
                                    city: ownerExtractedInfo?.provinceChosenFull,
                                    country: "Canada",
                                    howDidYouHearAboutUs: howHear
                                }
                            }
                            // console.log("test is", payload)
                            // console.log("storeLogo is", storeLogo)
                            const logoFormData = new FormData();
                            if(!storeLogo?.file){
                                let logdataURLtoFile = dataURLtoFile(storeLogo?.image, `${storeName}-logo.png`)
                                logoFormData.append("logo", logdataURLtoFile);
                            }else{
                                logoFormData.append("logo", storeLogo?.file);
                            }
                            
                            await registerVendorAction({ pageProps, action: payload, storeLogo: logoFormData })
                        }
                        // await handleLogin({ values: payload, pageProps })
                        // history.replace("/account/verification")
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            setFieldTouched,
                            setFieldValue,
                            setFieldError,
                            handleBlur,
                            handleChange,
                            // isSubmitting,
                        } = props;

                        return (
                            <Form>
                                {/* <PersistFormikValues name="signin-form" /> */}
                                <div className="logo-image">
                                    <div className="logo-icon">
                                        <img src={storeLogo?.image} alt="" />
                                        <label htmlFor="vendor-logo">{isStoreLogoNew ? "change logo" : "upload logo"} </label>
                                        <input accept="image/*" onChange={(e: any) => handleLogoUpload(e)} type="file" name="" id="vendor-logo" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className={errors.storeName && touched.storeName ? "invalid-input-wrap" : ""}>
                                        <Field
                                            id="storeName"
                                            value={values.storeName}
                                            name="storeName"
                                            type="text"
                                            placeholder="Store name"
                                            onChange={(e: any) => {
                                                setFieldTouched("storeName", true)
                                                setFieldValue("storeName", e.target.value)
                                            }}
                                            onBlur={(e: any) => {
                                                setSelectedUrl("")
                                                getStoreUrlAction({ pageProps, action: values.storeName })

                                            }}
                                            className={`input-wrap ${errors.storeName && touched.storeName ?
                                                "is-invalid-input "
                                                : values.storeName !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="storeName" className="form-input-error" component="div" />
                                </div>
                                {/* <pre>{JSON.stringify(errors)}</pre> */}
                                <div className="form-group">
                                    <div className="input-with-divider with-url">
                                        <div className="divider store-url">
                                            <div className="label-text">Store URL</div>
                                            {/* <div className="prefix-url">mamimarkets.com/</div> */}
                                        </div>
                                        <div>
                                            <Field
                                                id="storeUrl"
                                                value={pageProps.getUrlRequest.request_status === "GET_URL_OPTIONS_PENDING" ? "Please wait..." : selectedUrl}
                                                // value={values.storeUrl}
                                                name="storeUrl"
                                                type="text"
                                                placeholder=""
                                                onChange={() => { setFieldValue("storeUrl", "") }}
                                                className={`input-wrap valid-input url-input`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {pageProps.getUrlRequest.request_status === "GET_URL_OPTIONS_SUCCESS" &&
                                    <div className="url_options">
                                        <div className="url_header">Choose your preferred store url</div>
                                        {
                                            pageProps.getUrlRequest?.request_data?.response.map((eachItem: any, index: any) => {
                                                return (
                                                    <div className="each-options" key={index}>
                                                        {/* <span className={`custom_radio`}> */}
                                                        <span className={`custom_radio ${selectedUrl === eachItem ? "selected_radio" : ""} `}>
                                                            <input
                                                                onChange={(e) => {
                                                                    setFieldValue("storeUrl", e.target.value)
                                                                    setSelectedUrl(e.target.value)
                                                                }}
                                                                name="chosen_url"
                                                                type="radio" id={eachItem} value={eachItem} />
                                                        </span>
                                                        <label htmlFor={eachItem}>{eachItem}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {/* <div className="url_options">

                                        <div className="each-options">
                                            <span className={`custom_radio selected_radio`}>
                                                <input name="chosen_url" type="radio" id="chosen_url" value="" />
                                            </span>
                                            <label htmlFor="">Url 1</label>
                                        </div>
                                        <div className="each-options">
                                            <span className={`custom_radio selected_radio`}>
                                                <input name="chosen_url" type="radio" id="chosen_url" value="" />
                                            </span>
                                            <label htmlFor="">Url 2</label>
                                        </div>
                                    </div> */}
                                <div className="form-group">
                                    {/* <div className={errors.storeAddress && touched.storeAddress ? "invalid-input-wrap address-wrap" : "address-wrap"}> */}
                                    {/* <pre>{JSON.stringify(selectedCategories)}</pre> */}
                                    <div className="all-cats-selected">
                                        {
                                            selectedCategories.map((eachCat: any, index: any) => {
                                                return (
                                                    <div className="cat-name" key={index}>
                                                        {eachCat?.label}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <MultiSelect
                                        hasSelectAll={false}
                                        options={allCats}
                                        value={selectedCategories}
                                        onChange={(selectedCats: any) => {
                                            setSelectedCategories(selectedCats)
                                            setFieldTouched("storeCats", true)
                                            setFieldValue("storeCats", "set")
                                            setFieldError("storeCats", "")
                                        }}
                                        labelledBy="Select"
                                        className={`biz-cat ${errors.storeCats ? "invalid-input-wrap" : ""}`}
                                        overrideStrings={{
                                            "search": "Search Categories",
                                            "selectSomeItems": "Choose Categories",
                                            "allItemsAreSelected": "All Categories are chosen.",
                                            "noOptions": "No categories match",
                                        }}
                                    />
                                    {errors.storeCats &&
                                        <div className="form-input-error">Required</div>
                                    }
                                    {/* </div> */}
                                </div>
                                <div className="form-group">
                                    <div className={errors.storeAddress ? "invalid-input-wrap address-wrap" : "address-wrap"}>
                                        {/* <Field
                                                id="storeAddress"
                                                value={values.storeAddress}
                                                name="storeAddress"
                                                type="text"
                                                placeholder="Store Address"
                                                onChange={handleChange}
                                                className={`input-wrap ${errors.storeAddress && touched.storeAddress ?
                                                    "is-invalid-input "
                                                    : values.storeAddress !== "" ? "valid-input" : ""}`}
                                            /> */}
                                        <GooglePlacesAutocomplete
                                            selectProps={{
                                                value: addressSearched,
                                                // value: storeAddress,
                                                onChange: (address) => {
                                                    handleSelectedAddress(address)
                                                    setFieldValue("storeAddress", "set")
                                                    // setFieldValue("storePostCode", " ")
                                                    // setFieldTouched("storeAddress", true)
                                                    setFieldError("storeAddress", "")
                                                    // setFieldError("storePostCode", "")
                                                },
                                                // onChange: setStoreAddress,
                                                placeholder: "Store address",
                                                // autoFocus: true,
                                                classNamePrefix: "store-address-select",
                                                openMenuOnClick: false,
                                                className: "",
                                                noOptionsMessage: () => 'Type a valid address'
                                            }}
                                            autocompletionRequest={{
                                                componentRestrictions: {
                                                    country: ['ca'],
                                                }
                                            }}
                                            apiKey={appConstants.GOOGLE_MAP_KEY}
                                        />
                                    </div>
                                    {errors.storeAddress &&
                                        <div className="form-input-error">Required</div>
                                    }
                                    {/* <ErrorMessage name="storeAddress" className="form-input-error" component="div" /> */}
                                </div>
                                <div className="form-group">
                                    <div className="input-with-divider">
                                        <div className="divider normal">
                                            <div className="label-text">Postal Code</div>
                                            {/* <div className="other-txt">94105</div> */}
                                        </div>
                                        <div>
                                            <Field
                                                id="storePostCode"
                                                value={values.storePostCode || storeFormInitValues?.storePostCode}
                                                name="storePostCode"
                                                type="text"
                                                placeholder=""
                                                onChange={handleChange}
                                                className={`input-wrap valid-input`}
                                            />
                                        </div>
                                    </div>
                                    <ErrorMessage name="storePostCode" className="form-input-error" component="div" />
                                </div>

                                <div className="form-group">
                                    <div className={errors.storeProvince ? "invalid-input-wrap" : ""}>
                                        <Field
                                            id="storeProvince"
                                            value={values.storeProvince || storeFormInitValues?.storeProvince}
                                            name="storeProvince"
                                            type="text"
                                            placeholder="Store province"
                                            onChange={(e: any) => {
                                                // setFieldTouched("storeProvince", true)
                                                // setFieldValue("storeProvince", e.target.value)
                                            }}
                                            disabled={true}

                                            className={`input-wrap ${errors.storeProvince && touched.storeProvince ?
                                                "is-invalid-input "
                                                : values.storeProvince !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="storeProvince" className="form-input-error" component="div" />
                                </div>
                                <div className="form-group">
                                    <div className={errors.phoneNumber && touched.phoneNumber ? "invalid-input-wrap" : ""}>
                                        {/* <Field
                                                id="phoneNumber"
                                                value={values.phoneNumber}
                                                name="phoneNumber"
                                                type="text"
                                                placeholder="Store phone number"
                                                onChange={handleChange}
                                                className={`input-wrap ${errors.phoneNumber && touched.phoneNumber ?
                                                    "is-invalid-input "
                                                    : values.phoneNumber !== "" ? "valid-input" : ""}`}
                                            /> */}
                                        <PhoneInput
                                            country={'ca'}
                                            enableTerritories={false}
                                            enableSearch={true}
                                            disableSearchIcon={true}
                                            inputClass={`input-wrap ${errors.phoneNumber && touched.phoneNumber ?
                                                "is-invalid-input "
                                                : values.phoneNumber !== "" ? "valid-input" : ""}`}
                                            value={values.phoneNumber}
                                            onChange={(phone: string): void => {

                                                setFieldTouched("phoneNumber", true)
                                                setFieldValue("phoneNumber", phone)
                                            }}
                                        />
                                    </div>
                                    <ErrorMessage name="phoneNumber" className="form-input-error" component="div" />
                                </div>
                                {/* <div className="form-group">
                                            <div className={errors.storeProvince && touched.storeProvince ? "invalid-input-wrap" : ""}>
                                                <Field
                                                    value={values.storeProvince}
                                                    name="storeProvince"
                                                    as="select"
                                                    onChange={handleChange}
                                                    placeholder="Select Province"
                                                    className={`input-wrap ${errors.storeProvince && touched.storeProvince ?
                                                        "is-invalid-input "
                                                        : !errors.storeProvince && values.storeProvince !== "" ? "valid-input" : ""}`}
                                                >
                                                    <option value="">Select Province</option>
                                                    <option value="Alberta">Alberta</option>
                                                    <option value="British Columbia">British Columbia</option>
                                                    <option value="Manitoba">Manitoba</option>
                                                    <option value="New Brunswick">New Brunswick</option>
                                                    <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                                    <option value="Nova Scotia">Nova Scotia</option>
                                                    <option value="Ontario">Ontario</option>
                                                    <option value="Prince Edward Island">Prince Edward Island</option>
                                                    <option value="Quebec">Quebec</option>
                                                    <option value="Saskatchewan">Saskatchewan</option>
                                                </Field>
                                            </div>
                                            <ErrorMessage name="storeProvince" className="form-input-error" component="div" />
                                        </div> */}
                                <div className="form-group">
                                    <div className={errors.storeEmail && touched.storeEmail ? "invalid-input-wrap" : ""}>
                                        <Field
                                            id="storeEmail"
                                            value={values.storeEmail}
                                            name="storeEmail"
                                            type="text"
                                            placeholder="Email"
                                            onChange={handleChange}
                                            className={`input-wrap ${errors.storeEmail && touched.storeEmail ?
                                                "is-invalid-input "
                                                : values.storeEmail !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="storeEmail" className="form-input-error" component="div" />
                                </div>
                                <div className="form-group">
                                    <div className={errors.password && touched.password ? "invalid-input-wrap" : ""}>
                                        <Field
                                            id="password"
                                            value={values.password}
                                            name="password"
                                            type="password"
                                            placeholder="Create Password"
                                            onChange={handleChange}
                                            className={`input-wrap ${errors.password && touched.password ?
                                                "is-invalid-input "
                                                : values.password !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="password" className="form-input-error" component="div" />
                                </div>
                                <div className="form-group">
                                    <div className={errors.confirmPassword && touched.confirmPassword ? "invalid-input-wrap" : ""}>
                                        <Field
                                            id="confirmPassword"
                                            value={values.confirmPassword}
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirm Password"
                                            onChange={handleChange}
                                            className={`input-wrap ${errors.confirmPassword && touched.confirmPassword ?
                                                "is-invalid-input "
                                                : values.confirmPassword !== "" ? "valid-input" : ""}`}
                                        />
                                    </div>
                                    <ErrorMessage name="confirmPassword" className="form-input-error" component="div" />
                                </div>
                                <div className="owner-info">
                                    <div className="owner-heading">Personal Details</div>
                                    <div className="form-group two-sided">
                                        <div>
                                            <div className={((errors.ownerTitle && touched.ownerTitle) || (errors.firstName && touched.firstName))
                                                ? "input-with-divider with-content with-content-error " : "input-with-divider with-content"}>
                                                <div className="divider">
                                                    <div className={errors.ownerTitle && touched.ownerTitle ? "invalid-input-wrap" : ""}>
                                                        <Field
                                                            value={values.ownerTitle}
                                                            name="ownerTitle"
                                                            as="select"
                                                            onChange={handleChange}
                                                            placeholder=""
                                                            className={`form-control inapp-input select-input ${errors.ownerTitle && touched.ownerTitle ?
                                                                "is-invalid-input "
                                                                : !errors.ownerTitle && values.ownerTitle !== "" ? "valid-input" : ""}`}
                                                        >
                                                            <option value="">Title</option>
                                                            <option value="Mrs">Mrs</option>
                                                            <option value="Ms.">Ms.</option>
                                                            <option value="Mr">Mr</option>
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="input-content">
                                                    <Field
                                                        id="firstName"
                                                        value={values.firstName}
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First name"
                                                        onChange={handleChange}
                                                        className={`input-wrap ${errors.firstName && touched.firstName ?
                                                            "is-invalid-input "
                                                            : values.firstName !== "" ? "valid-input" : ""}`}
                                                    />

                                                </div>
                                            </div>
                                            <ErrorMessage name="firstName" className="form-input-error" component="div" />
                                            <ErrorMessage name="ownerTitle" className="form-input-error" component="div" />
                                        </div>
                                        <div>
                                            <Field
                                                id="lastName"
                                                value={values.lastName}
                                                name="lastName"
                                                type="text"
                                                placeholder="Last name"
                                                onChange={handleChange}
                                                className={`input-wrap ${errors.lastName && touched.lastName ?
                                                    "is-invalid-input "
                                                    : values.lastName !== "" ? "valid-input" : ""}`}
                                            />
                                            <ErrorMessage name="lastName" className="form-input-error" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className={errors.ownerPhone && touched.ownerPhone ? "invalid-input-wrap" : ""}>
                                            {/* <Field
                                                    id="ownerPhone"
                                                    value={values.ownerPhone}
                                                    name="ownerPhone"
                                                    type="text"
                                                    placeholder="Phone number"
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.ownerPhone && touched.ownerPhone ?
                                                        "is-invalid-input "
                                                        : values.ownerPhone !== "" ? "valid-input" : ""}`}
                                                /> */}
                                            <PhoneInput
                                                country={'ca'}
                                                enableTerritories={false}
                                                enableSearch={true}
                                                disableSearchIcon={true}
                                                inputClass={`input-wrap ${errors.ownerPhone && touched.ownerPhone ?
                                                    "is-invalid-input "
                                                    : values.ownerPhone !== "" ? "valid-input" : ""}`}
                                                value={values.ownerPhone}
                                                onChange={(phone: string): void => {

                                                    setFieldTouched("ownerPhone", true)
                                                    setFieldValue("ownerPhone", phone)
                                                }}
                                            />
                                        </div>
                                        <ErrorMessage name="ownerPhone" className="form-input-error" component="div" />
                                    </div>
                                    {/* <div className="form-group">
                                            <div className={errors.ownerCountry && touched.ownerCountry ? "invalid-input-wrap" : ""}>
                                                <Field
                                                    value={values.ownerCountry}
                                                    name="ownerCountry"
                                                    as="select"
                                                    onChange={handleChange}
                                                    placeholder="How did you hear about us"
                                                    className={`input-wrap ${errors.ownerCountry && touched.ownerCountry ?
                                                        "is-invalid-input "
                                                        : !errors.ownerCountry && values.ownerCountry !== "" ? "valid-input" : ""}`}
                                                >
                                                    <option value="">Country</option>
                                                    <option value="">Canada</option>
                                                    
                                                </Field>
                                            </div>
                                        
                                            <ErrorMessage name="ownerCountry" className="form-input-error" component="div" />
                                        </div> */}
                                    <div className="form-group">
                                        <div className={errors.ownerAddress ? "invalid-input-wrap address-wrap" : "address-wrap"}>
                                            {/* <Field
                                                    id="ownerAddress"
                                                    value={values.ownerAddress}
                                                    name="ownerAddress"
                                                    type="text"
                                                    placeholder="Address"
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.ownerAddress && touched.ownerAddress ?
                                                        "is-invalid-input "
                                                        : values.ownerAddress !== "" ? "valid-input" : ""}`}
                                                /> */}
                                            <GooglePlacesAutocomplete
                                                selectProps={{
                                                    value: ownerAddressSearched,
                                                    // onChange: handleSelectedOwnerAddress,
                                                    onChange: (address) => {
                                                        handleSelectedOwnerAddress(address)
                                                        setFieldValue("ownerAddress", "set")
                                                    },
                                                    placeholder: "Store owner address",
                                                    openMenuOnClick: false,
                                                    classNamePrefix: "store-address-select",
                                                    noOptionsMessage: () => 'Type a valid address'
                                                }}

                                                autocompletionRequest={{
                                                    componentRestrictions: {
                                                        country: ['ca'],
                                                    }
                                                }}
                                                apiKey={appConstants.GOOGLE_MAP_KEY}
                                            />
                                        </div>
                                        {errors.ownerAddress &&
                                            <div className="form-input-error">Required</div>
                                        }
                                        {/* <ErrorMessage name="ownerAddress" className="form-input-error" component="div" /> */}
                                    </div>
                                    <div className="form-group">
                                        <div className="input-with-divider">
                                            <div className="divider normal">
                                                <div className="label-text">Postal Code</div>
                                                {/* <div className="other-txt">94105</div> */}
                                            </div>
                                            <div>
                                                <Field
                                                    id="ownerPostCode"
                                                    value={values.ownerPostCode || storeFormInitValues?.ownerPostCode}
                                                    name="ownerPostCode"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    className={`input-wrap valid-input`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className={errors.ownerCity && touched.ownerCity ? "invalid-input-wrap" : ""}>
                                            <Field
                                                id="ownerCity"
                                                value={values.ownerCity || storeFormInitValues?.ownerCity}
                                                name="ownerCity"
                                                type="text"
                                                placeholder="Store Owner City"
                                                onChange={(e: any) => {
                                                    // setFieldTouched("storeProvince", true)
                                                    // setFieldValue("storeProvince", e.target.value)
                                                }}
                                                disabled={true}

                                                className={`input-wrap ${errors.ownerCity && touched.ownerCity ?
                                                    "is-invalid-input "
                                                    : values.ownerCity !== "" ? "valid-input" : ""}`}
                                            />
                                        </div>
                                        <ErrorMessage name="ownerCity" className="form-input-error" component="div" />
                                    </div>



                                    <div className="form-group">
                                        <div className={errors.howHear && touched.howHear ? "invalid-input-wrap" : ""}>
                                            <Field
                                                value={values.howHear}
                                                name="howHear"
                                                as="select"
                                                onChange={handleChange}
                                                placeholder="How did you hear about us"
                                                className={`input-wrap ${errors.howHear && touched.howHear ?
                                                    "is-invalid-input "
                                                    : !errors.howHear && values.howHear !== "" ? "valid-input" : ""}`}
                                            >
                                                <option value="">How did you hear about us</option>
                                                <option value="Google Search">Google Search</option>
                                                <option value="Social Media">Social Media</option>
                                                <option value="Friend">Friend</option>
                                                <option value="YouTube">YouTube</option>
                                                <option value="Others">Others</option>
                                            </Field>
                                        </div>
                                        {
                                            values.howHear === "Others" &&

                                            <div className="others-wrap">
                                                <Field
                                                    id="ownerPostCode"
                                                    value={values.howHear === "Others" ? "" : values.howHear}
                                                    name="ownerPostCode"
                                                    type="text"
                                                    placeholder="Enter Others.."
                                                    onChange={handleChange}
                                                    className={`input-wrap valid-input`}
                                                />
                                            </div>
                                        }
                                        <ErrorMessage name="howHear" className="form-input-error" component="div" />
                                    </div>
                                </div>
                                {pageProps?.creatUserRequest?.request_status
                                    && pageProps.creatUserRequest.request_status === "CREATE_ACCOUNT_FAILURE" &&
                                    <AlertMsg type="error" message={pageProps.creatUserRequest?.request_data.error} />
                                }
                                {/* {(emailLoginRequest?.status === "error" && !isLoading) &&
                                                <AlertMsg type="error" message={emailLoginRequest?.error} />
                                            } */}
                                <div className="agree-cta">
                                    <div className="agree-terms">
                                        <input type="checkbox" name="agreed" value={values.agreed} onChange={handleChange} id="agree-txt" />
                                        <label htmlFor="agree-txt">Accept Terms and Condition</label>
                                    </div>
                                    <button
                                        className="main-cta "
                                        //         className={`btn form-btn full-width 
                                        // ${((Object.keys(errors).length === 0 && Object.keys(touched).length >= 1)
                                        //                 || pageProps.loginRequest?.is_request_processing
                                        //             )
                                        //                 ? " btn-main " : "btn-frozen"}`
                                        //         }
                                        disabled={values.agreed ? pageProps.creatUserRequest?.is_request_processing : true}
                                        type="submit">

                                        {pageProps.creatUserRequest.is_request_processing ? "Please wait..." : "Sign up"}
                                    </button>
                                </div>

                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <div className="optional">
                Already have an account? <br />
                <Link to="/"> Sign in</Link>
            </div>
        </>
    )
}

const SignUpContent = ({ pageProps }: any) => {
    let requestResults = pageProps.getUrlRequest;

    let defaultLogo = {
        fileURL: "https://maamimarketstorage.blob.core.windows.net/upload-image/-ed329374-20a3-493c-bc2c-57ab2386094a.png",
        thumbnail: "https://maamimarketstorage.blob.core.windows.net/upload-image/-ed329374-20a3-493c-bc2c-57ab2386094a-thumbnail.png"
    }

    useEffect(() => {
        let catsPayload = `Page=1&Size=1000`
        getStoreUrlAction({ pageProps, action: "CLEAR" })
        registerVendorAction({ pageProps, action: "CLEAR" })
        getStoreCatsAction({ pageProps, action: catsPayload })
            .then(() => {
                // console.log("cats request =>", pageProps.getStoreCatsRequest)
                // console.log("cats =>", pageProps.getStoreCatsRequest?.request_data?.response?.results)


            })

        // pageProps.logoutAction();

    }, [])




    //Handle Address selection from Address AutoComplete

    return (
        <div className="signup-wrapper">
            <div className="signup-header">
                <img src={Logo} />
            </div>
            {/* {(pageProps.getStoreCatsRequest.request_status === "GET_STORE_CATS_SUCCESS" && catsList.length >= 1) && */}
            {(pageProps.getStoreCatsRequest.request_status === "GET_STORE_CATS_SUCCESS") &&
                <div className="signup-content">
                    {(pageProps?.creatUserRequest?.request_status
                        && pageProps.creatUserRequest.request_status !== "CREATE_ACCOUNT_SUCCESS") &&
                        <CreatAccountForm
                            defaultLogo={defaultLogo}
                            catListData={pageProps.getStoreCatsRequest?.request_data}
                            pageProps={pageProps}
                        />

                    }
                    {(pageProps?.creatUserRequest?.request_status
                        && pageProps.creatUserRequest.request_status === "CREATE_ACCOUNT_SUCCESS") &&
                        <div className="success-message-wrap">
                            <div className="success-animation">
                                <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                            </div>
                            <div className="success-message-txt">Your account has been created. Please click the link in the email sent to you</div>
                            <div className="resend_activation_link">Didn't receive link? Click here</div>

                        </div>
                    }
                </div>
            }
            {pageProps?.getStoreCatsRequest?.request_status && pageProps.getStoreCatsRequest.request_status === "GET_STORE_CATS_FAILURE" &&
                <div className="width-40 m-auto">
                    <AlertMsg type="error" message={pageProps.creatUserRequest?.request_data.error} />
                </div>
            }
            {pageProps.getStoreCatsRequest.request_status === "GET_STORE_CATS_PENDING" &&
                <LoadingItem />
            }
        </div>
    )
}


const SignUp = (pageProps: any) => {
    return (
        <div className="signup-wrap">
            <SignUpContent pageProps={pageProps} />
        </div>
    )
}


const mapDispatchToProps = {
    createAccountAction: authActions.CreateAccount,
    getStoreUrlAction: authActions.GetStoreUrl,
    getStoreCategoriesAction: authActions.GetStoreCategories,
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
    getUrlRequest: state.onboardingOutboundReducers.getUrlReducer,
    getStoreCatsRequest: state.onboardingOutboundReducers.getStoreCatsReducer,
    creatUserRequest: state.onboardingOutboundReducers.creatUserReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);