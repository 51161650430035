export const appConstants ={
    GOOGLE_MAP_KEY : "AIzaSyBgo1zf5mTGQpvXu4hPjQ6c5Cg2Ppo4Klc",
    CREATE_PRODUCT_SUCCESS : 'CREATE_PRODUCT_SUCCESS',
    CREATE_PRODUCT_PENDING : 'CREATE_PRODUCT_PENDING',
    CREATE_PRODUCT_FAILURE : 'CREATE_PRODUCT_FAILURE',
    CREATE_PRODUCT_RESET : 'CREATE_PRODUCT_RESET',

    UPDATE_PRODUCT_SUCCESS : 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_PENDING : 'UPDATE_PRODUCT_PENDING',
    UPDATE_PRODUCT_FAILURE : 'UPDATE_PRODUCT_FAILURE',
    UPDATE_PRODUCT_RESET : 'UPDATE_PRODUCT_RESET',

    UPLOAD_PRODUCT_PICTURE_SUCCESS : 'UPLOAD_PRODUCT_PICTURE_SUCCESS',
    UPLOAD_PRODUCT_PICTURE_PENDING : 'UPLOAD_PRODUCT_PICTURE_PENDING',
    UPLOAD_PRODUCT_PICTURE_FAILURE : 'UPLOAD_PRODUCT_PICTURE_FAILURE',
    UPLOAD_PRODUCT_PICTURE_RESET : 'UPLOAD_PRODUCT_PICTURE_RESET',

    GET_PRODUCTS_SUCCESS : 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_PENDING : 'GET_PRODUCTS_PENDING',
    GET_PRODUCTS_FAILURE : 'GET_PRODUCTS_FAILURE',
    GET_PRODUCTS_RESET : 'GET_PRODUCTS_RESET',

    GET_A_PRODUCT_SUCCESS : 'GET_A_PRODUCT_SUCCESS',
    GET_A_PRODUCT_PENDING : 'GET_A_PRODUCT_PENDING',
    GET_A_PRODUCT_FAILURE : 'GET_A_PRODUCT_FAILURE',
    GET_A_PRODUCT_RESET : 'GET_A_PRODUCT_RESET',

    GET_PRODUCT_META_SUCCESS : 'GET_PRODUCT_META_SUCCESS',
    GET_PRODUCT_META_PENDING : 'GET_PRODUCT_META_PENDING',
    GET_PRODUCT_META_FAILURE : 'GET_PRODUCT_META_FAILURE',
    GET_PRODUCT_META_RESET : 'GET_PRODUCT_META_RESET',

    GET_IMAGE_GALLERY_SUCCESS : 'GET_IMAGE_GALLERY_SUCCESS',
    GET_IMAGE_GALLERY_PENDING : 'GET_IMAGE_GALLERY_PENDING',
    GET_IMAGE_GALLERY_FAILURE : 'GET_IMAGE_GALLERY_FAILURE',
    GET_IMAGE_GALLERY_RESET : 'GET_IMAGE_GALLERY_RESET',
    
    GET_IMAGE_GALLERY_NEWBATCH : 'GET_IMAGE_GALLERY_NEWBATCH',

    GET_VENDOR_ORDERS_SUCCESS : 'GET_VENDOR_ORDERS_SUCCESS',
    GET_VENDOR_ORDERS_PENDING : 'GET_VENDOR_ORDERS_PENDING',
    GET_VENDOR_ORDERS_FAILURE : 'GET_VENDOR_ORDERS_FAILURE',
    GET_VENDOR_ORDERS_RESET : 'GET_VENDOR_ORDERS_RESET',

    GET_VENDOR_ORDER_DETAILS_SUCCESS : 'GET_VENDOR_ORDER_DETAILS_SUCCESS',
    GET_VENDOR_ORDER_DETAILS_PENDING : 'GET_VENDOR_ORDER_DETAILS_PENDING',
    GET_VENDOR_ORDER_DETAILS_FAILURE : 'GET_VENDOR_ORDER_DETAILS_FAILURE',
    GET_VENDOR_ORDER_DETAILS_RESET : 'GET_VENDOR_ORDER_DETAILS_RESET',

    CREATE_PAYOUT_ACCOUNT_SUCCESS : 'CREATE_PAYOUT_ACCOUNT_SUCCESS',
    CREATE_PAYOUT_ACCOUNT_PENDING : 'CREATE_PAYOUT_ACCOUNT_PENDING',
    CREATE_PAYOUT_ACCOUNT_FAILURE : 'CREATE_PAYOUT_ACCOUNT_FAILURE',
    CREATE_PAYOUT_ACCOUNT_RESET : 'CREATE_PAYOUT_ACCOUNT_RESET',

    GET_PAYOUT_PENDING_LINK_SUCCESS : 'GET_PAYOUT_PENDING_LINK_SUCCESS',
    GET_PAYOUT_PENDING_LINK_PENDING : 'GET_PAYOUT_PENDING_LINK_PENDING',
    GET_PAYOUT_PENDING_LINK_FAILURE : 'GET_PAYOUT_PENDING_LINK_FAILURE',
    GET_PAYOUT_PENDING_LINK_RESET : 'GET_PAYOUT_PENDING_LINK_RESET',

    GET_STORE_PAY_ACCOUNTS_SUCCESS : 'GET_STORE_PAY_ACCOUNTS_SUCCESS',
    GET_STORE_PAY_ACCOUNTS_PENDING : 'GET_STORE_PAY_ACCOUNTS_PENDING',
    GET_STORE_PAY_ACCOUNTS_FAILURE : 'GET_STORE_PAY_ACCOUNTS_FAILURE',
    GET_STORE_PAY_ACCOUNTS_RESET : 'GET_STORE_PAY_ACCOUNTS_RESET',

    GET_STORE_PAYMENT_HISTORY_SUCCESS : 'GET_STORE_PAYMENT_HISTORY_SUCCESS',
    GET_STORE_PAYMENT_HISTORY_PENDING : 'GET_STORE_PAYMENT_HISTORY_PENDING',
    GET_STORE_PAYMENT_HISTORY_FAILURE : 'GET_STORE_PAYMENT_HISTORY_FAILURE',
    GET_STORE_PAYMENT_HISTORY_RESET : 'GET_STORE_PAYMENT_HISTORY_RESET',

    TOGGLE_A_PRODUCT_SUCCESS : 'TOGGLE_A_PRODUCT_SUCCESS',
    TOGGLE_A_PRODUCT_PENDING : 'TOGGLE_A_PRODUCT_PENDING',
    TOGGLE_A_PRODUCT_FAILURE : 'TOGGLE_A_PRODUCT_FAILURE',
    TOGGLE_A_PRODUCT_RESET : 'TOGGLE_A_PRODUCT_RESET',

    GET_STORE_DETAILS_SUCCESS : 'GET_STORE_DETAILS_SUCCESS',
    GET_STORE_DETAILS_PENDING : 'GET_STORE_DETAILS_PENDING',
    GET_STORE_DETAILS_FAILURE : 'GET_STORE_DETAILS_FAILURE',
    GET_STORE_DETAILS_RESET : 'GET_STORE_DETAILS_RESET',

    UPDATE_STORE_DETAILS_SUCCESS : 'UPDATE_STORE_DETAILS_SUCCESS',
    UPDATE_STORE_DETAILS_PENDING : 'UPDATE_STORE_DETAILS_PENDING',
    UPDATE_STORE_DETAILS_FAILURE : 'UPDATE_STORE_DETAILS_FAILURE',
    UPDATE_STORE_DETAILS_RESET : 'UPDATE_STORE_DETAILS_RESET',


    GET_DASHBOARD_TOP_DATA_SUCCESS : 'GET_DASHBOARD_TOP_DATA_SUCCESS',
    GET_DASHBOARD_TOP_DATA_PENDING : 'GET_DASHBOARD_TOP_DATA_PENDING',
    GET_DASHBOARD_TOP_DATA_FAILURE : 'GET_DASHBOARD_TOP_DATA_FAILURE',
    GET_DASHBOARD_TOP_DATA_RESET : 'GET_DASHBOARD_TOP_DATA_RESET',

    GET_DASHBOARD_MIDDLE_DATA_SUCCESS : 'GET_DASHBOARD_MIDDLE_DATA_SUCCESS',
    GET_DASHBOARD_MIDDLE_DATA_PENDING : 'GET_DASHBOARD_MIDDLE_DATA_PENDING',
    GET_DASHBOARD_MIDDLE_DATA_FAILURE : 'GET_DASHBOARD_MIDDLE_DATA_FAILURE',
    GET_DASHBOARD_MIDDLE_DATA_RESET : 'GET_DASHBOARD_MIDDLE_DATA_RESET',

    GET_DASHBOARD_BOTTOM_DATA_SUCCESS : 'GET_DASHBOARD_BOTTOM_DATA_SUCCESS',
    GET_DASHBOARD_BOTTOM_DATA_PENDING : 'GET_DASHBOARD_BOTTOM_DATA_PENDING',
    GET_DASHBOARD_BOTTOM_DATA_FAILURE : 'GET_DASHBOARD_BOTTOM_DATA_FAILURE',
    GET_DASHBOARD_BOTTOM_DATA_RESET : 'GET_DASHBOARD_BOTTOM_DATA_RESET',

    CREATE_PAYOUT_TOKEN_SUCCESS : 'CREATE_PAYOUT_TOKEN_SUCCESS',
    CREATE_PAYOUT_TOKEN_PENDING : 'CREATE_PAYOUT_TOKEN_PENDING',
    CREATE_PAYOUT_TOKEN_FAILURE : 'CREATE_PAYOUT_TOKEN_FAILURE',
    CREATE_PAYOUT_TOKEN_RESET : 'CREATE_PAYOUT_TOKEN_RESET',

    DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS : 'DEACTIVATE_PAYOUT_ACCOUNT_SUCCESS',
    DEACTIVATE_PAYOUT_ACCOUNT_PENDING : 'DEACTIVATE_PAYOUT_ACCOUNT_PENDING',
    DEACTIVATE_PAYOUT_ACCOUNT_FAILURE : 'DEACTIVATE_PAYOUT_ACCOUNT_FAILURE',
    DEACTIVATE_PAYOUT_ACCOUNT_RESET : 'DEACTIVATE_PAYOUT_ACCOUNT_RESET',

    CHANGE_ORDER_STATUS_SUCCESS : 'CHANGE_ORDER_STATUS_SUCCESS',
    CHANGE_ORDER_STATUS_PENDING : 'CHANGE_ORDER_STATUS_PENDING',
    CHANGE_ORDER_STATUS_FAILURE : 'CHANGE_ORDER_STATUS_FAILURE',
    CHANGE_ORDER_STATUS_RESET : 'CHANGE_ORDER_STATUS_RESET',

    UPLOAD_MULTIPLE_SUCCESS : 'UPLOAD_MULTIPLE_SUCCESS',
    UPLOAD_MULTIPLE_PENDING : 'UPLOAD_MULTIPLE_PENDING',
    UPLOAD_MULTIPLE_FAILURE : 'UPLOAD_MULTIPLE_FAILURE',
    UPLOAD_MULTIPLE_RESET : 'UPLOAD_MULTIPLE_RESET',

    ADD_A_NEW_STORE_SUCCESS : 'ADD_A_NEW_STORE_SUCCESS',
    ADD_A_NEW_STORE_PENDING : 'ADD_A_NEW_STORE_PENDING',
    ADD_A_NEW_STORE_FAILURE : 'ADD_A_NEW_STORE_FAILURE',
    ADD_A_NEW_STORE_RESET : 'ADD_A_NEW_STORE_RESET',
}