// console.log("location is=>", window.location.origin)
// let MEDIA_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

// let STORE_BASE_URL = process.env.REACT_APP_STORE_BASE_URL;
// let AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

let MEDIA_BASE_URL = window.location.origin!=="https://vendor.mamimarkets.com" ? "https://staging-mediaapi.mamimarkets.com":"https://mediaapi.mamimarkets.com";
let MEDIA_BASE =  window.location.hostname!=="localhost"? `${MEDIA_BASE_URL}/api`: `http://localhost:8030/proxy/api`;
// let MEDIA_BASE = `${MEDIA_BASE_URL}/api`;

let AUTH_BASE_URL = window.location.origin!=="https://vendor.mamimarkets.com" ? "https://staging-identityapi.mamimarkets.com":"https://identityapi.mamimarkets.com";
 AUTH_BASE_URL = window.location.hostname!=="localhost"? AUTH_BASE_URL: `http://localhost:8010/proxy`;
let AUTH_BASE = window.location.hostname!=="localhost"? `${AUTH_BASE_URL}/api/v1/`: `http://localhost:8010/proxy/api/v1/`;



let STORE_BASE_URL = window.location.origin!=="https://vendor.mamimarkets.com" ? "https://staging-productapi.mamimarkets.com":"https://productapi.mamimarkets.com";
// let STORE_BASE = `${STORE_BASE_URL}/api`;
let STORE_BASE = window.location.hostname!=="localhost"?`${STORE_BASE_URL}/api`: `http://localhost:8020/proxy/api`;


// console.log(process.env.REACT_APP_AUTH_BASE_URL);
// console.log(process.env.REACT_APP_STORE_BASE_URL);
// console.log(process.env.REACT_APP_IMAGE_BASE_URL);

export const routes = {
    LOGIN: `${AUTH_BASE_URL}/connect/token`,
    INITIATE_FORGOT_PW: `${AUTH_BASE}Accounts/ForgotPassword`,
    RESET_PW: `${AUTH_BASE}Accounts/ResetPassword`,
    GET_PROFILE: `${AUTH_BASE}Accounts/GetUserProfile`,

    CONFIRM_EMAIL: `${AUTH_BASE}Accounts/ConfirmEmail`,
    GET_OTP_FOR_PHONE_CONFIRM: `${AUTH_BASE}Accounts/RequestPhoneNumberConfirmation`,
    CONFIRM_PHONE_NUM: `${AUTH_BASE}Accounts/ConfirmPhoneNumber`,
    RESEND_ACTIVATION_LINK: `${AUTH_BASE}Accounts/RequestEmailConfirmation`,

    GET_URL: `${AUTH_BASE}Stores/GetUrl`,
    GET_MY_STORES: `${STORE_BASE}/v1/Stores/GetMyStores`,
    GET_PAY_ACCOUNTS: `${STORE_BASE}/v1/Payments/GetAccounts`,
    GET_PUBLISH_KEY: `${STORE_BASE}/v1/Payments/GetPublishableKey`,
    DE_ACTIVATE_WITHDRAWAL_METHOD: `${STORE_BASE}/v1/Payments/DeactivateAccount`,
    GET_PAYOUT_HISTORY: `${STORE_BASE}/Payouts`,
    REGISTER_WITH_EMAIL: `${AUTH_BASE}Vendors/RegisterWithEmail`,
    
    CREATE_PRODUCT: `${STORE_BASE}/v1/Products/CreateProduct`,
    UPLOAD_BULK_PRODUCT: `${STORE_BASE}/v1/Products/CreateBulkProduct`,
    UPDATE_PRODUCT: `${STORE_BASE}/v1/Products/UpdateProduct`,
    GET_PRODUCTS: `${STORE_BASE}/v1/Products/GetProducts`,
    
    TOGGLE_PRODUCT: `${STORE_BASE}/v1/Products/`,
    
    GET_VENDORS_PRODUCTS: `${STORE_BASE}/v1/Products/GetVendorProducts`,
    
   

    GET_A_PRODUCT: `${STORE_BASE}/v1/Products/GetProduct`,
    GET_CATEGORIES: `${STORE_BASE}/Categories`,
    GET_UNITS: `${STORE_BASE}/UnitOfMeasures`,

    GET_STORE_DETAILS: `${STORE_BASE}/v1/Stores`,
    UPDATE_STORE_DETAILS: `${STORE_BASE}/v1/Stores/UpdateStore`,
    
    GET_IMAGE_GALLERY: `${MEDIA_BASE}/ProductImage/getImages`,
    PRODUCT_IMAGE_UPLOAD: `${MEDIA_BASE}/ProductImage/upload`,
    LOGO_IMAGE_UPLOAD: `${MEDIA_BASE}/ProductImage/UploadImage`,
    
    CREATE_PAYOUT_ACC: `${STORE_BASE}/v1/Payments/CreateAccount`,
    CREATE_PAYOUT_LINK: `${STORE_BASE}/v1/Payments/CreateAccountLink`,

    TOKEN_CARD_DATA: `${STORE_BASE}/v1/Payments/TokenizeCard`,
    TOKEN_BANK_DATA: `${STORE_BASE}/v1/Payments/TokenizeBankDetail`,

    GET_STORES_CATS: `${STORE_BASE}/StoreGroups`,
    
    CUSTOMER_COUNT: `${STORE_BASE}/v1/Reports/GetStoreCustomersCount`,
    SUMMARY_REPORT: `${STORE_BASE}/v1/Reports/GetVendorOrderSummaryReport`,
    REVIEW_SUMMARY: `${STORE_BASE}/v1/Reports/GetStoreReviewSummary`,
    ORDER_GRAPH_SUMMARY: `${STORE_BASE}/v1/Reports/GetOrderGraphReport`,
    ORDER_ITEM_REPORT: `${STORE_BASE}/v1/Reports/GetOrderItemReport`,


    GET_STORE_ORDERS: `${STORE_BASE}/v1/Orders/GetVendorOrders`,
    GET_STORE_ORDER_DETAIL: `${STORE_BASE}/v1/Orders/GetVendorOrderDetail`,
    SET_ORDER__IS_READY: `${STORE_BASE}/v1/Orders/VendorToggleOrderReady`,
    SET_ORDER__IS_PICKED_UP: `${STORE_BASE}/v1/Orders/OrderPickedUp`,
    SET_ORDER__IS_DELIVERED: `${STORE_BASE}/v1/Orders/OrderDelivered`,
    SET_ORDER__IS_CONFIRMED: `${STORE_BASE}/v1/Orders/VendorConfirmOrder`,


    ADD_A_STORE : `${AUTH_BASE}Stores/AddStore`,



}

