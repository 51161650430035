import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { publicIp } from 'public-ip';
import { InAppTemplate } from "../../../shared/templates/inapp";
import Info from "../../../assets/icons/info.svg";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { CardElement, Elements, useStripe, useElements, PaymentElement, CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from "@stripe/react-stripe-js"
import PhoneInput from 'react-phone-input-2'
import Modal from 'react-bootstrap/Modal'
import ArrowDn from "../../../assets/icons/arrow-dn.svg";
import OrderIco from "../../../assets/icons/dashboard/order.png";
import ProductsIco from "../../../assets/icons/dashboard/products.png";
import AveOrderIco from "../../../assets/icons/dashboard/ave-order.png";
import DiscountIco from "../../../assets/icons/dashboard/discount.png";
import ArrowGr from "../../../assets/icons/dashboard/arrow-up.png";
import ArrowRed from "../../../assets/icons/dashboard/arrow-dn.png";
import CustAll from "../../../assets/icons/dashboard/cust-all.png";
import WithdrawalIco from "../../../assets/icons/dashboard/withdrawal.png";
import NegativeIco from "../../../assets/icons/dashboard/negative.png";
import NeutralIco from "../../../assets/icons/dashboard/neutral.png";
import PositiveIco from "../../../assets/icons/dashboard/positive.png";
import ExportIco from "../../../assets/icons/dashboard/export.png";
import WalletIco from "../../../assets/icons/dashboard/wallet.png";
import LoadingIco from "../../../assets/loader1.gif";

import { loadStripe } from "@stripe/stripe-js"


import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';

import { AlertMsg } from "../../../shared/components/alert-msg"
import LeftArrow from "../../../assets/left-arr.png"

import { storeActions, GetGeoCodeInfoWithPostalCode } from "../../../redux/actions/in-app"

import { appConstants } from "../../../redux/action-constants/inapp.constants";

import distinctColors from 'distinct-colors'
import exportAsImage from "../../../shared/_helpers/exportAImage";
import { history } from "../../../shared/_helpers/history";
import { allowNumbersOnly, formatCreditCardNumber, formatCVC, formatExpirationDate, } from "../../../shared/utils"

import { useWindowSize } from "../../../hooks/useWindowSize";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ArcElement,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line, Doughnut } from 'react-chartjs-2';
import "../index.scss"


ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    responsive: true,
    cutout: 120,
    plugins: {
        legend: {
            display: false,
            position: 'top' as const,
        },
        title: {
            display: false,
            // text: 'Chart.js Line Chart',
        },
    },
    tension: 0.5
};


const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];

// const labelValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const labelValues = [1000, 1200, 1000, 2000, 1600, 2700, 2300, 2500];
const labelValues2 = [2000, 1100, 2000, 2000, 1200, 2700, 2000, 1500];
const data = {
    labels,
    datasets: [
        {
            label: '',
            data: labelValues,
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 5,
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: '',
            data: labelValues2,
            borderColor: 'rgb(80, 143, 244)',
            borderWidth: 5,
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },

    ],
};

const data2 = {
    labels,
    datasets: [
        {
            fill: true,
            label: '',
            data: labelValues,
            borderColor: 'rgba(80, 143, 244, 0.2)',
            borderWidth: 5,
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            fill: true,
            label: '',
            data: labelValues2,
            borderColor: 'rgb(80, 143, 244)',
            borderWidth: 5,
            // backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },

    ],
};

const data3 = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    // width: 100,
    datasets: [
        {
            label: '',
            data: [24, 65, 74, 32],
            backgroundColor: [
                'rgba(12, 239, 171, 1)',
                'rgba(250, 188, 30, 1)',
                'rgba(102, 82, 222, 1)',
                'rgba(255, 44, 247, 1)',
            ],
            borderColor: [
                'rgba(12, 239, 171, 1)',
                'rgba(250, 188, 30, 1)',
                'rgba(102, 82, 222, 1)',
                'rgba(255, 44, 247, 1)',
            ],
            borderWidth: 0,
        },
    ],
};


const getTopData = async ({ pageProps, payload }: any) => {

    await pageProps.getDashbardTopData(payload);
}

const getMiddleData = async ({ pageProps, payload }: any) => {

    await pageProps.getDashbardMiddleData(payload);
}


const getBottomData = async ({ pageProps, payload, payload2 }: any) => {

    await pageProps.getDashbardBottompData(payload, payload2);
}

const createPayoutAccAction = async ({ pageProps, payload }: any) => {

    await pageProps.createPayoutAccountAction(payload);
}

const createTokenizeData = async ({ pageProps, payload, type }: any) => {

    await pageProps.createTokenizeData(payload, type);
}


const CardPayoutForm = ({ stripe, elements, setCardTokenized }: any) => {
    const [cardHolder, setCardHolder] = useState<string>("")
    const [cardHolderError, setCardHolderError] = useState<boolean>(false)
    const [cardCardNumDone, setCardNumDone] = useState<boolean>(true)
    const [cardCardNumCheck, setCardNumCheck] = useState<string>("")
    const [cardCvvDone, setCardCvvDone] = useState<boolean>(true)
    const [cardCvvCheck, setCardCvvCheck] = useState<string>("")
    const [cardExpiryDone, setCardExpiryDone] = useState<boolean>(true)
    const [cardExpiryCheck, setCardExpiryCheck] = useState<string>("")

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return
        }
        if (cardHolder == "" || cardHolder.length < 4) {
            setCardHolderError(true)
        } else {
            setCardHolderError(false)
        }

        if (cardCardNumCheck == "") {
            setCardNumDone(false)
        }
        if (cardCvvCheck == "") {
            setCardCvvDone(false)
        }

        if (cardExpiryCheck == "") {
            setCardExpiryDone(false)
        }

        const cardNumberElement = elements.getElement(CardNumberElement)

        if (cardHolder !== "" && !cardHolderError && cardCardNumCheck !== "" && cardCardNumDone && cardCvvCheck !== "" && cardCvvDone && cardExpiryCheck !== "" && cardExpiryDone) {

            const res = await stripe.createToken(cardNumberElement, { name: cardHolder, currency:"CAD" })
            if (res?.token) {
                
                setCardTokenized(res)
            }

           
        }
    }
    return (
        <div className="form-wrap withdrawal-form">
            <div className="card_allo"></div>wed
            <div className="form-group">
                <label htmlFor="">Card number</label>
                <CardNumberElement
                    onChange={(event) => {
                        if (event?.empty === false) {
                            setCardNumCheck("card")
                        } else {
                            setCardNumCheck("")
                        }
                        if (event?.complete === true) {
                            setCardNumDone(true)
                        } else {
                            setCardNumDone(false)
                        }
                    }}
                    options={{
                        showIcon: true,
                        classes: {
                            base: `card2 ${cardCardNumDone ? "" : "is-invalid-input"}`
                        }
                    }} />

            </div>
            <div className="two-sided">
                <div className="form-group">
                    <label htmlFor="">Expiration date</label>
                    <CardExpiryElement
                        onChange={(event) => {
                            if (event?.empty === false) {
                                setCardExpiryCheck("exp")
                            } else {
                                setCardExpiryCheck("")
                            }
                            if (event?.complete === true) {
                                setCardExpiryDone(true)
                            } else {
                                setCardExpiryDone(false)
                            }
                        }}
                        options={{
                            classes: {
                                base: `card2 ${cardExpiryDone ? "" : "is-invalid-input"}`
                            }
                        }} />
                </div>
                <div className="form-group">
                    <label htmlFor="">CVV</label>
                    <CardCvcElement
                        onChange={(event) => {
                            if (event?.empty === false) {
                                setCardCvvCheck("cvv")
                            } else {
                                setCardCvvCheck("")
                            }
                            if (event?.complete === true) {
                                setCardCvvDone(true)
                            } else {
                                setCardCvvDone(false)
                            }
                        }}
                        options={{
                            placeholder: "000",
                            classes: {
                                base: `card2 ${cardCvvDone ? "" : "is-invalid-input"}`
                            }
                        }} />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="">Cardholder name</label>
                <input
                    id="name"
                    value={cardHolder}
                    name="name"
                    type="text"
                    placeholder="Enter name on card"
                    onChange={(e: any) => {
                        setCardHolder(e.target.value)
                        if (e.target.value.trim().length >= 4) {
                            setCardHolderError(false)
                        } else {
                            setCardHolderError(true)
                        }
                    }}
                    className={`input-wrap ${cardHolderError ?
                        "is-invalid-input "
                        : cardHolder !== "" ? "valid-input" : ""}`}
                />
                {cardHolderError && <div className="form-input-error">Required</div>}
                {/* <ErrorMessage name="name" className="form-input-error" component="div" /> */}
            </div>
            <div className="payout-cta">
                <button
                    // disabled={tokenRequest?.is_request_processing}
                    onClick={handleSubmit}
                    className="btn"
                // className={tokenRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                >
                    Verify information
                    {/* {tokenRequest.is_request_processing ? "Please wait..." : "Verify information"} */}
                </button>
            </div>
        </div>
    )
}
const CardPayoutFormWrap = ({ setCardTokenized }: any) => {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardPayoutForm setCardTokenized={setCardTokenized} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    )
}
const CreatePayoutAccountModal = ({ showPayoutModal, setShowPayoutModal, pageProps, stripePromiseInfo }: any) => {
    const [payoutAccountData, setPayoutAccountData] = useState();
    const [payoutAccountCity, setPayoutAccountCity] = useState();
    const [payoutStage, setPayoutStage] = useState("acc_details");
    const [payoutType, setPayoutType] = useState<any>();

    const [cardtokenizedData, setCardTokenized] = useState<any>();
    const [newPostalCode, setNewPostalCode] = useState();
    const [postalCodeAreaData, setPostalCodeArea] = useState<any>();
    const [postalCodeAreaError, setPostalCodeAreaError] = useState<any>();

    const [extractedInfo, setExtractedInfo] = useState<any>();
    const [ownerAddress, setOwnerAddress] = useState<any>(null);
    const [ownerExtractedInfo, setOwnerExtractedInfo] = useState<any>();
    const [ownerAddressSearched, setOwnerAddressSearched] = useState<any>();
    const [storeFormInitValues, setStoreFormInitValues] = useState<any>({
        // password: "",
        // confirmPassword: "",
        // storeUrl: "",
        // storeName: "",
        // phoneNumber: "",
        // storeEmail: "",
        // ownerTitle: "",
        // storeProvince: "",
        // firstName: "",
        // lastName: "",
        // ownerPhone: "",
        // ownerCity: "",
        // ownerAddress: "",
        // ownerPostCode: "",
        // howHear: "",
        // storeCats:"",
        // storeAddress: "",
        // agreed: false
        country: "Canada",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        phone: "",
        name: "",
        postalCode: "",
        firstName: "",
        lastName: ""
    });
    // const [cardNum, setClientSecret] = useState<any>();

    const [clientSecret, setClientSecret] = useState<any>();
    const [tokenizedData, setDataToTokenize] = useState<any>();
    const [ipAddress, setIpAddress] = useState<any>();

    let tokenRequest = pageProps.createPayoutInfoTokenRequest;
    async function getMyIp() {

        let ipAddress = await publicIp();
        //    
        setIpAddress(ipAddress)
        // return ipAddress;
    }
    useEffect(() => {
        getMyIp()
        createTokenizeData({
            pageProps,
            payload: "CLEAR"
        })
        setStoreFormInitValues({
            country: "Canada",
            email: "",
            addressLine1: "",
            city: "",
            state: "",
            phone: "",
            name: "",
            postalCode: "",
            firstName: "",
            lastName: ""
        })
        setOwnerAddressSearched({
            label: "",
            value: "",
        })
        // let publishKey = "pk_test_51LcbNDHJH8AKEGL7VaMkQ6xHS9lBcI6QQlnpVxbTDw2AVaazrKXywMsXwoYPjrkyscQxAjPFn56eW7RN5fzC4ChK00htA72jFT"
        // let secretKey = "pi_3MX1RtHJH8AKEGL70tU50HKj_secret_Z4ymWltCDYHnNL6zp2SzydNaZ"
        // const stripePromise = loadStripe(publishKey);

        // setStripePromise(stripePromise)
        // setClientSecret(secretKey)
    }, [])

    useEffect(() => {

        if (payoutAccountData) {
            createPayoutAccAction({ pageProps, payload: payoutAccountData })
            // .then(() => {

            //     console.log("payload is ", payload);

            // })
        }


    }, [payoutAccountData])

    useEffect(() => {

        if (tokenizedData) {
            createPayoutAccAction({
                pageProps,
                payload: "CLEAR"
            })
            setOwnerAddressSearched({
                label: "",
                value: "",
            })
            setStoreFormInitValues({
                country: "Canada",
                email: "",
                addressLine1: "",
                city: "",
                state: "",
                phone: "",
                name: "",
                postalCode: "",
                firstName: "",
                lastName: ""
            })
            createTokenizeData({
                pageProps,
                payload: "CLEAR"
            })
            createTokenizeData({ pageProps, payload: tokenizedData, type: payoutType })
            // .then(() => {

            //     console.log("payload is ", payload);

            // })
        }


    }, [tokenizedData])

    useEffect(() => {
        if (ownerExtractedInfo) {

            // setPostalCodeAreaError("")
            let tempStoreFormInitValues = { ...storeFormInitValues }
            tempStoreFormInitValues.ownerCity = ownerExtractedInfo?.postalCodeArea
            tempStoreFormInitValues.ownerState = ownerExtractedInfo?.provinceChosenFull
            tempStoreFormInitValues.ownerPostCode = ownerExtractedInfo?.postCode
            setPostalCodeArea(ownerExtractedInfo?.postCode)
            setStoreFormInitValues(tempStoreFormInitValues)
            setOwnerAddressSearched({
                label: ownerExtractedInfo?.newAddress,
                value: ownerExtractedInfo?.newAddress,
            })
        }

    }, [ownerExtractedInfo])

    useEffect(() => {
        if (newPostalCode) {
            setPostalCodeAreaError("")
            GetGeoCodeInfoWithPostalCode(newPostalCode, extractedInfo?.topLevelArea).then((res: any) => {
                // console.log("res", res)
                let tempStoreFormInitValues = { ...storeFormInitValues }
                if (res?.status === "success" && res?.data) {

                    tempStoreFormInitValues.ownerCity = res?.data
                    // setPostalCodeArea(res?.data)
                } else {
                    setPostalCodeAreaError(res?.error)
                    // tempStoreFormInitValues.ownerCity = ""
                    // setPostalCodeArea("")
                }
                setStoreFormInitValues(tempStoreFormInitValues)
            })
        }

    }, [newPostalCode])

    let detailsValidationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("Required")

    });

    let payoutInitialValues: any = {
        country: "Canada",
        email: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        phone: "",
        name: "",
        postalCode: "",
        firstName: "",
        lastName: ""
    }

    let cardPayoutInitialValues: any = {
        number: "",
        cvc: "",
        expiry: "",
        name: "",
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
    }

    let cardDetailsValidationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Required"),
        number: Yup.string()
            .required("Required"),
        cvc: Yup.string()
            .required("Required")
            .min(3, "Invalid"),
        expiry: Yup.string()
            .required("Required")
            .min(5, "Invalid"),
        addressLine1: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        zip: Yup.string(),

    });
    let bankPayoutInitialValues: any = {
        accountNumber: "",
        accountHolderName: "",
        currency: "CAD",
        accountHolderType: "",
        routingNumber: "",
        institutionNumber: "",
        accountype: "",
    }


    let bankDetailsValidationSchema = Yup.object().shape({
        accountNumber: Yup.string()
            .required("Required"),
        accountHolderName: Yup.string()
            .required("Required"),
        routingNumber: Yup.string()
            .required("Required"),
        accountHolderType: Yup.string()
            .required("Required"),
        accountype: Yup.string()
            .required("Required"),
        institutionNumber: Yup.string()
            .required("Required"),


    });

    let extractedData: any = {};
    const handleSelectedOwnerAddress = async (address: any) => {

        setOwnerAddress(address)


        // getLatLng

        const [place] = await geocodeByPlaceId(address?.value.place_id);
        let addressMeta = place?.address_components;



        // geocodeByAddress(address?.label)
        //     .then(results => getLatLng(results[0]))
        //     .then(({ lat, lng }: any) => {
        //         console.log("addressLat", lat)
        //         console.log("addressLng", lng)
        //         setAddressLatLng({ lat, lng })
        //     }
        //     );


        let extractedInfoFromAddress = () => {
            let streetNumber: any = '',
                streetName: any = '',
                areaName: any = '',
                townName: any = '',
                postalCodeArea: any = '',
                fullAddress: any,
                topLevelArea: any,
                provinceChosenFull: any,
                provinceChosen: any;

            for (let componentIndex = 0; componentIndex < addressMeta.length; componentIndex++) {

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "postal_code" && !extractedData?.postCode
                ) {
                    extractedData.postCode = addressMeta[componentIndex]?.long_name;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "street_number" && streetNumber === ""
                ) {

                    streetNumber = `${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length === 1
                    && addressMeta[componentIndex]?.types[0] === "route" && streetName === ""
                ) {
                    streetName = `${streetNumber !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 1
                    && (
                        addressMeta[componentIndex]?.types.includes("neighborhood") ||
                        addressMeta[componentIndex]?.types.includes("locality")
                    )
                    && (areaName.split(",").length - 1) < 1

                    // && areaName === ""
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4")
                    )
                    && (areaName.split(",").length - 1) < 1
                ) {

                    areaName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")
                    )
                    // && areaName === ""
                ) {
                    townName += `${streetNumber !== "" || streetName !== "" || areaName !== "" ? ', ' : ""}${addressMeta[componentIndex]?.long_name}`;
                }

                // console.log("postalCodeArea.length",postalCodeArea.length)
                // console.log("administrative_area_level_2",addressMeta[componentIndex]?.types.includes("administrative_area_level_2"))
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        addressMeta[componentIndex]?.types.includes("administrative_area_level_3") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_4") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_5") ||
                        addressMeta[componentIndex]?.types.includes("locality") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_2")


                    )
                    // && postalCodeArea.length === 0
                    // && areaName === ""
                ) {
                    postalCodeArea = `${addressMeta[componentIndex]?.long_name}`;

                }

                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )
                    && ((postalCodeArea.split(",").length - 1) < 1)

                ) {
                    // postalCodeArea += `${postalCodeArea !== "" ? ", " : ""}${addressMeta[componentIndex]?.short_name}`;
                    topLevelArea = addressMeta[componentIndex]?.long_name
                }
                if (addressMeta[componentIndex]?.types.length >= 2
                    && (

                        // addressMeta[componentIndex]?.types.includes("administrative_area_level_2") ||
                        addressMeta[componentIndex]?.types.includes("administrative_area_level_1")
                    )


                ) {
                    provinceChosen = addressMeta[componentIndex]?.short_name
                    provinceChosenFull = addressMeta[componentIndex]?.long_name
                }


                // fullAddress = `${streetNumber} ${streetName} ${areaName} ${townName}`;
                fullAddress = `${streetNumber} ${streetName} ${areaName}`;
                extractedData.newAddress = fullAddress;
                extractedData.postalCodeArea = postalCodeArea;
                extractedData.topLevelArea = topLevelArea;
                extractedData.provinceChosen = provinceChosen;
                extractedData.provinceChosenFull = provinceChosenFull;


                if (extractedData?.postCode && extractedData?.newAddress
                    && extractedData?.provinceChosen && extractedData?.provinceChosenFull) {
                    break;
                }

            }

            // console.log("data is ", extractedData)
            return extractedData
        }
        setOwnerExtractedInfo(extractedInfoFromAddress())



    }


    const CardDetailsInfo = () => {
        const stripe: any = useStripe();
        const elements: any = useElements();

        const collectCardDetails = () => {
            let cardInfo = elements.getElement(PaymentElement)

           
        }


        return (
            <>
                {/* <PaymentElement options={{ layout:"tabs", paym}} /> */}
                <PaymentElement options={{
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'],
                    layout: "tabs"
                }}
                />
                <div className="payout-cta">
                    <button
                        disabled={pageProps.createStorePayoutRequest?.is_request_processing}
                        className={pageProps.createStorePayoutRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                        onClick={() => collectCardDetails()}
                    >
                        {pageProps.createStorePayoutRequest.is_request_processing ? "Please wait..." : "Verify information"}
                    </button>
                </div>
                {/* <PaymentElement options={{fields:{billingDetails}}} /> */}
            </>
        )
    }


    return (
        <Modal className="payout_wrap" show={showPayoutModal} onHide={() => setShowPayoutModal(false)}>
            {
                tokenRequest.request_status !== "CREATE_PAYOUT_TOKEN_SUCCESS" &&
                // tokenRequest.request_status !== "CREATE_PAYOUT_TOKEN_RESET" &&
                <>
                    <Modal.Header closeButton>
                        {/* <div className="reason_heading">Reason for return </div> */}

                        {/* <div className="send-head"></div> */}
                        Add withdrawal method
                    </Modal.Header>
                    <Modal.Body>
                        <div className="heading-help">
                            To add a withdrawal method to your account, select one of the  channels below and fill in the appropriate information
                        </div>

                        <Tab.Container defaultActiveKey="bank_info">
                            <Nav className="acc_details_tabs">
                                <Nav.Item>
                                    <Nav.Link eventKey="bank_info">
                                        Bank details
                                        <span className="underline"></span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="card_info">
                                        Card details
                                        <span className="underline"></span>
                                    </Nav.Link>
                                </Nav.Item>

                            </Nav>

                            <Tab.Content className="acc_details_tabs_content">
                                <Tab.Pane eventKey="card_info">
                                <Elements stripe={stripePromiseInfo}>
                                        <CardPayoutFormWrap setCardTokenized={setCardTokenized} />
                                    </Elements>

                                    {/* <div className="card_coming_soon">
                                        Coming soon
                                    </div> */}
                                </Tab.Pane>
                                <Tab.Pane eventKey="bank_info">
                                    <Formik
                                        initialValues={bankPayoutInitialValues}
                                        validationSchema={bankDetailsValidationSchema}
                                        onSubmit={(values: any, actions: any) => {
                                            setCardTokenized("")

                                            let payload: any = {
                                                accountNumber: values.accountNumber,
                                                accountHolderName: values.accountHolderName,
                                                currency: values.currency,
                                                accountHolderType: parseInt(values.accountHolderType),
                                                routingNumber: `${values.routingNumber}-${values.institutionNumber}`,
                                                accountype: parseInt(values.accountype),


                                            }
                                            setPayoutType("bank")
                                            setDataToTokenize(payload)

                                        }}
                                    >
                                        {(props: FormikProps<any>) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                setFieldTouched,
                                                handleChange,
                                                setFieldValue,
                                            } = props;
                                            return (
                                                <Form>
                                                    <div className="form-wrap withdrawal-form">
                                                        <div className="form-group">
                                                            <label htmlFor="">Account number</label>
                                                            <Field
                                                                id="accountNumber"
                                                                value={allowNumbersOnly(values.accountNumber)}
                                                                name="accountNumber"
                                                                type="text"
                                                                // pattern="[\d| ]{16,22}"
                                                                placeholder="0000 000"
                                                                onChange={handleChange}
                                                                className={`input-wrap ${errors.accountNumber && touched.accountNumber ?
                                                                    "is-invalid-input "
                                                                    : values.accountNumber !== "" ? "valid-input" : ""}`}
                                                            />
                                                            <ErrorMessage name="accountNumber" className="form-input-error" component="div" />
                                                        </div>
                                                        <div className="two-sided">
                                                            <div className="form-group">
                                                                <label htmlFor="">Bank transit number</label>
                                                                <Field
                                                                    id="routingNumber"
                                                                    value={allowNumbersOnly(values.routingNumber)}
                                                                    name="routingNumber"
                                                                    type="text"
                                                                    placeholder="Bank transit number"
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.routingNumber && touched.routingNumber ?
                                                                        "is-invalid-input "
                                                                        : values.routingNumber !== "" ? "valid-input" : ""}`}
                                                                />
                                                                <ErrorMessage name="routingNumber" className="form-input-error" component="div" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Institution number</label>
                                                                <Field
                                                                    id="institutionNumber"
                                                                    value={allowNumbersOnly(values.institutionNumber)}
                                                                    name="institutionNumber"
                                                                    type="text"
                                                                    placeholder="0 0 0"
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.institutionNumber && touched.institutionNumber ?
                                                                        "is-invalid-input "
                                                                        : values.institutionNumber !== "" ? "valid-input" : ""}`}
                                                                />
                                                                <ErrorMessage name="institutionNumber" className="form-input-error" component="div" />
                                                            </div>
                                                        </div>
                                                        <div className="two-sided">
                                                            <div className="form-group">
                                                                <label htmlFor="">Account type</label>
                                                                <select name="accountype"
                                                                    value={values.accountype} id=""
                                                                    // onChange={(e: any) => {

                                                                    //     setPayoutAccountCity(e.target.value)
                                                                    // }}
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.accountype && touched.accountype ?
                                                                        "is-invalid-input "
                                                                        : values.accountype !== "" ? "valid-input" : ""}`}

                                                                >
                                                                    <option value="">Select account type</option>
                                                                    <option value="1">Savings</option>
                                                                    <option value="2">Checking</option>
                                                                </select>
                                                                <ErrorMessage name="accountype" className="form-input-error" component="div" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="">Account Mode</label>
                                                                <select name="accountHolderType" value={values.accountHolderType} id=""
                                                                    // onChange={(e: any) => {

                                                                    //     setPayoutAccountCity(e.target.value)
                                                                    // }}
                                                                    onChange={handleChange}
                                                                    className={`input-wrap ${errors.accountHolderType && touched.accountHolderType ?
                                                                        "is-invalid-input "
                                                                        : values.accountHolderType !== "" ? "valid-input" : ""}`}

                                                                >
                                                                    <option value="">Select account mode</option>
                                                                    <option value="1">Individual</option>
                                                                    <option value="2">Company</option>
                                                                </select>
                                                                <ErrorMessage name="accountHolderType" className="form-input-error" component="div" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Bank Name</label>
                                                            <Field
                                                                id="accountHolderName"
                                                                value={values.accountHolderName}
                                                                name="accountHolderName"
                                                                type="text"
                                                                placeholder="Bank Name"
                                                                onChange={handleChange}
                                                                className={`input-wrap ${errors.accountHolderName && touched.accountHolderName ?
                                                                    "is-invalid-input "
                                                                    : values.accountHolderName !== "" ? "valid-input" : ""}`}
                                                            />
                                                            <ErrorMessage name="accountHolderName" className="form-input-error" component="div" />
                                                        </div>
                                                    </div>
                                                    {tokenRequest.request_status === "CREATE_PAYOUT_TOKEN_FAILURE" &&
                                                        <AlertMsg type="error" message={pageProps.createPayoutInfoTokenRequest?.request_data.error} />
                                                    }
                                                    <div className="payout-cta">
                                                        <button
                                                            disabled={tokenRequest?.is_request_processing}

                                                            className={tokenRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                                                        >
                                                            {tokenRequest.is_request_processing ? "Please wait..." : "Verify information"}
                                                        </button>
                                                    </div>
                                                </Form>
                                            )
                                        }}

                                    </Formik>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Modal.Body>
                </>
            }

            {
                tokenRequest.request_status === "CREATE_PAYOUT_TOKEN_SUCCESS" &&
                <>
                    <Modal.Header closeButton>
                        {/* <div className="reason_heading">Reason for return </div> */}

                        {/* <div className="send-head"></div> */}
                        Verify information
                    </Modal.Header>
                    <Modal.Body>
                        <div className="heading-help">
                            By confirming the accuracy of your details, you can trust that your withdrawals are safe and secure on our platform
                        </div>
                        <Formik
                            initialValues={storeFormInitValues}
                            validationSchema={detailsValidationSchema}
                            onSubmit={(values: any, actions: any) => {
                                let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
                                loggedAccountInfo = JSON.parse(loggedAccountInfo);


                                let payload: any = {
                                    storeId: loggedAccountInfo.selectedStore.id,
                                    country: "CA",
                                    email: values.email,
                                    addressLine1: ownerAddressSearched?.label,
                                    // addressLine2: values.addressLine2,
                                    city: values.city || storeFormInitValues?.ownerCity,
                                    // city: values.city,
                                    state: values.state || storeFormInitValues?.ownerState,
                                    phone: `+${values.phone}`,
                                    name: `${values.firstName} ${values.lastName}`,
                                    postalCode: values.postalCode || storeFormInitValues?.ownerPostCode,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    externalAccountToken: tokenRequest.request_data.response,
                                    ip:ipAddress
                                }

                                // createPayoutAccAction({ pageProps, payload })
                                setPayoutAccountData(payload)

                            }}
                        >
                            {(props: FormikProps<any>) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    setFieldTouched,
                                    handleChange,
                                    setFieldValue,
                                } = props;
                                return (
                                    <Form>
                                        <div className="form-wrap withdrawal-form">
                                            <div className="">
                                                <div className="two-sided">
                                                    <div className="form-group">
                                                        <label htmlFor="">First Name</label>
                                                        <Field
                                                            id="firstName"
                                                            value={values.firstName}
                                                            name="firstName"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.firstName && touched.firstName ?
                                                                "is-invalid-input "
                                                                : values.firstName !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="firstName" className="form-input-error" component="div" />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="">Last Name</label>
                                                        <Field
                                                            id="lastName"
                                                            value={values.lastName}
                                                            name="lastName"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.lastName && touched.lastName ?
                                                                "is-invalid-input "
                                                                : values.lastName !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="lastName" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="two-sided">

                                                    <div className="form-group">
                                                        <label htmlFor="">Phone Number</label>
                                                        {/* <Field
                                                            id="phone"
                                                            value={values.phone}
                                                            name="phone"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.phone && touched.phone ?
                                                                "is-invalid-input "
                                                                : values.phone !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="phone" className="form-input-error" component="div" /> */}
                                                        <PhoneInput
                                                            country={'ca'}
                                                            enableTerritories={false}
                                                            enableSearch={true}
                                                            disableSearchIcon={true}
                                                            inputClass={`input-wrap ${errors.phone && touched.phone ?
                                                                "is-invalid-input "
                                                                : values.phone !== "" ? "valid-input" : ""}`}
                                                            value={values.phone}
                                                            onChange={(phone: string): void => {

                                                                setFieldTouched("phone", true)
                                                                setFieldValue("phone", phone)
                                                            }}
                                                        />
                                                        <ErrorMessage name="phone" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Email</label>
                                                        <Field
                                                            id="email"
                                                            value={values.email}
                                                            name="email"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.email && touched.email ?
                                                                "is-invalid-input "
                                                                : values.email !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="email" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="form-group address-wrap">
                                                    <label htmlFor="">Home address</label>
                                                    {/* <Field
                                                            id="postalCode"
                                                            value={values.postalCode}
                                                            name="postalCode"
                                                            type="text"
                                                            placeholder="Enter your home address"
                                                            onChange={handleChange}
                                                            className={`input-wrap ${errors.postalCode && touched.postalCode ?
                                                                "is-invalid-input "
                                                                : values.postalCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="postalCode" className="form-input-error" component="div" /> */}
                                                    <GooglePlacesAutocomplete
                                                        selectProps={{
                                                            value: ownerAddressSearched,
                                                            // onChange: handleSelectedOwnerAddress,
                                                            onChange: (address) => {
                                                                setFieldValue("postalCode", "")
                                                                handleSelectedOwnerAddress(address)

                                                                setFieldValue("addressLine1", "set")
                                                            },
                                                            placeholder: "Enter your home address",
                                                            openMenuOnClick: false,
                                                            classNamePrefix: "store-address-select",
                                                            noOptionsMessage: () => 'Type a valid address'
                                                        }}

                                                        autocompletionRequest={{
                                                            componentRestrictions: {
                                                                country: ['ca'],
                                                            }
                                                        }}
                                                        apiKey={appConstants.GOOGLE_MAP_KEY}
                                                    />
                                                </div>

                                                <div className="two-sided">

                                                    <div className="form-group">
                                                        <label htmlFor="">City</label>
                                                        {/* <Field
                                                    id="city"
                                                    value={values.city}
                                                    name="city"
                                                    type="text"
                                                    placeholder=""
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.city && touched.city ?
                                                        "is-invalid-input "
                                                        : values.city !== "" ? "valid-input" : ""}`}
                                                /> */}
                                                        <Field
                                                            id="city"
                                                            value={values.city || storeFormInitValues?.ownerCity}
                                                            name="city"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={() => { }}
                                                            className={`input-wrap ${errors.city && touched.city ?
                                                                "is-invalid-input "
                                                                : values.city !== "" ? "valid-input" : ""}`}
                                                        />

                                                        <ErrorMessage name="city" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">State</label>
                                                        <Field
                                                            id="state"
                                                            value={values.state || storeFormInitValues?.ownerState}
                                                            name="state"
                                                            type="text"
                                                            placeholder="State"
                                                            onChange={() => { }}
                                                            className={`input-wrap ${errors.state && touched.state ?
                                                                "is-invalid-input "
                                                                : values.state !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="state" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                                <div className="two-sided">
                                                    <div className="form-group">
                                                        <label htmlFor="">Postal Code</label>
                                                        <Field
                                                            id="postalCode"
                                                            value={values.postalCode || storeFormInitValues?.ownerPostCode}
                                                            name="postalCode"
                                                            type="text"
                                                            placeholder=""
                                                            onChange={(e: any) => {
                                                                setFieldValue("postalCode", e.target.value.trim())
                                                                if (e.target.value.trim().length >= 5) {
                                                                    // if (postalCodeAreaData) {
                                                                    setNewPostalCode(e.target.value.trim())
                                                                    // } 
                                                                    // else {
                                                                    //     setAddressSearched({
                                                                    //         label: values.newAddress,
                                                                    //         value: values.newAddress
                                                                    //     })
                                                                    //     setIsAddressPrefil(false)
                                                                    // }
                                                                }
                                                            }}
                                                            className={`input-wrap ${errors.postalCode && touched.postalCode ?
                                                                "is-invalid-input "
                                                                : values.postalCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="postalCode" className="form-input-error" component="div" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="">Country</label>
                                                        <Field
                                                            id="country"
                                                            value={values.country}
                                                            name="country"
                                                            disabled
                                                            type="text"
                                                            placeholder=""
                                                            onChange={() => { }}
                                                            className={`input-wrap ${errors.postalCode && touched.postalCode ?
                                                                "is-invalid-input "
                                                                : values.postalCode !== "" ? "valid-input" : ""}`}
                                                        />
                                                        <ErrorMessage name="postalCode" className="form-input-error" component="div" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {pageProps.createStorePayoutRequest.request_status === "CREATE_PAYOUT_ACCOUNT_FAILURE" &&
                                            <AlertMsg type="error" message={pageProps.createStorePayoutRequest?.request_data.error} />
                                        }
                                        <div className="payout-cta sided">
                                            <button
                                                type="button"
                                                disabled={pageProps.createStorePayoutRequest?.is_request_processing}
                                                // className=""
                                                onClick={() => createTokenizeData({
                                                    pageProps,
                                                    payload: "CLEAR"
                                                })}
                                                className={pageProps.createStorePayoutRequest.is_request_processing ? "btn btn-disabled alt-btn" : "btn alt-btn"}
                                            >
                                                <img src={LeftArrow} alt="" /> Go Back
                                            </button>
                                            <button
                                                disabled={pageProps.createStorePayoutRequest?.is_request_processing}
                                                // className=""
                                                className={pageProps.createStorePayoutRequest.is_request_processing ? "btn btn-disabled" : "btn"}
                                            >
                                                {pageProps.createStorePayoutRequest.is_request_processing ? "Please wait..." : "Add withdrawal method"}
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }}

                        </Formik>
                    </Modal.Body>
                </>
            }
        </Modal>
    )
}

const InAppContent = ({ pageProps }: any) => {
    const [salesFlag, setSalesFlag] = useState(1);
    const screenResolution: any = useWindowSize();
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    let getDashbardTopDataRequest = pageProps.getDashbardTopDataRequest;
    let getDashbardMiddleDataRequest = pageProps.getDashbardMiddleDataRequest;
    let getDashbardBottompDataRequest = pageProps.getDashbardBottompDataRequest;
    const [stripePromiseInfo, setStripePromise] = useState<any>();

    useEffect(() => {



        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let storeId = loggedAccountInfo.selectedStore.id;
        let payload = `StoreId=${storeId}`;
        let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;


        getTopData({
            pageProps,
            payload: "CLEAR"
        })

        getMiddleData({
            pageProps,
            payload: "CLEAR"
        })

        getBottomData({
            pageProps,
            payload: "CLEAR"
        })

        getTopData({
            pageProps,
            payload
        })

        getMiddleData({
            pageProps,
            payload
        })

        getBottomData({
            pageProps,
            payload,
            payload2: graphPayload
        })




    }, [])

    useEffect(() => {



        let loggedAccountInfo: any = localStorage.getItem("mamiVendorXAuth") || "{}";
        loggedAccountInfo = JSON.parse(loggedAccountInfo);
        let storeId = loggedAccountInfo.selectedStore.id;
        let payload = `StoreId=${storeId}`;
        let graphPayload = `StoreId=${storeId}&ReportGroupingType=${salesFlag}`;




        getBottomData({
            pageProps,
            payload: "CLEAR"
        })



        getBottomData({
            pageProps,
            payload,
            payload2: graphPayload
        })




    }, [salesFlag])

    useEffect(() => {
        if (getDashbardTopDataRequest?.request_status === "GET_DASHBOARD_TOP_DATA_SUCCESS") {
            
            const stripePromiseData = loadStripe(pageProps.getDashbardTopDataRequest.request_data?.publishkey?.publishableKey);

            setStripePromise(stripePromiseData)
        }




    }, [getDashbardTopDataRequest?.request_status])


    const ReviewSummary = ({ ReviewData }: any) => {

        let negativeFeedbackCount: any = 0,

            positiveFeedbackCount: any = 0,

            neutralFeedbackCount: any = 0,

            totalReviewsCount: any = 0;
        let allReviews: any[] = ReviewData;





        allReviews.forEach((eachReview: any) => {

            if (eachReview?.rating >= 4) {
                positiveFeedbackCount += eachReview?.count
            }
            if (eachReview?.rating === 3) {
                neutralFeedbackCount += eachReview?.count
            }
            if (eachReview?.rating < 3) {
                negativeFeedbackCount += eachReview?.count
            }

            totalReviewsCount += eachReview?.count
        })

        let positiveDiff = (positiveFeedbackCount / totalReviewsCount) * 100,
            positiveReviewPerCent = Math.round((positiveDiff / parseFloat(totalReviewsCount)) * 100);

        let neutralDiff = (neutralFeedbackCount / totalReviewsCount) * 100,
            neutralReviewPerCent = Math.round((neutralDiff / parseFloat(totalReviewsCount)) * 100);

        let negativeDiff = (negativeFeedbackCount / totalReviewsCount) * 100,
            negativeReviewPerCent = Math.round((negativeDiff / parseFloat(totalReviewsCount)) * 100);


        return (
            <>
                <div className="all-bands">
                    <div className="each-band negative" style={{
                        width: `${negativeDiff}%`,
                        // marginLeft: `${(positiveFeedbackCount===0 && neutralFeedbackCount===0 && negativeFeedbackCount>=1)?0:"15px"}`,
                    }}></div>
                    <div className="each-band neutral" style={{
                        width: `${neutralDiff}%`,
                        marginLeft: `${(negativeFeedbackCount === 0 || (neutralFeedbackCount >= 1 && negativeFeedbackCount === 0)) ? 0 : "15px"}`,
                    }}></div>
                    <div className="each-band positive" style={{
                        width: `${positiveDiff}%`,
                        marginLeft: `${(negativeFeedbackCount >= 1 || neutralFeedbackCount >= 1) ? "15px" : 0}`,
                    }}></div>
                </div>
                <div className="all-rating-cap">
                    <div className="each-cap">
                        <div className="heading">Negative</div>
                        <div className="icon-wrap"><img src={NegativeIco} alt="" /> {negativeFeedbackCount} </div>
                    </div>
                    <div className="each-cap">
                        <div className="heading">Neutral</div>
                        <div className="icon-wrap"><img src={NeutralIco} alt="" /> {neutralFeedbackCount} </div>
                    </div>
                    <div className="each-cap">
                        <div className="heading">Positive</div>
                        <div className="icon-wrap"><img src={PositiveIco} alt="" /> {positiveFeedbackCount} </div>
                    </div>
                </div>
            </>
        )
    }

    const TotalPopularProducts = ({ TotalData }: any) => {
        const sum = TotalData.reduce((accumulator: any, object: any) => {
            return accumulator + object.subTotal;
        }, 0);
        return (
            <div className="chartdata-inner">
                <img src={WalletIco} alt="" />
                <span>${sum}</span>
            </div>
        )
    }

    const PopularProductStats = ({ AllProducts }: any) => {
        var palette: any[] = distinctColors({ count: AllProducts.length }) || []

        const options5 = {
            responsive: true,
            maintainAspectRatio: true,
            cutout: screenResolution?.width <= 767 ? 60 : 45,
            // outerWidth: 100,
            plugins: {
                legend: {
                    display: false,
                    position: 'top' as const,
                },
                title: {
                    display: false,
                    // text: 'Chart.js Line Chart',
                },
            },
            tension: 0.5
        };

        let chartColors: any[] = palette.map((each: any) => `${each}`)
        let chartValues: any[] = AllProducts.map((each: any) => each?.subTotal)
        let chartLabels: any[] = AllProducts.map((each: any) => each?.name)


        const data4 = {
            labels: [...chartLabels],
            // width: 100,
            datasets: [
                {
                    label: '',
                    data: [...chartValues],
                    backgroundColor: [
                        ...chartColors
                    ],
                    borderColor: [
                        ...chartColors
                    ],
                    borderWidth: 0,
                },
            ],
        };
        return (
            <div className={screenResolution?.width <= 767 ? "small-chartdata" : ""}>
                <div className="chartdata-wrap">
                    <Doughnut options={options5} data={data4} />
                    <TotalPopularProducts TotalData={AllProducts} />
                </div>
                <div className=" statinfo">
                    {
                        AllProducts.map((eachItem: any, index: any) => {
                            return (
                                <div className="data-stat" key={index}>
                                    <div className="label-data"

                                    >
                                        <div className="data-count">
                                            <span>{eachItem?.name}</span>
                                            {/* <span>Fashion</span> */}
                                        </div>
                                        <div className="data-percent"> ${eachItem?.subTotal}</div>
                                    </div>
                                    <div className="custom-progress">
                                        <div className="progress-inner"
                                            style={{
                                                backgroundColor: palette[index],
                                                width: Math.round(eachItem?.percentage)
                                            }}
                                        ></div>
                                    </div>
                                    {/* <ProgressBar className="lightgreen" now={Math.round(eachItem?.percentage)} variant="lightgreen" /> */}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    const SalesChart = ({ salesData }: any) => {

        let chartValues: any[] = salesData.map((each: any) => each?.count)
        let chartLabels: any[] = salesData.map((each: any) => each?.grouping)
        const data6 = {
            labels: chartLabels,
            datasets: [
                {
                    label: '',
                    data: chartValues,
                    borderColor: '#00A85B',
                    borderWidth: 2,
                    // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                // {
                //     label: '',
                //     data: labelValues2,
                //     borderColor: 'rgb(80, 143, 244)',
                //     borderWidth: 5,
                //     // backgroundColor: 'rgba(255, 99, 132, 0.5)',
                // },

            ],
        };
        return (
            <>
                {salesData.length >= 1 &&
                    <Line options={options} data={data6} />
                }

                {salesData.length === 0 &&
                    <div className="no-data">No Data for specified period</div>
                }
            </>
        )
    }

    const downloadChart = () => {
        // setIsPrintingInvoice(true)
        let chartElem = document.getElementById("chart-sales");
        if (chartElem) {
            const mainChartElem: HTMLElement = chartElem!;
            // html2canvas(orderElem).then(canvas => {
            //     document.body.appendChild(canvas)
            // });
            exportAsImage(mainChartElem, `Sales-Chart`);
        }



    }

    // console.log("getDashbardBottompDataRequest", getDashbardBottompDataRequest);
    return (
        <div className="inapp-content">
            {/* <div className="page-heading">Dashboard</div> */}
            {stripePromiseInfo && showPayoutModal &&
            <CreatePayoutAccountModal
                pageProps={pageProps}
                showPayoutModal={showPayoutModal}
                stripePromiseInfo={stripePromiseInfo}
                setShowPayoutModal={setShowPayoutModal}
            />}
            <div className="page-content dashboard">
                {getDashbardTopDataRequest?.request_status === "GET_DASHBOARD_TOP_DATA_SUCCESS" &&
                    <div className="top-section">
                        {/* <img src={LoadingIco} alt="" /> */}

                        <div className="each-topsection counts">
                            <div className="each-count">
                                <div className="count-left">
                                    <div className="item-icon"><img src={OrderIco} alt="" /></div>
                                    <div className="item-name">Total orders</div>
                                </div>
                                <div className="count-right">{getDashbardTopDataRequest?.request_data?.response?.orders?.totalOrder}</div>
                            </div>
                            <div className="each-count">
                                <div className="count-left">
                                    <div className="item-icon"><img src={ProductsIco} alt="" /></div>
                                    <div className="item-name">No of products</div>
                                </div>
                                <div className="count-right">{getDashbardTopDataRequest?.request_data?.response?.orders?.numberOfProducts}</div>
                            </div>
                            <div className="each-count">
                                <div className="count-left">
                                    <div className="item-icon"><img src={AveOrderIco} alt="" /></div>
                                    <div className="item-name">Average order</div>
                                </div>
                                <div className="count-right">${Math.round(getDashbardTopDataRequest?.request_data?.response?.orders?.averageOrder)}</div>
                            </div>
                        </div>

                        <div className="each-topsection customers">
                            <div className="each-data">
                                <div className="top-item">
                                    <div className="count-txt">0</div>
                                    <div className="item-icon"><img src={DiscountIco} /></div>
                                </div>
                                <div className="item-name-txt">Discounts</div>
                                <div className="percent-txt">
                                    <img src={ArrowGr} alt="" />
                                    <span className="gr">50%</span> from last month
                                </div>
                            </div>
                            <div className="each-data">
                                <div className="top-item">
                                    <div className="count-txt">{getDashbardTopDataRequest?.request_data?.response?.customer}</div>
                                    <div className="item-icon"><img src={CustAll} /></div>
                                </div>
                                <div className="item-name-txt">Customers</div>
                                <div className="percent-txt">
                                    <img src={ArrowRed} alt="" />
                                    <span className="down">30%</span> from last month
                                </div>
                            </div>
                        </div>
                        <div className="each-topsection withdrawal" onClick={() => setShowPayoutModal(true)}>
                            <div className="inner">
                                <img src={WithdrawalIco} />
                                <div className="withdrawal-txt">Add a withdrawal method</div>
                            </div>
                        </div>
                    </div>
                }
                {getDashbardTopDataRequest?.request_status === "GET_DASHBOARD_TOP_DATA_PENDING" &&
                    <div className="top-section">
                        {/* <img src={LoadingIco} alt="" /> */}

                        <div className="each-topsection counts with-loader">
                            <img src={LoadingIco} alt="" />
                        </div>

                        <div className="each-topsection customers with-loader">
                            <img src={LoadingIco} alt="" />
                        </div>
                        <div className="each-topsection withdrawal">
                            <div className="inner">
                                <img src={WithdrawalIco} />
                                <div className="withdrawal-txt">Add a withdrawal method</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="mid-section">
                    <div className="left-side">
                        {getDashbardMiddleDataRequest?.request_status === "GET_DASHBOARD_MIDDLE_DATA_SUCCESS" &&
                            <div className="review-summary">
                                <div className="heading-txt">Community Reviews</div>
                                <ReviewSummary ReviewData={getDashbardMiddleDataRequest?.request_data?.response} />



                            </div>
                        }
                        {getDashbardMiddleDataRequest?.request_status === "GET_DASHBOARD_MIDDLE_DATA_PENDING" &&
                            <div className="review-summary">
                                <div className="heading-txt">Community Reviews</div>
                                <div className="with-loader">
                                    <img src={LoadingIco} alt="" />
                                </div>
                            </div>
                        }

                        {screenResolution?.width <= 767 &&
                            <div className="right-side">
                                <div className="head-wrap">
                                    <div className="head-txt">Popular Products</div>
                                    <select name="" id="">
                                        <option value="7days">Last 7days</option>
                                        <option value="30days">Last 30days</option>
                                    </select>
                                </div>
                                {(getDashbardBottompDataRequest?.request_status === "GET_DASHBOARD_BOTTOM_DATA_SUCCESS") &&
                                    <>

                                        {/* <PopularProductStats AllProducts={getDashbardBottompDataRequest?.request_data?.response?.itemsSummary} /> */}

                                    </>
                                }
                                {getDashbardBottompDataRequest?.request_status === "GET_DASHBOARD_BOTTOM_DATA_PENDING" &&
                                    <div className="statinfo with-loading">
                                        <img src={LoadingIco} alt="" />
                                    </div>
                                }
                            </div>
                        }
                        <div className="other-section horizontal">
                            <div className="sales-wrap">
                                {getDashbardBottompDataRequest?.request_status === "GET_DASHBOARD_BOTTOM_DATA_SUCCESS" &&
                                    <div className="all-sale-stats cards">
                                        <div className="stats-heading">
                                            <div className="heading-txt">Sales report</div>
                                            <div className="stats-heading">
                                                <div className={salesFlag === 1 ? "each-stat-head  active" : "each-stat-head"} onClick={() => setSalesFlag(1)} >6 Months</div>
                                                <div className={salesFlag === 3 ? "each-stat-head  active" : "each-stat-head"} onClick={() => setSalesFlag(3)}  >7 days</div>
                                                {/* <div className="each-stat-head">30 days</div> */}
                                            </div>
                                            {screenResolution.width >= 768 &&
                                                <div className="export-cta" onClick={() => downloadChart()}>
                                                    <img src={ExportIco} alt="" />
                                                    Export PDF
                                                </div>
                                            }
                                        </div>
                                        <div className="allstats">

                                            <div className="each-chartwrap" id="chart-sales">
                                                <SalesChart salesData={getDashbardBottompDataRequest.request_data?.response?.graph} />

                                            </div>
                                        </div>
                                    </div>
                                }
                                {getDashbardBottompDataRequest?.request_status === "GET_DASHBOARD_BOTTOM_DATA_PENDING" &&
                                    <div className="all-sale-stats cards with-loading">
                                        <div className="stats-heading">
                                            <div className="heading-txt">Sales report</div>
                                        </div>
                                        <div className="allstats">
                                            <img src={LoadingIco} alt="" />
                                        </div>
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                    {screenResolution?.width >= 768 &&
                        <div className="right-side">
                            <div className="head-wrap">
                                <div className="head-txt">Popular Products</div>
                                <select name="" id="">
                                    <option value="7days">Last 7days</option>
                                    <option value="30days">Last 30days</option>
                                </select>
                            </div>
                            {(getDashbardBottompDataRequest?.request_status === "GET_DASHBOARD_BOTTOM_DATA_SUCCESS") &&
                                <>

                                    {/* <PopularProductStats AllProducts={getDashbardBottompDataRequest?.request_data?.response?.itemsSummary} /> */}

                                </>
                            }
                            {getDashbardBottompDataRequest?.request_status === "GET_DASHBOARD_BOTTOM_DATA_PENDING" &&
                                <div className="statinfo with-loading">
                                    <img src={LoadingIco} alt="" />
                                </div>
                            }
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

const Dashboard = (pageProps: any) => {
    return (
        <InAppTemplate pageHeadingTitle="Dashboard" childComponent={<InAppContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    getDashbardTopData: storeActions.GetDashbardTopData,
    getDashbardMiddleData: storeActions.GetDashbardMiddleData,
    getDashbardBottompData: storeActions.GetDashbardBottompData,

    createPayoutAccountAction: storeActions.CreatePayoutAccount,
    createTokenizeData: storeActions.CreateTokenizeData,
};

const mapStateToProps = (state: any) => ({
    // return {
    getDashbardTopDataRequest: state.allProductReducers.getDashbardTopDataReducer,
    getDashbardMiddleDataRequest: state.allProductReducers.getDashbardMiddleDataReducer,
    getDashbardBottompDataRequest: state.allProductReducers.getDashbardBottompDataReducer,

    createStorePayoutRequest: state.allProductReducers.createStorePayoutReducer,
    createPayoutInfoTokenRequest: state.allProductReducers.createPayoutInfoTokenReducer,
    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);