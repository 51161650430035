import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { useSearchParams, useLocation } from "react-router-dom";
import { OnBoardingFormValues, ILoginProps } from "../../../types"
import GSign from "../../../assets/g-sign.svg"
import { PostBoardingWrap } from "../../../shared/templates/onboarding";
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"

const forgotPasswordAction = async ({ pageProps, payload }: any) => {

    await pageProps.ResetPassword(payload);
}

const PostBoardingContent = ({ pageProps }: any) => {
    useEffect(() => {
        pageProps.logoutAction();
        forgotPasswordAction({
            pageProps,
            payload: "CLEAR"
        })


    }, [])
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let checkValidationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string().required('Required').when("password", {
            is: (val: string | any[]) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
            ),
        }),

    });
    let initialValues: any = {
        password: "",
        confirmPassword: "",
    }
    return (
        <div className="postboarding-content">
            {(pageProps?.resetPasswordRequest?.request_status
                && pageProps.resetPasswordRequest.request_status !== "COMPLETE_PASSWORD_RESET_SUCCESS") &&

                <div className="content-heading">
                    <h2>Reset Password</h2>
                    {/* <div className="content-heading-msg">
                    New Vendor?
                    <Link to="/account/create">Register</Link>
                </div> */}
                </div>
            }

            <div className="form-wrap">
                <Formik
                    initialValues={initialValues}

                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        let { password, confirmPassword } = values;
                        let payload = {
                            confirmPassword,
                            password,
                            email: params.get("email"),
                            token: params.get("token")
                        }
                        // console.log("payload", payload)
                        await forgotPasswordAction({ pageProps, payload })
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            // handleBlur,
                            handleChange,
                            // isSubmitting,
                        } = props;

                        return (
                            <Form>
                                {/* <PersistFormikValues name="signin-form" /> */}
                                {(pageProps?.resetPasswordRequest?.request_status
                                    && pageProps.resetPasswordRequest.request_status !== "COMPLETE_PASSWORD_RESET_SUCCESS") &&
                                    <>
                                        <div className="form-group">
                                            <div className={errors.password && touched.password ? "invalid-input-wrap" : ""}>
                                                <Field
                                                    id="password"
                                                    value={values.password}
                                                    name="password"
                                                    type="password"
                                                    placeholder="Create Password"
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.password && touched.password ?
                                                        "is-invalid-input "
                                                        : values.password !== "" ? "valid-input" : ""}`}
                                                />
                                            </div>
                                            <ErrorMessage name="password" className="form-input-error" component="div" />
                                        </div>
                                        <div className="form-group">
                                            <div className={errors.confirmPassword && touched.confirmPassword ? "invalid-input-wrap" : ""}>
                                                <Field
                                                    id="confirmPassword"
                                                    value={values.confirmPassword}
                                                    name="confirmPassword"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    onChange={handleChange}
                                                    className={`input-wrap ${errors.confirmPassword && touched.confirmPassword ?
                                                        "is-invalid-input "
                                                        : values.confirmPassword !== "" ? "valid-input" : ""}`}
                                                />
                                            </div>
                                            <ErrorMessage name="confirmPassword" className="form-input-error" component="div" />
                                        </div>

                                        {pageProps?.resetPasswordRequest?.request_status
                                            && pageProps.resetPasswordRequest.request_status === "COMPLETE_PASSWORD_RESET_FAILURE" &&
                                            <AlertMsg type="error" message={pageProps.resetPasswordRequest?.request_data.error} />
                                        }
                                        <button
                                            className="main-cta full"

                                            disabled={pageProps.resetPasswordRequest?.is_request_processing}
                                            type="submit">

                                            {pageProps.resetPasswordRequest.is_request_processing ? "Please wait..." : "Continue"}
                                        </button>

                                    </>
                                }
                                {(pageProps?.resetPasswordRequest?.request_status
                                    && pageProps.resetPasswordRequest.request_status === "COMPLETE_PASSWORD_RESET_SUCCESS") &&
                                    <div className="success-message-wrap">
                                        <div className="success-animation">
                                            <svg className="success_icon_tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                                        </div>
                                        <div className="success-message-txt">Password was successfully updated</div>
                                        <div className="form-link">
                                            <Link to="/">Proceed to Login</Link>
                                        </div>
                                    </div>
                                }
                            </Form>
                        )
                    }}
                </Formik>
            </div>


            {(pageProps?.resetPasswordRequest?.request_status
                && pageProps.resetPasswordRequest.request_status !== "COMPLETE_PASSWORD_RESET_SUCCESS") &&
                <div className="optional">
                    <div className="option-txt">OR</div>
                    <button className="alt-cta full all-center">
                        <img className="gsign" src={GSign} alt="Google SignIn" />
                    </button>
                </div>
            }

        </div>
    )
}


const ResetPassword = (pageProps: any) => {
    return (
        <PostBoardingWrap pageHeadingTitle="Reset your password" childComponent={<PostBoardingContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    ResetPassword: authActions.ResetPassword,
    logoutAction: authActions.Logout,
};

const mapStateToProps = (state: any) => ({
    resetPasswordRequest: state.onboardingOutboundReducers.resetPasswordReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);