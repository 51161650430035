export const authConstants ={
    
    LOGIN_USER_SUCCESS : 'LOGIN_USER_SUCCESS',
    LOGIN_USER_PENDING : 'LOGIN_USER_PENDING',
    LOGIN_USER_FAILURE : 'LOGIN_USER_FAILURE',
    LOGIN_USER_RESET : 'LOGIN_USER_RESET',

    CREATE_ACCOUNT_SUCCESS : 'CREATE_ACCOUNT_SUCCESS',
    CREATE_ACCOUNT_PENDING : 'CREATE_ACCOUNT_PENDING',
    CREATE_ACCOUNT_FAILURE : 'CREATE_ACCOUNT_FAILURE',
    CREATE_ACCOUNT_RESET : 'CREATE_ACCOUNT_RESET',

    CONFIRM_EMAIL_SUCCESS : 'CONFIRM_EMAIL_SUCCESS',
    CONFIRM_EMAIL_PENDING : 'CONFIRM_EMAIL_PENDING',
    CONFIRM_EMAIL_FAILURE : 'CONFIRM_EMAIL_FAILURE',
    CONFIRM_EMAIL_RESET : 'CONFIRM_EMAIL_RESET',

    RESEND_ACTIVATION_EMAIL_SUCCESS : 'RESEND_ACTIVATION_EMAIL_SUCCESS',
    RESEND_ACTIVATION_EMAIL_PENDING : 'RESEND_ACTIVATION_EMAIL_PENDING',
    RESEND_ACTIVATION_EMAIL_FAILURE : 'RESEND_ACTIVATION_EMAIL_FAILURE',
    RESEND_ACTIVATION_EMAIL_RESET : 'RESEND_ACTIVATION_EMAIL_RESET',

    GET_URL_OPTIONS_SUCCESS : 'GET_URL_OPTIONS_SUCCESS',
    GET_URL_OPTIONS_PENDING : 'GET_URL_OPTIONS_PENDING',
    GET_URL_OPTIONS_FAILURE : 'GET_URL_OPTIONS_FAILURE',
    GET_URL_OPTIONS_RESET : 'GET_URL_OPTIONS_RESET',

    GET_STORE_CATS_SUCCESS : 'GET_STORE_CATS_SUCCESS',
    GET_STORE_CATS_PENDING : 'GET_STORE_CATS_PENDING',
    GET_STORE_CATS_FAILURE : 'GET_STORE_CATS_FAILURE',
    GET_STORE_CATS_RESET : 'GET_STORE_CATS_RESET',

    GET_VENDOR_PHONE_OTP_SUCCESS : 'GET_VENDOR_PHONE_OTP_SUCCESS',
    GET_VENDOR_PHONE_OTP_PENDING : 'GET_VENDOR_PHONE_OTP_PENDING',
    GET_VENDOR_PHONE_OTP_FAILURE : 'GET_VENDOR_PHONE_OTP_FAILURE',
    GET_VENDOR_PHONE_OTP_RESET : 'GET_VENDOR_PHONE_OTP_RESET',

    CONFIRM_PHONE_SUCCESS : 'CONFIRM_PHONE_SUCCESS',
    CONFIRM_PHONE_PENDING : 'CONFIRM_PHONE_PENDING',
    CONFIRM_PHONE_FAILURE : 'CONFIRM_PHONE_FAILURE',
    CONFIRM_PHONE_RESET : 'CONFIRM_PHONE_RESET',

   
    LOGOUT: 'USERS_LOGOUT',

    LOGOUT_USER_SUCCESS : 'LOGOUT_USER_SUCCESS',
    LOGOUT_USER_PENDING : 'LOGOUT_USER_PENDING',
    LOGOUT_USER_FAILURE : 'LOGOUT_USER_FAILURE',
    LOGOUT_USER_RESET :   'LOGOUT_USER__RESET',

    CHANGE_PASSWORD_SUCCESS : 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_PENDING : 'CHANGE_PASSWORD_PENDING',
    CHANGE_PASSWORD_FAILURE : 'CHANGE_PASSWORD_FAILURE',
    CHANGE_PASSWORD_RESET :   'CHANGE_PASSWORD__RESET',

    FORGOT_PASSWORD_SUCCESS : 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_PENDING : 'FORGOT_PASSWORD_PENDING',
    FORGOT_PASSWORD_FAILURE : 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_RESET :   'FORGOT_PASSWORD_RESET',

  

    COMPLETE_PASSWORD_RESET_SUCCESS : 'COMPLETE_PASSWORD_RESET_SUCCESS',
    COMPLETE_PASSWORD_RESET_PENDING : 'COMPLETE_PASSWORD_RESET_PENDING',
    COMPLETE_PASSWORD_RESET_FAILURE : 'COMPLETE_PASSWORD_RESET_FAILURE',
    COMPLETE_PASSWORD_RESET_RESET :   'COMPLETE_PASSWORD_RESET_RESET',

    UPLOAD_STORE_LOGO_SUCCESS : 'UPLOAD_STORE_LOGO_SUCCESS',
    UPLOAD_STORE_LOGO_PENDING : 'UPLOAD_STORE_LOGO_PENDING',
    UPLOAD_STORE_LOGO_FAILURE : 'UPLOAD_STORE_LOGO_FAILURE',
    UPLOAD_STORE_LOGO_RESET : 'UPLOAD_STORE_LOGO_RESET',   
}