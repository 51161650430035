import { IoMdClose } from 'react-icons/io'
import '../../../scss/help-center.scss'
import { CiImageOn } from "react-icons/ci"
import React, { useState } from 'react'

interface TicketModalProps {
    handleModalClose: () => void;
}
export const TicketModal: React.FC<TicketModalProps> = ({ handleModalClose }) => {
    return (
        <div className="ticketContainer">
            <div className="ticketModal">
                <div className="flex">
                    <h4 className="title">Create support ticket</h4>
                    <div onClick={handleModalClose} style={{cursor: 'pointer'}}>
                        <IoMdClose size={22} />
                    </div>
                </div>
                <div className="flex2">
                    <div>
                        <label className="label">First name</label>
                        <input type='text' placeholder='Jide' className="input" />
                    </div>
                    <div>
                        <label className="label">Last name</label>
                        <input type='text' placeholder='Fabanwo' className="input" />
                    </div>
                </div>
                <div className="column">
                    <label className="label">Email</label>
                    <input type='text' placeholder='jide.fabanwo@gmail.com' className="input" />
                </div>
                <div className="column">
                    <label htmlFor="" className="label">What issue are you having?</label>
                    <select className="select">
                        <option value="select an issue">Select an issue</option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                    </select>
                </div>
                <div className="column">
                    <label className="label">Description</label>
                    <textarea placeholder='Describe the issue to allow us properly assist you' className="textarea" />
                </div>
                <div className="row2">
                    <CiImageOn size={17} color='#00A85B' style={{marginTop: '.2rem'}} />
                    <p className="upload">Upload media</p>
                </div>
                <div className="column">
                    <label htmlFor="" className="label">Priority</label>
                    <select className="select">
                        <option value="select an issue">Select</option>
                        <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option>
                    </select>
                </div>
                <button className="button">Submit ticket</button>
            </div>
        </div>
    )
    }