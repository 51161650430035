import { useState, useEffect } from "react";
import '../../../scss/onboarding.scss';
import { connect } from 'react-redux';
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { OnBoardingFormValues, ILoginProps } from "../../../types"
import GSign from "../../../assets/g-sign.svg"
import PwSeen from "../../../assets/icons/pw-visible.png"
import PwHide from "../../../assets/icons/pw-hidden.png"
import { PostBoardingWrap } from "../../../shared/templates/onboarding";
import { authActions } from "../../../redux/actions/onboarding"
import { AlertMsg } from "../../../shared/components/alert-msg"

const handleLogin = async ({ pageProps, payload }: any) => {

    await pageProps.LoginAction(payload);
}

const handleresendActivation = async ({ pageProps, payload }: any) => {

    await pageProps.resendActivationEmailAction(payload);
}

const PostBoardingContent = ({ pageProps }: any) => {
    const [isPwShown, setShowPw] = useState<boolean>(false)
    const [emailTxt, setEmailTxt] = useState<string>("")
    useEffect(() => {
        pageProps.logoutAction();
        handleLogin({
            pageProps,
            payload: "CLEAR"
        })

        handleresendActivation({
            pageProps,
            payload: "CLEAR"
        })


    }, [])
    let checkValidationSchema = Yup.object().shape({
        username: Yup.string().trim()
            .email('Valid email is required')
            .required('Required'),
        password: Yup.string()
            .required('Required'),

    });
    let initialValues: any = { username: "", password: "" }
    return (
        <div className="postboarding-content">
            <div className="content-heading">
                <h2>Log in to your vendor account</h2>
                <div className="content-heading-msg">
                    Manage your products, track sales, and stay connected with your customers
                </div>
            </div>
            <div className="form-wrap">
                <Formik
                    initialValues={initialValues}

                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        let { username, password } = values;
                        let payload: any = {
                            username,
                            password,
                            grant_type: "password",
                            scope: "api1.read IdentityServerApi",
                            client_secret: "SuperSecretPassword",
                            // client_secret: process.env.REACT_APP_IDENTTITY_SECRET,
                            client_id: "oauthClient"
                        }
                        setEmailTxt(username)
                        var formBody: any = [];
                        for (var property in payload) {
                            var encodedKey = encodeURIComponent(property);
                            var encodedValue = encodeURIComponent(payload[property]);
                            formBody.push(encodedKey + "=" + encodedValue);
                        }
                        formBody = formBody.join("&");
                        // setLoginData(formBody)
                        await handleLogin({ pageProps, payload: formBody })
                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            // handleBlur,
                            handleChange,
                            // isSubmitting,
                        } = props;

                        return (
                            <Form>
                                {/* <PersistFormikValues name="signin-form" /> */}
                                <div>
                                    <label className="label">Email</label>
                                    <div className="form-group">
                                        <div className={errors.username && touched.username ? "invalid-input-wrap" : ""}>
                                            <Field
                                                value={values.username}
                                                id="username"
                                                name="username"
                                                type="text"
                                                onChange={handleChange('username')}
                                                placeholder="sample@gmail.com"
                                                className={`input-wrap ${errors.username && touched.username ?
                                                    "is-invalid-input "
                                                    : !errors.username && values.username !== "" ? "valid-input" : ""}`}
                                            />
                                        </div>
                                        <ErrorMessage name="username" className="form-input-error" component="div" />
                                    </div>
                                </div>
                                <div>
                                    <label className="label">Password</label>
                                    <div className="form-group">
                                        <div className={errors.password && touched.password ? "invalid-input-wrap input-with-icon" : "input-with-icon"}>
                                            <Field
                                                id="password"
                                                value={values.password}
                                                name="password"
                                                type={!isPwShown ? "password" : "text"}
                                                placeholder="Enter your password here"
                                                onChange={handleChange}
                                                className={`input-wrap ${errors.password && touched.password ?
                                                    "is-invalid-input "
                                                    : values.password !== "" ? "valid-input" : ""}`}
                                            />
                                            <div className="show-hide" onClick={() => setShowPw(!isPwShown)}>
                                                {isPwShown && <img src={PwHide} alt="" />}
                                                {!isPwShown && <img src={PwSeen} alt="" />}
                                            </div>
                                        </div>
                                        <ErrorMessage name="password" className="form-input-error" component="div" />
                                    </div>
                                </div>
                                {pageProps?.loginRequest?.request_status
                                    && pageProps.loginRequest.request_status === "LOGIN_USER_FAILURE" &&
                                    <>
                                        {pageProps?.resendActivationEmailRequest?.request_status === "RESEND_ACTIVATION_EMAIL_RESET" &&
                                            <AlertMsg type="error" message={pageProps.loginRequest?.request_data.error} />
                                        }
                                        {pageProps?.resendActivationEmailRequest?.request_status === "RESEND_ACTIVATION_EMAIL_FAILURE" &&
                                            <AlertMsg type="error" message={pageProps.resendActivationEmailRequest?.request_data.error} />
                                        }
                                        {pageProps?.resendActivationEmailRequest?.request_status === "RESEND_ACTIVATION_EMAIL_SUCCESS" &&
                                            <AlertMsg type="success" message="Activation email has been resent to you" />
                                        }

                                        {(pageProps.loginRequest?.request_data.error.includes("not yet activated") && pageProps?.resendActivationEmailRequest?.request_status !== "RESEND_ACTIVATION_EMAIL_SUCCESS") &&
                                            <div className="form-link resend-link"
                                                onClick={async () => {
                                                    await handleresendActivation({ pageProps, payload: `${emailTxt}ll` })
                                                }}
                                            >
                                                Click here to resend activation email
                                                {/* <Link to="/account/forgot-password"></Link> */}
                                            </div>

                                        }
                                    </>
                                }
                                <div className="space-between">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" />
                                        <label className="label" style={{ marginLeft: '.5rem' }}>Remember password</label>
                                    </div>
                                        <Link to="/account/forgot-password" className="forgot">Forgot Password?</Link>
                                </div>
                                <button
                                    className="main-cta full"
                                    //         className={`btn form-btn full-width 
                                    // ${((Object.keys(errors).length === 0 && Object.keys(touched).length >= 1)
                                    //                 || pageProps.loginRequest?.is_request_processing
                                    //             )
                                    //                 ? " btn-main " : "btn-frozen"}`
                                    //         }
                                    disabled={pageProps.loginRequest?.is_request_processing}
                                    type="submit">

                                    {pageProps.loginRequest.is_request_processing ? "Please wait..." : "Log in"}
                                </button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
            <div className="optional">
                <span className="span">Don&apos;t have an account yet?<a href='/' style={{marginTop: '-.1rem'}}> Sign up</a></span>
            </div>

        </div>
    )
}


const LogIn = (pageProps: any) => {
    return (
        <PostBoardingWrap pageHeadingTitle="Sign in or Create your Account" childComponent={<PostBoardingContent pageProps={pageProps} />} />
    )
}


const mapDispatchToProps = {
    LoginAction: authActions.Login,
    logoutAction: authActions.Logout,
    resendActivationEmailAction: authActions.ResendActivationEmail,
};

const mapStateToProps = (state: any) => ({
    loginRequest: state.onboardingOutboundReducers.loginReducer,
    resendActivationEmailRequest: state.onboardingOutboundReducers.resendActivationEmailReducer,
})
export default connect(mapStateToProps, mapDispatchToProps)(LogIn);