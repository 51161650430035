import { connect } from 'react-redux';
import { useState } from 'react';
import { NavLink } from "react-router-dom"
import { InAppTemplate } from "../../../shared/templates/inapp";
import { Formik, Form, Field, FormikProps, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Mail from "../../../assets/icons/mail2.svg"
import Walmart from "../../../assets/icons/walmart.svg"
import { TableComponent } from "../../../shared/components/table"
import Modal from 'react-bootstrap/Modal'

const InAppContent = () => {

    let detailsValidationSchema = Yup.object().shape({
        reasonForLeaving: Yup.string()
            .required("Required")

    });
    let initialValues: any = {
        reasonForLeaving: "",
    }

    return (
        <div className="inapp-content">
            <div className="page-content cards setting-page">

                <div className="page-heading">
                    <h3>Settings</h3>
                </div>
                <div className="page-menus">
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile">
                        Personal Details
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/store">
                        Store Details
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/staff">
                        Staff Account
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/withdrawal">
                        Withdrawal Method
                    </NavLink>
                    <NavLink end className={({ isActive }) => (isActive ? 'active-nav' : '')} to="/app/profile/delete">
                        Delete Account
                    </NavLink>
                </div>

            </div>
            <div className="cards setting-page deletepage">
                <div className="business-info">
                    <img src={Walmart} alt="" />
                    <div className="business-name">Walmart</div>
                    <div className="sub-info">Business Account</div>
                </div>
                <div className="confirm">Are you Sure?</div>
                <div className="confirm-txt">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna  tincidunt ut laoreet dolore magna 
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={detailsValidationSchema}
                    onSubmit={(values: any, actions) => {

                    }}
                >
                    {(props: FormikProps<any>) => {
                        const {
                            values,
                            touched,
                            errors,
                            setFieldTouched,
                            handleChange,
                            setFieldValue,
                        } = props;
                        return (
                            <Form>
                                <div className="form-wrap">
                                    <div className="">

                                        <div className="form-group">
                                            <Field
                                                id="reasonForLeaving"
                                                value={values.reasonForLeaving}
                                                name="reasonForLeaving"
                                                type="text"
                                                placeholder="Reason for leaving us"
                                                onChange={handleChange}
                                                className={`input-wrap ${errors.reasonForLeaving && touched.reasonForLeaving ?
                                                    "is-invalid-input "
                                                    : values.reasonForLeaving !== "" ? "valid-input" : ""}`}
                                            />
                                            <ErrorMessage name="reasonForLeaving" className="form-input-error" component="div" />
                                        </div>




                                    </div>

                                </div>
                                <div className="invite-cta">
                                    <button className="btn">Delete</button>
                                </div>
                            </Form>
                        )
                    }}

                </Formik>

            </div>
        </div>
    )
}

const DeleteProfile = () => {
    return (
        <InAppTemplate pageHeadingTitle="Personal Profile" childComponent={<InAppContent />} />
    )
}


const mapDispatchToProps = {

};

const mapStateToProps = (state: any) => ({
    // return {


    // };
})
export default connect(mapStateToProps, mapDispatchToProps)(DeleteProfile);