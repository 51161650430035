import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const exportAsImage = async (element, imageFileName) => {
 
  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];
  let htmlWidth = html.clientWidth;
  let bodyWidth = body.clientWidth;

  const newWidth = element.scrollWidth - element.clientWidth;

  if (newWidth > element.clientWidth) {
    htmlWidth += newWidth;
    bodyWidth += newWidth;
  }

  html.style.width = htmlWidth + "px";
  body.style.width = bodyWidth + "px";


  const canvas = await html2canvas(element, {
    height: window.outerHeight + window.innerHeight,
    windowHeight: window.outerHeight + window.innerHeight,
    scrollY: -window.scrollY,
    
    //   onrendered: function() {         
    //     var image = canvas.toDataURL("image/png", 1.0)         
    //     var doc = new jsPDF('p', 'mm');
    //     doc.addImage(image, 'PNG', 10, 10);
    //     doc.save(`${imageFileName}.pdf`);
    // }
  });
  const image = canvas.toDataURL("image/png", 1.0);
  let doc = new jsPDF("p", "mm", "a4");

  let docWidth = doc.internal.pageSize.getWidth();
  let docHeight = doc.internal.pageSize.getHeight();
  // var doc = new jsPDF('p', 'mm');
  doc.addImage(image, 'PNG', 0, 0, docWidth, docHeight);
  doc.save(`${imageFileName}.pdf`);

  // downloadImage(image, imageFileName);
  html.style.width = null;
  body.style.width = null;

};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();

};

export default exportAsImage;
